import { StructuredText } from "datocms-structured-text-utils";
import { graphql } from "gatsby";
import {
  DatoCmsArticle,
  DatoCmsExternalResource,
  DatoCmsPartner,
  DatoCmsWebinar,
  PartnerBySlugQuery,
} from "../../graphql-types";
import SpaceChildren from "../components/layout/SpaceChildren";
import Spacing from "../components/layout/Spacing";
import PartnerPageBenefitsSection from "../components/partners/PartnerPageBenefitsSection";
import PartnerPageCTASection from "../components/partners/PartnerPageCTASection";
import PartnerPageFeaturedStories from "../components/partners/PartnerPageFeaturedStories";
import PartnerPageGriddedSection from "../components/partners/PartnerPageGriddedSection";
import PartnerPageHero from "../components/partners/PartnerPageHero";
import PartnerPageRelatedEventsSection from "../components/partners/PartnerPageRelatedEventsSection";
import PartnerPageResourcesSection from "../components/partners/PartnerPageResourcesSection";
import PartnerPageVideoEmbedsSection from "../components/partners/PartnerPageVideoEmbedsSection";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import { EventDirectoryItem, StoryDescriptor } from "../types/helper.types";
import { isEmptyStructuredText } from "../utils/datocms.utils";
import Page from "./Page.template";
import { getPlainTextContentFromStructuredText } from "../../scripts/structuredText";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";

const PartnerPageTemplate: PageComponent<{
  data: PartnerBySlugQuery;
  pageContext: {
    slug: string;
    tag: string;
    stories: StoryDescriptor[];
    externalResources: DatoCmsExternalResource[];
    events: EventDirectoryItem[];
  };
}> = props => {
  const partner = props.data.partner as DatoCmsPartner;
  const ogImage = partner.coverImage?.url || "/images/og/tines-partners.png";
  const hasResources =
    props.pageContext.externalResources.length > 0 ||
    props.data.articles.nodes.length > 0 ||
    props.data.webinars.nodes.length > 0;
  return (
    <Page {...props} navThemeColor="purple">
      <SEO
        title={`${partner.name} × Tines`}
        description={
          partner.summary ||
          getPlainTextContentFromStructuredText(partner.overview)
        }
        image={ogImage}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="partnerPage"
        recordId={partner.id}
      />
      <SpaceChildren size="sectionPadding">
        <PartnerPageHero
          partner={partner}
          hasStories={props.pageContext.stories.length > 0}
        />

        {!isEmptyStructuredText(
          partner.griddedSectionContent as StructuredText
        ) && <PartnerPageGriddedSection partner={partner} />}

        <PartnerPageBenefitsSection partner={partner} />

        {props.pageContext.stories.length > 0 && (
          <PartnerPageFeaturedStories
            partner={partner}
            stories={props.pageContext.stories}
          />
        )}

        {(partner.videoEmbeds ?? []).length > 0 && (
          <PartnerPageVideoEmbedsSection partner={partner} />
        )}

        {props.pageContext.events.length > 0 && (
          <PartnerPageRelatedEventsSection events={props.pageContext.events} />
        )}

        {hasResources && (
          <PartnerPageResourcesSection
            partner={partner}
            articles={props.data.articles.nodes as DatoCmsArticle[]}
            webinars={props.data.webinars.nodes as DatoCmsWebinar[]}
            externalResources={props.pageContext.externalResources}
          />
        )}

        <PartnerPageCTASection partner={partner} />
      </SpaceChildren>

      <Spacing size="sectionPadding" />
    </Page>
  );
};

export const partnerBySlugQuery = graphql`
  query PartnerBySlug($slug: String, $tag: String) {
    partner: datoCmsPartner(slug: { eq: $slug }) {
      id: originalId
      name
      logo {
        url
        width
        height
      }
      coverImage {
        width
        height
        url
      }
      summary
      websiteUrl
      slug
      tag {
        name
      }
      storyLibraryTag
      landingPageTitle
      overview {
        value
      }
      griddedSectionImage {
        width
        height
        url
      }
      griddedSectionContent {
        value
      }
      benefits {
        id
        __typename
        heading
        content
        icon {
          url
        }
      }
      videoEmbeds {
        id: originalId
        title
        url
        videoFile {
          video {
            mp4Url
            streamingUrl
            thumbnailUrl
          }
          width
          height
          basename
          alt
          url
        }
        autoplay
        loop
      }
      valueProposition
    }
    articles: allDatoCmsArticle(
      filter: {
        tags: { elemMatch: { name: { eq: $tag } } }
        hiddenInBlogIndex: { ne: true }
      }
    ) {
      nodes {
        title
        slug
        datePublished
        excerpt
        coverImage {
          width
          height
          url
        }
      }
    }
    webinars: allDatoCmsWebinar(
      filter: {
        category: { slug: { eq: "webinars" } }
        tags: { elemMatch: { name: { eq: $tag } } }
        recordedVideoLink: { ne: "" }
      }
    ) {
      nodes {
        id: originalId
        title
        slug
        recordedVideoLink
        excerpt
        coverImage {
          width
          height
          url
        }
      }
    }
  }
`;
export default PartnerPageTemplate;
