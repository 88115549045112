import { styled } from "@linaria/react";
import {
  DatoCmsStoryDigest,
  DatoCmsYdwwtWinners2023Section,
} from "../../../../graphql-types";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import YDWWTWhatSticker from "../../ydwwt/YDWWTWhatSticker";
import { font } from "../../../styles/fonts.styles";
import StoryEntry from "../StoryEntry";
import Spacing from "../../layout/Spacing";
import RigidDotGrid from "../../decorations/RigidDotGrid";
import img from "../../../../static/images/library/ydwwt-section-decoration.png";
import img2x from "../../../../static/images/library/ydwwt-section-decoration@2x.png";
import { makeSrcSet2x } from "../../../utils/srcset.utils";
import { Link } from "gatsby";
import { colors } from "../../../styles/colors.styles";
import { convertDatoRecordToStoryDescriptor } from "../../../utils/story.utils";
import {
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const SectionWrap = styled.div`
  position: relative;
  background-color: ${withOpacity(colors.purple, 0.2)};
  color: ${colors.purple700};
  ${darkModeLinariaCSS(`color: ${colors.purple300}`)};
  border-radius: ${rSize("radius")};
  padding: ${rSize("lg")};
  font-size: 1.6rem;
  overflow: hidden;
`;
const Header = styled.header`
  ${fromTabletMd} {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  position: relative;
  h2 {
    font-size: 3.2rem;
  }
`;
const HeaderMainText = styled.div``;
const HeaderLink = styled.div`
  ${uptoTabletMd} {
    margin-top: 1em;
  }
  padding-bottom: 0.2em;
  a {
    font-weight: 600;
    text-decoration: none;
  }
`;
const Winner2023 = styled.p`
  font-family: ${font("serif")};
  margin-top: 0.4rem;
  font-size: 2.4rem;
`;
const Description = styled.p`
  margin-top: 1.4rem;
  max-width: 33em;
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-gap: ${rSize("gap")};
  ${uptoTabletMd} {
    grid-template-rows: auto auto;
  }
  ${fromTabletMd} {
    grid-template-columns: minmax(0, 1fr) 22.5em;
  }
`;

const DecoImg = styled.img`
  ${uptoTabletMd} {
    display: none;
  }
  position: absolute;
  top: 0;
  right: 0;
`;

export const YdwwtWinners2023Section = ({
  section,
}: {
  section: DatoCmsYdwwtWinners2023Section;
}) => {
  return (
    <SectionWrap id="ydwwt">
      <RigidDotGrid color={withOpacity(colorsV4.purple, 0.1)} />
      <Header>
        <HeaderMainText>
          <h2>
            You did <YDWWTWhatSticker /> with Tines
          </h2>
          <Winner2023>Winners 2023</Winner2023>
          <Description>
            YDWWT is our semi-annual community competition to find the most
            creative applications of Tines.
          </Description>
        </HeaderMainText>
        <HeaderLink>
          <Link to="/you-did-what-with-tines">
            Enter your own story to win →
          </Link>
        </HeaderLink>
      </Header>
      <Spacing size="gap" />
      <Grid>
        <StoryEntry
          story={convertDatoRecordToStoryDescriptor(
            section.winner as DatoCmsStoryDigest
          )}
          preHeading="Winner"
          overrides={{
            color: "purple",
          }}
        />
        <StoryEntry
          story={convertDatoRecordToStoryDescriptor(
            section.runnerUp as DatoCmsStoryDigest
          )}
          preHeading="Runner-up"
          overrides={{
            color: "purple",
          }}
        />
      </Grid>
      <DecoImg srcSet={makeSrcSet2x(img, img2x)} src={img} />
    </SectionWrap>
  );
};
