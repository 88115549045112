import { styled } from "@linaria/react";
import { ButtonGroupCenteredOnMobile } from "../../components/forms/Button";
import Spacing from "../../components/layout/Spacing";
import HeroSection, {
  HeroSectionDescription,
} from "../../components/sections/HeroSection";
import AllCaps from "../../components/typography/AllCaps";
import SEO from "../../components/utilities/SEO";
import { colors } from "../../styles/colors.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import SignUpButton from "../../components/utilities/SignUpButton";
import { ExplosionCTASection } from "../../components/reusableSections/ExplosionCTASection";
import { AIPageQuoteSection } from "../../components/ai/AIPageQuoteSection";
import { AIPageTrustSection } from "../../components/ai/AIPageTrustSection";
import { NoLetterSpacing } from "../../components/typography/NoLetterSpacing";
import { AIPageBenefitsSection } from "../../components/ai/AIPageBenefitsSection";
import { AIPageMainFeatureSetSection } from "../../components/ai/AIPageMainFeatureSetSection";
import { AIPageUseCasesSection } from "../../components/ai/AIPageUseCasesSection";
import { AIPageDemoVideoSection } from "../../components/ai/AIPageDemoVideoSection";
import { AIPageExamplesSection } from "../../components/ai/AIPageExamplesSection";
import { AIPageQuoteGridSection } from "../../components/ai/AIPageQuoteGridSection";
import { w } from "../../utils/string.utils";
import { rSize } from "../../styles/responsiveSizes.styles";
import { useConnectorPathPainter } from "../../components/decorations/ConnectorPaths";
import { AutomaticModeIllustratedBlock } from "../../components/ai/heroSectionComponents/AutomaticModeIllustratedBlock";
import { AIActionIllustratedBlock } from "../../components/ai/heroSectionComponents/AIActionIllustratedBlock";
import aiPageHeroBackdropStaticConnectors from "../../components/ai/graphics/aiPageHeroBackdropStaticConnectors.svg";
import { css } from "linaria";
import ogImage from "../../../static/images/og/tines-ai.png";
import {
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  uptoDesktop,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import mobileHeroImage from "../../components/ai/graphics/aiPageHeroImageMobile.svg";
import tabletHeroImage from "../../components/ai/graphics/aiPageHeroImageTablet.svg";
import AIPageRelatedContentSection from "../../components/ai/AIPageRelatedContentSection";
import AIPageWebinarButtonAndLightbox from "../../components/ai/AIPageWebinarLightbox";
import { AdminToolBarWithEditInDatoCmsButton } from "../../components/site/AdminToolbar";

const HeroSectionGrid = styled.div`
  position: relative;
  grid-gap: 0 ${rSize("gap")};
  ${fromDesktopMd} {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: 8.5rem auto 4.2rem;
    grid-template-areas: ${`
      '${w(".", 2)} ${w("m", 8)} ${w(".", 2)}'
      '${w("l", 2)} ${w("m", 8)} ${w("r", 2)}'
      '${w("l", 2)} ${w(".", 8)} ${w("r", 2)}'
    `};
    padding-bottom: 11em;
  }
  ${fromDesktopMl} {
    grid-template-areas: ${`
      '${w(".", 3)} ${w("m", 6)} ${w(".", 3)}'
      '${w("l", 3)} ${w("m", 6)} ${w("r", 3)}'
      '${w("l", 3)} ${w(".", 6)} ${w("r", 3)}'
    `};
  }
`;

const HeaderGroup = styled.div`
  position: relative;
  grid-area: m;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  p {
    ${uptoPhoneLg} {
      max-width: 20em;
    }
  }
`;

const MobileHeroImageWrap = styled.div`
  text-align: center;
  overflow: hidden;
  margin-top: 3em;
  margin-left: ${rSize("pageMargin", -1)};
  margin-right: ${rSize("pageMargin", -1)};
  display: flex;
  justify-content: center;
  ${fromTablet} {
    display: none;
  }
`;
const MobileHeroImage = styled.img`
  width: 465px;
  height: 769px;
`;

const TabletHeroImageWrap = styled.div`
  text-align: center;
  overflow: hidden;
  margin-top: 2em;
  margin-left: ${rSize("pageMargin", -1)};
  margin-right: ${rSize("pageMargin", -1)};
  display: none;
  ${fromTablet} {
    display: flex;
    justify-content: center;
  }
  ${fromDesktopMd} {
    display: none;
  }
`;

const TabletHeroImage = styled.img`
  width: 878px;
  aspect-ratio: 878 / 510;
  max-width: 110vw;
`;

const ConnectorSvgElementGroupMediaQuery = styled.div`
  ${uptoDesktop} {
    display: none;
  }
`;

const PageAI: PageComponent = props => {
  const description =
    "Leverage LLMs to build, run, and monitor your most important workflows. Private and secure by design.";
  const connectorSvgElementGroup = useConnectorPathPainter();
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title="Power your workflows with AI"
        image={ogImage}
        description={description}
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="aiPage"
        /* cspell:disable */
        recordId="Wi9Deur9SZ-95v0GSDVNuw"
        /* cspell:enable */
        label="Edit in CMS"
      />

      <ConnectorSvgElementGroupMediaQuery>
        {connectorSvgElementGroup}
      </ConnectorSvgElementGroupMediaQuery>

      <HeroSection
        contentRelative
        clipOverflow
        className={css`
          ${fromDesktopMd} {
            background-position: center bottom;
            background-image: url(${aiPageHeroBackdropStaticConnectors});
          }
        `}
      >
        <HeroSectionGrid>
          <HeaderGroup>
            <AllCaps as="p">Product</AllCaps>
            <Spacing size="1.5em" />
            <h1>
              Power your work<NoLetterSpacing>fl</NoLetterSpacing>ows with AI
            </h1>
            <HeroSectionDescription>{description}</HeroSectionDescription>
            <Spacing size="lg" />
            <ButtonGroupCenteredOnMobile>
              <AIPageWebinarButtonAndLightbox />
              <SignUpButton appearance="outlined" darker />
            </ButtonGroupCenteredOnMobile>
          </HeaderGroup>

          <AutomaticModeIllustratedBlock />
          <AIActionIllustratedBlock />

          <MobileHeroImageWrap>
            <MobileHeroImage src={mobileHeroImage} />
          </MobileHeroImageWrap>
          <TabletHeroImageWrap>
            <TabletHeroImage src={tabletHeroImage} />
          </TabletHeroImageWrap>
        </HeroSectionGrid>
      </HeroSection>

      <Spacing size="xxl" />

      <AIPageQuoteSection />
      <Spacing size="sectionMargin" />

      <AIPageTrustSection />
      <Spacing size="sectionMargin" />

      <AIPageBenefitsSection />
      <Spacing size="sectionMargin" />

      <AIPageMainFeatureSetSection />

      <Spacing size="xxl" />

      <AIPageUseCasesSection />
      <Spacing size="sectionMargin" />

      <AIPageDemoVideoSection />
      <Spacing size="sectionMargin" />

      <AIPageExamplesSection />
      <Spacing size="sectionMargin" />

      <AIPageQuoteGridSection />
      <Spacing size="sectionMargin" />

      <AIPageRelatedContentSection />
      <Spacing size="sectionMargin" />

      <ExplosionCTASection />
    </Page>
  );
};

export default PageAI;
