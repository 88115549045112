export const MapPinTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path opacity="0.3" d="M6.5 8.5V15.5L11.5 13.5V8.5L9 12L6.5 8.5Z" />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1596 9.80844C12.1232 8.62752 13 7.55295 13 5.85C13 3.7237 11.2091 2 9 2C6.79086 2 5 3.7237 5 5.85C5 7.55295 5.87681 8.62752 6.84039 9.80844C7.5105 10.6297 8.22259 11.5024 8.71092 12.6737C8.80768 12.9057 9.19232 12.9057 9.28908 12.6737C9.77741 11.5024 10.4895 10.6297 11.1596 9.80844ZM9 7C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6C8 6.55228 8.44772 7 9 7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.85C12 6.94964 11.544 7.71806 10.8057 8.65467C10.6831 8.81018 10.5504 8.97228 10.4115 9.14212C9.9655 9.68713 9.45424 10.3119 9 11.0551C8.54576 10.3119 8.0345 9.68713 7.58854 9.14212L7.58853 9.14211C7.44956 8.97227 7.31693 8.81018 7.19434 8.65467C6.456 7.71806 6 6.94964 6 5.85C6 4.31167 7.30678 3 9 3C10.6932 3 12 4.31167 12 5.85ZM11.1596 9.80844C10.6027 10.491 10.0168 11.209 9.55442 12.1045L9.54278 12.1271C9.5007 12.2092 9.45966 12.2929 9.41979 12.3781C9.37468 12.4745 9.33105 12.573 9.28908 12.6737C9.19232 12.9057 8.80768 12.9057 8.71092 12.6737C8.66895 12.573 8.62532 12.4745 8.58021 12.3781C8.54034 12.2929 8.4993 12.2092 8.45722 12.1271L8.44558 12.1045C7.98322 11.209 7.39731 10.491 6.84039 9.80844C6.02736 8.81204 5.27612 7.89135 5.06121 6.60504C5.06417 6.62276 5.06723 6.64041 5.07039 6.658L3 6.1404V14.1096L6.46442 14.9757L11.1344 13.1077C11.3659 13.0151 11.6236 13.0123 11.857 13.0999L15 14.2785V6.34652L12.9912 5.59323C12.9971 5.6781 13 5.76372 13 5.85C13 7.55295 12.1232 8.62752 11.1596 9.80844ZM12.7158 4.42194L15.3511 5.41019C15.7414 5.55655 16 5.92967 16 6.34652V14.2785C16 14.9767 15.3026 15.46 14.6489 15.2148L11.5058 14.0362L6.83581 15.9042C6.64063 15.9823 6.42582 15.9969 6.22188 15.9459L2.75746 15.0798C2.3123 14.9685 2 14.5685 2 14.1096V6.1404C2 5.48983 2.61139 5.01247 3.24254 5.17026L5.00755 5.61151C5.1355 3.5963 6.87407 2 9 2C10.6849 2 12.1265 3.00269 12.7158 4.42194ZM5 5.85002C5 5.85002 5 5.85002 5 5.85001Z"
    />
  </svg>
);
