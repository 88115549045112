/* eslint-disable @typescript-eslint/no-unsafe-argument */
import dayjs from "dayjs";
import { graphql } from "gatsby";
import { QuickstartEvergreenPageQuery } from "../../graphql-types";
import ClientRedirectPageTemplate from "../templates/ClientRedirectPage.template";
import { PageComponent } from "../types/gatsby.types";
import ogImage from "../../static/images/og/tines-quickstart.png";

/**
 * 💻
 */
const PageQuickstartRedirectHandler: PageComponent<{
  data: QuickstartEvergreenPageQuery;
}> = props => {
  let redirectTo = "";
  const quickstartEntries = props.data.webinars.nodes;
  const firstAvailableQuickstart =
    quickstartEntries
      .sort((a, b) => dayjs(a.timeStart).diff(b.timeStart))
      .find(quickstart => dayjs(quickstart.timeStart).isAfter(dayjs())) ??
    quickstartEntries[0];

  if (firstAvailableQuickstart) {
    redirectTo = `/quickstart/${firstAvailableQuickstart.slug}`;
  } else {
    redirectTo = `/webinars`;
  }

  return (
    <ClientRedirectPageTemplate
      {...props}
      pageContext={{
        title: "Tines Quickstart",
        description:
          "In our effort to enable everyone to get the most out of their automation goals, we would like to invite you to join us in our Tines Quickstart webinars!",
        ogImage,
        to: redirectTo,
        delay: 500,
        message: firstAvailableQuickstart
          ? "Taking you to the latest Quickstart webinar..."
          : "No quickstart webinars scheduled, taking you to all webinars...",
      }}
    />
  );
};

export const quickstartEvergreenPageQuery = graphql`
  query QuickstartEvergreenPage {
    webinars: allDatoCmsWebinar(
      filter: { category: { slug: { eq: "quickstart" } } }
    ) {
      nodes {
        id: originalId
        title
        slug
        timeStart
      }
    }
  }
`;

export default PageQuickstartRedirectHandler;
