import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import Spacing from "../layout/Spacing";
import { serif } from "../../styles/fonts.styles";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPaths";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import NoWrap from "../typography/Nowrap";

const Blockquote = styled.blockquote`
  position: relative;
  text-align: center;
  margin: 0;
  max-width: 42em;
  margin-left: 1em;
  margin-right: 1em;
  ${fromTablet} {
    margin-left: auto;
    margin-right: auto;
  }
  ${fromDesktop} {
    margin-top: -3em;
  }
  ${fromDesktopMd} {
    margin-top: 0;
  }
  p {
    font-family: ${serif};
    font-size: 1.8rem;
    letter-spacing: -0.01rem;
    line-height: 1.2;
    ${fromTablet} {
      font-size: 2.4rem;
      letter-spacing: -0.02rem;
      font-weight: 300;
    }
  }
  footer {
    font-size: 1.4rem;
  }
`;

const ConnectorWaypointsPositioner = styled.div`
  position: absolute;
  top: ${rSize("xxl", -1)};
  bottom: ${rSize("xxl", -1)};
  left: 0;
  right: 0;
`;

export const AIPageQuoteSection = () => (
  <PageSection relative>
    <ConnectorWaypointsPositioner>
      <ConnectorPathWaypoint
        id="ai-page-quote-section-start"
        index={0}
        color={colors.yellow300}
        top="0"
        left="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-quote-section-start"
        index={1}
        color={colors.light200}
        top="2em"
        left="50%"
      />

      <ConnectorPathWaypoint
        id="ai-page-quote-section-middle"
        index={0}
        color={colors.light200}
        top="2em"
        left="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-quote-section-middle"
        index={1}
        color={colors.light200}
        bottom="2em"
        left="50%"
      />

      <ConnectorPathWaypoint
        id="ai-page-quote-section-end"
        index={0}
        color={colors.light200}
        bottom="2em"
        left="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-quote-section-end"
        index={1}
        color={colors.yellow400}
        bottom="0"
        left="50%"
      />
    </ConnectorWaypointsPositioner>
    <Blockquote>
      <p>
        “It’s unlocking new use cases for us. Automatic mode, especially,
        promises to help the team work faster and lower the barrier to entry for
        non-developers on <NoWrap>the team</NoWrap>.”
      </p>
      <Spacing size="lg" />
      <footer>
        <strong>Allen Cox</strong>, MyFitnessPal
      </footer>
    </Blockquote>
  </PageSection>
);
