import StaticImageFrame from "../components/basic/StaticImageFrame";
import Spacing from "../components/layout/Spacing";
import PageSection from "../components/reusableSections/PageSection";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import SEO from "../components/utilities/SEO";
import { colorsV4 } from "../styles/colorsV4.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import headerImage from "../../static/images/og/tines-test-drive-header.png";
import ogImage from "../../static/images/og/tines-test-drive.png";
import { ArticleLikePageContent } from "../components/articles/ArticleLikePageContent";
import { AlignCenterOnMobile } from "../components/typography/Align";
import { Serif } from "../components/typography/Serif";
import { FormEvent, useState } from "react";
import Footnotes from "../components/basic/Footnotes";
import Button from "../components/forms/Button";
import TextInput, { TextInputSet } from "../components/forms/TextInput";
import CornerDotSet from "../components/utilities/CornerDotSet";
import PrivacyNotice from "../components/utilities/PrivacyNotice";
import { scrollToHash } from "../utils/anchorLinkScroll.utils";
import { emailIsLegitimate } from "../utils/checkEmailLegitimacy.utils";
import { reportErrorSilently } from "../utils/error.utils";
import { readMarketingAttribution } from "../utils/marketingAttribution.utils";
import { resolveAfter } from "../utils/promises.utils";
import { styled } from "@linaria/react";
import SpotIllustration from "../components/illustrations/SpotIllustration";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { css } from "linaria";
import Textarea from "../components/forms/Textarea";
import Pill from "../components/basic/Pill";
import { getFormMetaInfo } from "../utils/form.utils";

const PageTestDrive: PageComponent = props => {
  const desc = `Are you an information security professional who is tired of settling for tools that aren't delivering results? We get it, we're here to help and prove our value to you. Bring us 2 to 3 of your most challenging & cumbersome workflows, and let us show you the simplicity of Tines.`;
  return (
    <Page {...props} backgroundColor={colorsV4.canvas400}>
      <SEO
        title="Tines Automation Test Drive"
        description={desc}
        image={ogImage}
      />
      <SiteNavHeightPlaceholder withSpacingTop />
      <Spacing size="lg" />
      <PageSection>
        <StaticImageFrame
          src={headerImage}
          intrinsicWidth={2048}
          intrinsicHeight={655}
          aspectRatio={`2048/655`}
          backgroundColor={colorsV4.green100}
          className={css`
            border-radius: 1em;
            ${fromTablet} {
              border-radius: 1.5em;
            }
            ${fromDesktop} {
              border-radius: 2.5em;
            }
          `}
        />
      </PageSection>
      <Spacing size="sectionPadding" />
      <PageSection>
        <AlignCenterOnMobile>
          <ArticleLikePageContent
            className={css`
              text-align: center;
              h2 {
                line-height: 1.1;
                font-weight: 600;
                font-size: 3rem;
                max-width: 12em;
                margin-left: auto;
                margin-right: auto;
                ${fromTablet} {
                  font-size: 3.6rem;
                }
                ${fromDesktopMd} {
                  font-size: 4.8rem;
                }
              }
              p {
                max-width: 35em;
                margin-left: auto;
                margin-right: auto;
              }
            `}
          >
            <Pill color="purple">Book a meeting</Pill>
            <Spacing size="lg" />
            <h2>
              Put your <Serif>automation processes</Serif> to the test
            </h2>
            <Spacing size="lg" />
            <p>
              Are you an information security professional who is tired of
              settling for tools that aren't delivering results?
            </p>
            <Spacing size="0.5em" />
            <p>We get it, we're here to help and prove our value to you.</p>
            <Spacing size="0.5em" />
            <p>
              Bring us 2 to 3 of your most challenging & cumbersome workflows,
              and let us show you the simplicity of Tines.
            </p>
            <Spacing size="0.5em" />
            <p>
              The time has come - 30 minutes, a few use cases, and a vendor
              PROVING out a better solution for you and your team.
            </p>
            <Spacing size="sectionPadding" />
            <BookingForm />
          </ArticleLikePageContent>
        </AlignCenterOnMobile>
      </PageSection>
      <Spacing size="sectionPadding" />
    </Page>
  );
};

const FormContainer = styled.form`
  position: relative;
  border-radius: 1.5em;
  padding: 1em;
  ${onlyPhones} {
    padding-top: 2em;
  }
  ${fromTablet} {
    padding: 2em;
    border-radius: 2em 2em 2em 2.5em;
  }
  background-color: ${colorsV4.canvas550};
  h3 {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 1em;
    text-align: center;
  }
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
  input {
    background-color: ${colorsV4.white};
    color: ${colorsV4.warmBlack};
    &:hover {
      background-color: ${colorsV4.white};
    }
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: [start] 1fr [end];
  margin-top: 1em;
  margin-bottom: 0.5em;
  grid-gap: 1em;
`;

const TextareaFieldBlock = styled.div`
  background-color: ${colorsV4.white};
  color: ${colorsV4.warmBlack};
  border-radius: 1.25em;
  border: 2px solid ${colorsV4.canvas700};
  header {
    padding: 1em 1em 0.5em 1em;
  }
  h4 {
    font-size: inherit;
  }
  text-align: left;
  textarea {
    border-radius: 0;
    border: 0;
    background-color: transparent;
    padding: 0.5em 1em 1em 1em;
  }
  &:hover {
    border-color: ${colorsV4.purple300};
  }
`;

const SuccessMessageContainer = styled.div`
  background-color: ${colorsV4.canvas550};
  padding: 1em 1.5em 2.5em 1.5em;
  border-radius: 1.25em;
  text-align: center;
  h2 {
    font-size: 2rem;
  }
`;

const SuccessMessage = () => (
  <SuccessMessageContainer className="EventRegistrationFormSuccessMessage">
    <SpotIllustration name="paperPlane" size="150" />
    <h2>Thanks for submitting, we’ll be in touch.</h2>
  </SuccessMessageContainer>
);

const makeFormBase = () => ({
  firstName: "",
  lastName: "",
  title: "",
  company: "",
  email: "",
  phone: "",
  useCases: "",
  technologyAndToolsIntegrated: "",
  challenges: "",
  availability: "",
  ...getFormMetaInfo(),
});

const BookingForm = () => {
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const formState = useState(() => ({
    ...makeFormBase(),
  }));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setAwaitingResponse(true);
    if (!(await emailIsLegitimate(formState[0].email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }

    const payload = {
      form: formState[0],
      marketingAttribution: readMarketingAttribution(),
    };

    try {
      await fetch(
        "https://hq.tines.io/webhook/75b43e942d861519b422fd88a9cdff4a/7ae11459d0b8029f99584eb618ec138b",
        {
          method: "post",
          body: JSON.stringify(payload),
        }
      );
      setHasSubmitted(true);
      await resolveAfter();
      await scrollToHash({ useHash: "#form", doNotPushState: true });
    } catch (e) {
      reportErrorSilently(e);
      window.alert(`Hmm, something wasn't quite right. Please try again.`);
    } finally {
      setAwaitingResponse(false);
    }
  };

  return (
    <div id="form">
      {hasSubmitted ? (
        <SuccessMessage />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <FormContainer id="form" onSubmit={handleSubmit}>
          <CornerDotSet color={colorsV4.canvas900} offset={rSize("gap")} />
          <h3>Let us prove it</h3>
          <Spacing size=".5em" />
          <FormGrid>
            <TextInputSet>
              <TextInput
                formState={formState}
                name="firstName"
                required
                placeholder="First name *"
              />
              <TextInput
                formState={formState}
                name="lastName"
                required
                placeholder="Last name *"
              />
              <TextInput
                formState={formState}
                name="email"
                type="email"
                placeholder="Email *"
                required
              />
              <TextInput
                formState={formState}
                name="phone"
                type="tel"
                placeholder="Phone"
              />
              <TextInput
                formState={formState}
                name="company"
                required
                placeholder="Company *"
              />
              <TextInput
                formState={formState}
                name="title"
                placeholder="Work title"
              />
            </TextInputSet>

            <Spacing size=".5em" />

            <TextareaFieldBlock>
              <header>
                <h4>Briefly explain the use case(s) *</h4>
              </header>
              <Textarea
                formState={formState}
                name="useCases"
                required
                rows={3}
              />
            </TextareaFieldBlock>

            <TextareaFieldBlock>
              <header>
                <h4>
                  Which technology/tools are integrated in the use case(s) *
                </h4>
              </header>
              <Textarea
                formState={formState}
                name="technologyAndToolsIntegrated"
                required
                rows={3}
              />
            </TextareaFieldBlock>

            <TextareaFieldBlock>
              <header>
                <h4>
                  Biggest challenges today (what metrics are tied to it?) *
                </h4>
              </header>
              <Textarea
                formState={formState}
                name="challenges"
                required
                placeholder={`Ex. an analyst/engineer spends "x" amount of time remediating "y" number of alerts daily / weekly`}
                rows={3}
              />
            </TextareaFieldBlock>

            <TextareaFieldBlock>
              <header>
                <h4>Available meeting times *</h4>
              </header>
              <Textarea
                formState={formState}
                name="availability"
                required
                rows={3}
              />
            </TextareaFieldBlock>

            <Spacing size=".5em" />

            <Button
              loading={awaitingResponse}
              children="Submit"
              type="submit"
              name="submit"
              fullWidth
              padding="1em"
            />

            <Footnotes>
              <PrivacyNotice />
            </Footnotes>
            <Spacing size=".5em" />
          </FormGrid>
        </FormContainer>
      )}
    </div>
  );
};

export default PageTestDrive;
