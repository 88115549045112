import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";
import Spacing from "../layout/Spacing";
import ImageStickerAllYourWorkflows from "../../images/tines-sticker-all-your-workflows.png";
import ImageStickerAllYourWorkflows2x from "../../images/tines-sticker-all-your-workflows@2x.png";
import ImageStickerCreatorWithLogo from "../../images/tines-sticker-creator-with-logo.png";
import ImageStickerCreatorWithLogo2x from "../../images/tines-sticker-creator-with-logo@2x.png";
import ImageStickerHeart from "../../images/tines-sticker-heart.png";
import ImageStickerHeart2x from "../../images/tines-sticker-heart@2x.png";
import ImageStickerShapes from "../../images/tines-sticker-shapes.png";
import ImageStickerShapes2x from "../../images/tines-sticker-shapes@2x.png";
import { css } from "linaria";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
  onlyPhones,
  uptoDesktopMl,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { makeSrcSet2x } from "../../utils/srcset.utils";
import YDWWTWhatSticker, { WhatSticker } from "./YDWWTWhatSticker";
import { font } from "../../styles/fonts.styles";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import { DatoCmsYdwwt } from "../../../graphql-types";
import HeroSection from "../sections/HeroSection";
import { StructuredTextWithCustomBlocks } from "../articles/StructuredTextWithCustomBlocks";
import YDWWTSubmitButtonWithNotice from "./YDWWTSubmitButtonWithNotice";

const InnerWrap = styled.div`
  position: relative;
  h1 {
    user-select: none;
    font-family: ${font("sans")};
    font-weight: 600;
    ${WhatSticker} {
      ${onlyPhones} {
        font-size: 75%;
      }
    }
    ${uptoDesktopMl} {
      max-width: 9em;
      margin-left: auto;
      margin-right: auto;
    }
  }
  h2 {
    font-size: 1.8rem;
    font-family: ${font("serif")};
    font-weight: 400;
    line-height: 1.25;
    span {
      display: block;
    }
    ${fromTablet} {
      font-size: 2.4rem;
    }
  }
`;

const Description = styled.div`
  font-size: 1.6rem;
  max-width: 36em;
  margin-left: auto;
  margin-right: auto;
  p {
    line-height: 1.5;
    ${onlyPhones} {
      margin-left: 1em;
      margin-right: 1em;
    }
  }
`;

const DecoSticker = css`
  ${uptoTabletMd} {
    display: none;
  }
  position: absolute;
  transition: 0.2s;
  transform: scale(0.5);
  ${mediaFromTo("tablet", "tabletLg")} {
    transform: scale(0.65);
  }
  ${mediaFromTo("tabletLg", "desktopMd")} {
    transform: scale(0.8);
  }
  ${fromDesktopMd} {
    transform: none;
  }
`;

const DecoStickerHeart = styled.div`
  ${uptoTabletMd} {
    display: none;
  }
  top: 1em;
  right: -1em;
  img {
    transform: scale(0.9) translate(50%, -45%);
    ${fromTabletLg} {
      transform: scale(0.9) translate(30%, -30%);
    }
    ${fromDesktopMd} {
      transform: scale(0.9) translate(20%, -25%);
    }
  }
`;

const DecoStickerShapes = styled.div`
  left: -4em;
  transform: scale(0.8);
  ${uptoTabletMd} {
    display: none;
  }
  ${fromTabletMd} {
    top: 2em;
  }
`;

const DecoStickerCreatorWithLogo = styled.div`
  display: none;
  bottom: 1em;
  left: 0;
  ${fromTabletMd} {
    display: block;
  }
  img {
    transform: translateX(-25%);
    ${fromDesktopMd} {
      transform: translateX(-15%);
    }
  }
`;

const DecoStickerAllYourWorkflows = styled.div`
  right: 0;
  ${uptoTabletMd} {
    bottom: 1em;
  }
  ${fromDesktopMd} {
    bottom: 3em;
  }
  img {
    transform: scale(0.9) translateX(75%);
    ${fromTablet} {
      transform: scale(0.9) translateX(65%);
    }
    ${fromTabletLg} {
      transform: scale(0.9) translateX(35%);
    }
  }
`;

const YDWWTHero = (props: { ydwwt: DatoCmsYdwwt }) => {
  return (
    <HeroSection
      backdrop="grid"
      backdropColor={colors.dark500}
      backdropParallax
    >
      <InnerWrap>
        <Spacing size="5em" />
        <h1>
          <span>
            You did <YDWWTWhatSticker />
          </span>{" "}
          <span>with Tines</span>
        </h1>
        <Spacing size="md" />
        <WithCustomInlineFormatting as="h2">
          {props.ydwwt.heroSectionSubheading}
        </WithCustomInlineFormatting>
        <Spacing size="md" />
        <Description>
          <StructuredTextWithCustomBlocks
            value={props.ydwwt.heroSectionBodyCopy}
            linkedHeadingMaxLevel="off"
          />
        </Description>
        <Spacing size="md" />
        <YDWWTSubmitButtonWithNotice ydwwt={props.ydwwt} />

        <DecoStickerShapes className={DecoSticker}>
          <img
            src={ImageStickerShapes}
            srcSet={makeSrcSet2x(ImageStickerShapes, ImageStickerShapes2x)}
            width="195"
            height="182"
          />
        </DecoStickerShapes>
        <DecoStickerHeart className={DecoSticker}>
          <img
            src={ImageStickerHeart}
            srcSet={makeSrcSet2x(ImageStickerHeart, ImageStickerHeart2x)}
            width="154"
            height="151"
          />
        </DecoStickerHeart>
        <DecoStickerCreatorWithLogo className={DecoSticker}>
          <img
            src={ImageStickerCreatorWithLogo}
            srcSet={makeSrcSet2x(
              ImageStickerCreatorWithLogo,
              ImageStickerCreatorWithLogo2x
            )}
            width="267"
            height="194"
          />
        </DecoStickerCreatorWithLogo>
        <DecoStickerAllYourWorkflows className={DecoSticker}>
          <img
            src={ImageStickerAllYourWorkflows}
            srcSet={makeSrcSet2x(
              ImageStickerAllYourWorkflows,
              ImageStickerAllYourWorkflows2x
            )}
            width="303"
            height="221"
          />
        </DecoStickerAllYourWorkflows>
      </InnerWrap>
    </HeroSection>
  );
};

export default YDWWTHero;
