import { styled } from "@linaria/react";
import {
  DatoCmsCustomerFocusSection,
  DatoCmsLibraryCollection,
  DatoCmsStoryDigest,
} from "../../../../graphql-types";
import { withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  darkModeLinariaCSS,
  lightModeLinariaCSS,
} from "../../../utils/colorScheme.utils";
import Pill from "../../basic/Pill";
import { Link } from "gatsby";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import Spacing from "../../layout/Spacing";
import StoryEntry from "../StoryEntry";
import { colors, getBrandColorTheme } from "../../../styles/colors.styles";
import { convertDatoRecordToStoryDescriptor } from "../../../utils/story.utils";
import { cx } from "linaria";
import { CSSProperties } from "react";
import { Paths } from "../../../utils/pathBuilders.utils";

const SectionWrap = styled.div`
  background-color: ${withOpacity(colors.light400, 0.4)};
  border-radius: ${rSize("radius")};
  padding: ${rSize("gap")};
  text-decoration: none;
  font-size: 1.6rem;
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.dark500, 0.8)};`
  )}
  &.hasStories {
    display: grid;
    ${fromTabletLg} {
      grid-template-columns: 1fr 1fr;
    }
    grid-gap: ${rSize("gap")};
  }
`;

const Header = styled.header`
  padding: ${rSize("gap")};
  .noStories & {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-gap: ${rSize("gap")};
    align-items: center;
    min-height: 25em;
  }
  h2 {
    max-width: 15em;
    font-size: 2.8rem;
    ${fromTabletLg} {
      font-size: 3.4rem;
    }
  }
  p {
    max-width: 25em;
    a {
      color: var(--ac, ${colors.purple});
    }
    + p {
      margin-top: 1em;
    }
  }
`;

const HeaderTextContent = styled.div``;

const LogoWrap = styled.div`
  img {
    max-height: 4em;
    width: auto;
  }
  .noStories & {
    background-color: ${withOpacity(colors.lightest, 0.5)};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.darkest, 0.5)};`
    )}
    border-radius: ${rSize("radius")};
    padding: ${rSize("lg")};
  }
  .hasStories & {
    margin-top: ${rSize("gap")};
  }
`;

const Stories = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  > * {
    border-radius: ${rSize("radius")};
  }
`;

const ImageWrap = styled.div`
  img {
    &.light {
      ${darkModeLinariaCSS("display: none;")}
    }
    &.dark {
      ${lightModeLinariaCSS("display: none;")}
    }
  }
`;

export const CustomerFocusSection = ({
  collection,
  section,
}: {
  collection: DatoCmsLibraryCollection;
  section: DatoCmsCustomerFocusSection;
}) => {
  const imageUrl = section.customer?.logo?.url;
  const imageDarkModeUrl = section.customer?.logoWhite?.url;
  const image = (
    <ImageWrap>
      {imageUrl ? (
        <>
          <img
            className={imageDarkModeUrl ? "light" : undefined}
            src={imageUrl}
          />
          {imageDarkModeUrl && <img className="dark" src={imageDarkModeUrl} />}
        </>
      ) : null}
    </ImageWrap>
  );
  const theme = getBrandColorTheme(
    section.caseStudy?.color ?? collection.color
  );
  return (
    <SectionWrap
      id="customer-focus"
      className={cx(section.stories?.length ? "hasStories" : "noStories")}
      style={
        {
          "--ac": theme.c500,
        } as CSSProperties
      }
    >
      <Header>
        <HeaderTextContent>
          <Pill color={theme.name} appearance="translucent">
            Customer focus
          </Pill>
          <Spacing size="1.5em" />
          <h2>{section.heading}</h2>
          <Spacing size="1em" />
          <p>{section.description}</p>
          <p>
            Learn more about{" "}
            <Link to={Paths.caseStudy(section.caseStudy?.slug)}>
              how{" "}
              {section.caseStudy?.slug
                ? `${section.customer?.name ?? "this customer"} uses`
                : "our customers use"}{" "}
              Tines
            </Link>
            .
          </p>
        </HeaderTextContent>
        {section.customer?.logo?.url && (
          <LogoWrap>
            {section.caseStudy?.slug ? (
              <Link to={Paths.caseStudy(section.customer.slug)}>{image}</Link>
            ) : (
              image
            )}
          </LogoWrap>
        )}
      </Header>
      <Stories>
        {section.stories?.map(s => (
          <StoryEntry
            story={convertDatoRecordToStoryDescriptor(s as DatoCmsStoryDigest)}
            key={s!.id}
          />
        ))}
      </Stories>
    </SectionWrap>
  );
};
