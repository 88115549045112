import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import NewsletterSignupForm from "../forms/NewsletterSignupForm";
import SpotIllustration from "../illustrations/SpotIllustration";
import Spacing from "../layout/Spacing";

type Props = {
  title?: string;
  eventName: string;
};

const EventRegistrationClosedNoticeContainer = styled.form`
  position: relative;
  border-radius: 1.5em;
  text-align: center;
  padding: 1em 1em 2.5em 1em;
  background-color: ${colorsV4.canvas550};
  ${fromTablet} {
    padding: 1em 2.5em 2.5em 2.5em;
    border-radius: 2em;
  }
  ${fromDesktopMd} {
    padding: 1em 3.5em 3em 3.5em;
  }
  h3 {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 0.5em;
  }
  > p {
    + p {
      margin-top: 1.4rem;
    }
  }
`;

const EventRegistrationClosedNotice = (props: Props) => {
  return (
    <EventRegistrationClosedNoticeContainer>
      <SpotIllustration name="accessForbidden" />
      <h3>{props.title ?? "Event registration has closed."}</h3>
      <p>
        Subscribe to our newsletter below to get notified of upcoming Tines
        events, webinars and other helpful resources.
      </p>
      <Spacing size="1em" />
      <NewsletterSignupForm />
    </EventRegistrationClosedNoticeContainer>
  );
};

export default EventRegistrationClosedNotice;
