import { graphql } from "gatsby";
import { PageComponent } from "../types/gatsby.types";
import Page from "./Page.template";
import {
  DatoCmsSolutionPage,
  DatoCmsStoryDigest,
  SolutionPageBySlugQuery,
} from "../../graphql-types";
import { StoryDescriptor } from "../types/helper.types";
import SolutionPageHero from "../components/solutions/SolutionPageHero";
import SolutionPageWorkflowSection from "../components/solutions/SolutionPageWorkflowSection";
import { colors } from "../styles/colors.styles";
import SolutionPageQuoteSection from "../components/solutions/SolutionPageQuoteSection";
import SolutionPageLibrarySection from "../components/solutions/SolutionPageLibrarySection";
import { convertDatoRecordToStoryDescriptor } from "../utils/story.utils";
import { uniqBy } from "lodash-es";
import { styled } from "@linaria/react";
import Spacing from "../components/layout/Spacing";
import SolutionPageCTASection from "../components/solutions/SolutionPageCTASection";
import SEO from "../components/utilities/SEO";
import { stripCustomInlineFormattingSyntax } from "../components/typography/WithCustomInlineFormatting";
import SolutionPageCustomerLogosSection from "../components/solutions/SolutionPageCustomerLogosSection";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";

const SolutionPageContent = styled.div`
  color: ${colors.purple800};
`;

const SolutionPageTemplate: PageComponent<{
  data: SolutionPageBySlugQuery;
  pageContext: {
    slug: string;
    autoSelectedStories: StoryDescriptor[];
  };
}> = props => {
  const page = props.data.solution as DatoCmsSolutionPage;

  const handPickedStories = (
    (page.featuredStories ?? []) as DatoCmsStoryDigest[]
  ).map(convertDatoRecordToStoryDescriptor);

  const featuredStories = uniqBy(
    [...handPickedStories, ...props.pageContext.autoSelectedStories],
    i => i.id
  ).slice(0, 6);

  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title={`${page.name} Solutions: ${stripCustomInlineFormattingSyntax(
          page.headline
        )}`}
        description={stripCustomInlineFormattingSyntax(page.description)}
        image={page.previewImage?.url}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="solutionPage"
        recordId={page.id}
      />
      <SolutionPageContent>
        <SolutionPageHero page={page} />
        <SolutionPageWorkflowSection
          page={page}
          shaded={!page.quoteSectionQuoteContent}
        />
        <SolutionPageCustomerLogosSection />
        {page.quoteSectionQuoteContent && (
          <SolutionPageQuoteSection
            page={page}
            shaded={!!page.quoteSectionQuoteContent}
          />
        )}
        <Spacing size="xl" />
        <SolutionPageLibrarySection
          page={page}
          padTop={!page.quoteSectionQuoteContent}
          stories={featuredStories}
        />
        <Spacing size="sectionMargin" />
        <SolutionPageCTASection page={page} />
        <Spacing size="sectionMargin" />
      </SolutionPageContent>
    </Page>
  );
};

export const solutionPageBySlugQuery = graphql`
  query SolutionPageBySlug($slug: String) {
    solution: datoCmsSolutionPage(slug: { eq: $slug }) {
      id: originalId
      name
      slug
      headline
      description
      color
      pageIllustrationType
      featuredProductImageOrVideo {
        url
        width
        height
      }
      workflowSteps {
        id: originalId
        name
        description
        media {
          url
          width
          height
        }
      }
      quoteSectionHeadline
      quoteSectionDescription
      quoteSectionQuoteContent
      quoteSectionQuoteAuthorName
      quoteSectionQuoteAuthorWorkTitle
      quoteSectionQuoteAuthorOrgnization {
        name
      }
      quoteSectionResourceLinks {
        id: originalId
        textLabel
        url
      }
      quoteSectionDataHighlightData
      quoteSectionDataHighlightDescription
      quoteSectionDataHighlightIcon
      libraryCollection {
        name
        collectionType
        slug
        color
      }
      useCases
      featuredStories {
        descriptor
        internalAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        communityAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        videoIntroEmbedUrl
        gallery {
          url
          width
          height
        }
      }
      ctaSectionHeadline
      ctaSectionBodyText
      ctaSectionPrimaryButton
      previewImage {
        url
      }
    }
  }
`;

export default SolutionPageTemplate;
