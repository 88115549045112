import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { useSiteContext } from "../../context/site.context";
import {
  fromDesktop,
  fromDesktopMl,
  fromDesktopSm,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  brandColorThemeVar,
  getBrandColorThemeVariables,
} from "../../styles/colors.styles";
import { DatoCmsPerson, DatoCmsWebinar } from "../../../graphql-types";
import { MMMMDYYYY, getReadableTimezoneName } from "../../utils/time.utils";
import DateTimeFormatter from "../basic/DateTimeFormatter";
import { cx } from "linaria";
import { rSize } from "../../styles/responsiveSizes.styles";
import PersonInfoItem from "../general/PersonInfoItem";
import { CSSProperties } from "react";
import { PersonPhotoIconContainer } from "../general/PersonPhotoIcon";

type Props = {
  webinar: Partial<DatoCmsWebinar>;
  noDescription?: boolean;
  featured?: boolean;
  showFeaturedTag?: boolean;
};

const WebinarEntryLinkInner = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: 2.8rem 1.8em;
  min-height: 28rem;
  .featured & {
    ${fromDesktop} {
      min-height: 35rem;
    }
  }
`;

const ContentSection = styled.div`
  > * {
    + * {
      margin-top: 1.8rem;
    }
  }
`;

const webinarEntryLinkStyle = css`
  display: grid;
  grid-template-columns: minmax(auto, minmax(0, 1fr));
  grid-gap: 1.8em;
  text-decoration: none;
  width: 100%;
  background-color: ${brandColorThemeVar(50)};
  color: ${brandColorThemeVar(700)};
  padding: 1.5em;
  border-radius: ${rSize("radius")};
  background-position: 0 0;
  ${fromTablet} {
    padding: 2em;
    &.featured {
      padding: 2.5em;
    }
  }
  ${fromDesktopSm} {
    &.featured {
      color: ${brandColorThemeVar(800)};
      ${PersonPhotoIconContainer} {
        width: 6.4rem;
        height: 6.4rem;
        > figure,
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:only-child {
        background-image: var(--backgroundImage);
        background-size: auto 100%;
        background-position: right;
        background-repeat: no-repeat;
        padding-right: 30rem;
      }
    }
  }
  &:hover {
    background-color: ${brandColorThemeVar(100)};
  }
  ${PersonPhotoIconContainer} {
    mix-blend-mode: multiply;
  }
`;

const Title = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: -0.01em;
  ${fromDesktop} {
    font-size: 2.8rem;
    .featured & {
      font-size: 3.2rem;
    }
  }
  .featured:only-child & {
    ${fromDesktop} {
      font-size: 4.2rem;
      line-height: 1;
      max-width: 14em;
    }
    ${fromDesktopMl} {
      font-size: 5.2rem;
    }
  }
`;

const TimeStamp = styled.div`
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 1.15rem;
`;

const WebinarHostList = styled.div`
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
  .featured:only-child & {
    display: flex;
    flex-wrap: wrap;
    > * {
      margin-right: 1em;
      + * {
        margin-top: 0;
      }
    }
  }
`;

const Excerpt = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 32em;
  font-size: 1.5rem;
`;

const WebinarEntryLink = (props: Props) => {
  const { webinar } = props;
  const siteContext = useSiteContext();
  return (
    <Link
      to={`/${webinar.category?.slug ?? "webinars"}/${webinar.slug}`}
      title={webinar.title ?? "Tines Webinar"}
      className={cx(
        webinarEntryLinkStyle,
        props.featured ? "featured" : "regular"
      )}
      style={
        {
          ...getBrandColorThemeVariables(webinar.colorTheme),
          "--backgroundImage": webinar.upcomingWebinarEntryBackdrop?.url
            ? `url(${webinar.upcomingWebinarEntryBackdrop?.url})`
            : "none",
        } as CSSProperties
      }
    >
      <WebinarEntryLinkInner {...props}>
        <ContentSection>
          <TimeStamp {...props}>
            <div>
              <DateTimeFormatter
                value={webinar.timeStart as string | null}
                format={MMMMDYYYY}
              />
            </div>
            <div>
              <DateTimeFormatter
                value={webinar.timeStart as string | null}
                format="h:mm A"
              />{" "}
              {getReadableTimezoneName(siteContext.timezone)} Time
            </div>
          </TimeStamp>
          <Title>{webinar.title}</Title>
          {props.featured && <Excerpt>{webinar.excerpt}</Excerpt>}
        </ContentSection>
        {!!webinar.hosts?.length && (
          <WebinarHostList>
            {webinar.hosts.map(host => (
              <PersonInfoItem
                person={host as DatoCmsPerson}
                key={host!.id}
                iconSize={38}
              />
            ))}
          </WebinarHostList>
        )}
      </WebinarEntryLinkInner>
    </Link>
  );
};

export default WebinarEntryLink;
