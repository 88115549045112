import { styled } from "@linaria/react";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
  onlyPhones,
  uptoPhoneMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import Button, {
  ButtonGroupCenteredOnMobile,
} from "../components/forms/Button";
import { DatoCmsContentCard, PartnersPageQuery } from "../../graphql-types";
import { externalLinkAttr } from "../constants/externalLinks.constants";
import HeroSection, {
  HeroSectionDescription,
} from "../components/sections/HeroSection";
import { colors } from "../styles/colors.styles";
import AllCaps from "../components/typography/AllCaps";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { PartnersIllustration } from "../components/illustrations/neue/PartnersIllustration";
import PartnerDirectorySection from "../components/partners/PartnerDirectorySection";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import PageSection from "../components/reusableSections/PageSection";
import { SectionHeading2 } from "../components/typography/SectionHeading2";
import { rSize } from "../styles/responsiveSizes.styles";
import QuoteCardNeue from "../components/general/QuoteCardNeue";
import { LogoOakRidge } from "../components/thirdPartyLogos/LogoOakRidge";
import { LogoTuro } from "../components/thirdPartyLogos/LogoTuro";
import { graphql } from "gatsby";
import ArticleEntryLink from "../components/articles/ArticleEntryLink";
import { ContentCardDispatcher } from "../components/contentCards/_ContentCardDispatcher";
import { fluidFontSize } from "../styles/helpers.styles";
import ogImage from "../../static/images/og/tines-partners.png";
import { LogoBCMOne } from "../components/thirdPartyLogos/LogoBCMOne";

const HeaderGroup = styled.div`
  h1 {
    ${fluidFontSize(36, 56)};
  }
`;

const IllustrationWrap = styled.div`
  ${uptoPhoneMd} {
    margin-top: -1em;
    margin-left: -3.25em;
    margin-right: -3.25em;
  }
  ${mediaFromTo("phoneLg", "desktop")} {
    max-width: 75vw;
    margin-left: auto;
    margin-right: auto;
  }
  svg {
    max-width: 100%;
    height: auto;
  }
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    margin-top: -2em;
  }
`;

const QuoteGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletLg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  min-height: 26em;
  align-items: stretch;
`;

const BlogPostsGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  align-items: stretch;
`;

const FeaturedContentCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(4, 1fr);
    min-height: 23em;
  }
`;

const PagePartners: PageComponent<{
  data: PartnersPageQuery;
}> = props => {
  const description =
    "Our industry-leading partner network includes 200+ innovative companies – from global consultants to regional experts – to support your business needs today and well into the future.";
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO title="Partners" description={description} image={ogImage} />
      <AdminToolBarWithEditInDatoCmsButton itemType="partnerPage" />
      <HeroSection
        textColor={colors.purple800}
        backdrop="grid"
        backdropColor={colors.purple100}
        backdropAnimateIn
        backdropParallax
        contentRelative
      >
        <IllustrationWrap>
          <PartnersIllustration />
        </IllustrationWrap>
        <Spacing size="lg" />
        <HeaderGroup>
          <AllCaps as="p">Partners</AllCaps>
          <Spacing size="1.5em" />
          <h1>
            <span>Work smarter with</span> <span>Tines’ trusted partners</span>
          </h1>
          <HeroSectionDescription>{description}</HeroSectionDescription>
          <Spacing size="lg" />
          <ButtonGroupCenteredOnMobile>
            <Button
              to="/partners/become-a-partner"
              title="Become a partner"
              darker
            />
            <Button
              href="https://partners.tines.com/"
              title="Visit the partner portal"
              appearance="outlined"
              darker
              {...externalLinkAttr}
            >
              Partner portal <sup>↗</sup>
            </Button>
          </ButtonGroupCenteredOnMobile>
        </HeaderGroup>
      </HeroSection>

      <Spacing size="sectionPadding" />

      <PartnerDirectorySection />

      <Spacing size="sectionMargin" />

      <PageSection>
        <SectionHeading2 lighter centered maxWidth="15em">
          Make your tools and systems work together like never before
        </SectionHeading2>
        <Spacing size="sectionPadding" />
        <QuoteGrid>
          <QuoteCardNeue
            color="green"
            sourceName="Pete Wood"
            sourceDetails="Lead Engineer"
            sourceLogo={<LogoOakRidge />}
            fontSize="medium"
            footerLayout="space-between"
          >
            [We’re] putting Elastic and Tines to work and extracting insights we
            always wanted but could never get to. We didn’t really go into this
            thinking Tines would solve for that, but it has.
          </QuoteCardNeue>
          <QuoteCardNeue
            color="orange"
            sourceName="Dan Rubins"
            sourceDetails="VP of IT and Information Security"
            sourceLogo={<LogoBCMOne />}
            fontSize="medium"
            footerLayout="space-between"
          >
            We used the tools we’ve bought, from CrowdStrike to RunZero to
            others, much more effectively because we have Tines.
          </QuoteCardNeue>
          <QuoteCardNeue
            color="purple"
            /* cspell:disable */
            sourceName="Shasheen Bandodkar"
            /* cspell:enable */
            sourceDetails="Information Security Analyst"
            sourceLogo={<LogoTuro />}
            fontSize="medium"
            footerLayout="space-between"
          >
            Before Tines, if I wanted to relay information related to something
            that was happening in AWS, I had to interact with engineering, and
            creating that ticket was very manual. With Tines, this entire
            process became seamlessly automated.
          </QuoteCardNeue>
        </QuoteGrid>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <SectionHeading2 lighter centered>
          Backed by the companies
          <br /> you trust
        </SectionHeading2>
        <Spacing size="sectionPadding" />
        <BlogPostsGrid>
          {props.data.page?.articles?.map(a => (
            <ArticleEntryLink record={a!} key={a!.id} />
          ))}
        </BlogPostsGrid>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <SectionHeading2 lighter centered>
          Get the latest updates
        </SectionHeading2>
        <Spacing size="sectionPadding" />
        <FeaturedContentCardGrid>
          {props.data.page?.featuredContentCards?.map((c, i) => (
            <ContentCardDispatcher card={c as DatoCmsContentCard} key={i} />
          ))}
        </FeaturedContentCardGrid>
      </PageSection>

      <Spacing size="sectionMargin" />

      <ExplosionCTASection />
    </Page>
  );
};

export const partnersPageQuery = graphql`
  query PartnersPage {
    page: datoCmsPartnersPage {
      articles: featuredBlogArticles {
        id: originalId
        title
        slug
        excerpt
        coverImage {
          url
          width
          height
        }
      }
      featuredContentCards {
        preset
        linkToRecord {
          ... on DatoCmsArticle {
            id: originalId
            slug
            title
            model {
              apiKey
            }
          }
          ... on DatoCmsWebinar {
            id: originalId
            slug
            title
            colorTheme
            category {
              slug
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsCaseStudy {
            id: originalId
            color
            slug
            title
            customer {
              logoDarkGray {
                url
                width
                height
              }
              logoWhite {
                url
                width
                height
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsPodcast {
            id: originalId
            applePodcastsLink
            color
            episodeNumber
            title
            spotifyLink
            season {
              seasonNumber
              title
            }
            guestSpeaker {
              name
              surname
              title
              organization {
                name
              }
            }
            guestSpeakerImageTransparent {
              url
              width
              height
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsDocument {
            id: originalId
            title
            slug
            description {
              value
            }
            category {
              name
              slug
            }
            previewImage {
              url
              width
              height
            }
          }
        }
        linkLabel
        linkUrl
        preHeadingTag
        heading
        description
        size
        color
        colorMode
        backgroundImage {
          url
        }
        icon {
          url
          width
          height
        }
      }
    }
  }
`;

export default PagePartners;
