import Pill from "../../basic/Pill";
import ReportCard from "../ReportCard";
import ReportSection from "../ReportSection";
import { Serif } from "../../typography/Serif";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import GalaxyGateIconForDarkBackground from "./GalaxyGateIconForDarkBackground";
import NumberedListCardGrid from "../NumberedListCardGrid";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import Spacing from "../../layout/Spacing";
import ReportSummaryCard from "../ReportSummaryCard";
import { Columned } from "../../layout/Columned";

const NoCodePlaybookChapter5 = () => {
  return (
    <ReportSection
      id="preparing-for-the-future"
      themeName="warmBlack"
      smallerSectionHeading
      header={
        <>
          <div>
            <GalaxyGateIconForDarkBackground />
          </div>
          <Pill color="purple">Part Five</Pill>
          <h2>
            Preparing for the future
            <br />
            <Serif>with no-code automation</Serif>
          </h2>
          <p>
            Here are five things security leaders can do to prepare their
            security team for what lies ahead.
          </p>
        </>
      }
    >
      <ReportCard largerHeading>
        <Pill color="purple">Actionable tip 1</Pill>
        <h3>Start small and experiment with core use cases</h3>
        <Columned>
          <p>
            In thinking about a more sustainable, scalable future for your
            SecOps team, automation is going to be the most important strategy
            you could implement. When analysts have the ability to automate
            their repetitive tasks, they’re not only creating more efficient
            workflows for everyday processes, they’re freeing up their time to
            actually do what they love: analysis.
          </p>
          <p>
            However, security leaders often fear they don’t have the bandwidth
            or tooling in place to fully leverage security automation.
            Or, if they adopt automation, they want to automate all their
            processes immediately. True no-code cloud-based automation platforms
            offer you the freedom to start small with free Community Editions or
            trials and grow as your business needs evolve. With automation as
            well, you can start with a set of core use cases and demonstrate
            their value before expanding.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="orange">Actionable tip 2</Pill>
        <h3>
          Think hard about whether the time is right to build an internal SOC
        </h3>
        <Columned>
          <p>
            The days of having an SOC as a standalone team responsible for
            security are coming to a close. Not only have their costs risen in
            recent years, but their complexity has as well, and{" "}
            <a
              href="https://www.mandiant.com/resources/economics-of-the-soc-2021-ponemon-institute-second-annual-study"
              {...externalLinkAttr}
            >
              over half of organizations say the ROI of their SOC is getting
              worse
            </a>
            .
          </p>
          <p>
            As organizations recognize that security touches everyone, security
            professionals will be embedded into each team in the enterprise.
            Security leaders should consider whether the investment required in
            building an SOC would be better spent elsewhere — like implementing
            automation or developing more iterative approaches to security.
            While SOCs are phased out, an outsourced SOC or MSSP could provide
            an interim solution.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="pink">Actionable tip 3</Pill>
        <h3>
          Be prepared to pitch for automated access to critical, non-security
          systems
        </h3>
        <Columned>
          <p>
            Security teams are tasked with performing remediations for their
            organization, yet much of that work is often found on software that
            the security team has no direct control over. Security teams then
            find themselves dependent on other teams for their own success —
            teams whose priorities may not align with the priorities of SecOps.
            This misalignment and limited access can allow vulnerabilities to
            remain unaddressed.
          </p>
          <p>
            Security teams need to find ways to work with other departments to
            gain access, which will not only require relationship-building and
            trust, but being able to adequately communicate why SecOps needs
            access to those systems. Other departments may be reluctant to grant
            open-ended, manual access, but may be more willing to allow access
            provided it is only used in automated scenarios with known inputs
            and outputs.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="green">Actionable tip 4</Pill>
        <h3>Invest in best-in-breed solutions</h3>
        <Columned>
          <p>
            Organizations experienced an average of{" "}
            <a
              href="https://blog.checkpoint.com/2022/01/10/check-point-research-cyber-attacks-increased-50-year-over-year"
              {...externalLinkAttr}
            >
              925 cyberattacks a week
            </a>{" "}
            in 2021, a 50% increase from 2020. Threats are frequent and
            pervasive, and are becoming too sophisticated to be handled by
            one-size-fits-all solutions. Organizations wanting to stay ahead in
            their security approach need to un-bundle their security stacks and
            all-in-one “big box shops” and invest in best-of-breed security
            tools designed for specific purposes.
          </p>
          <p>
            Thanks to no-code automation, the overhead costs associated with
            managing best-in-breed solutions are no longer a hindrance to
            investment. Since no-code platforms work in sync with known and
            custom tools alike, automation can also eliminate the risk of
            fragmentation by uniting together these specialized solutions.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="purple">Actionable tip 5</Pill>
        <h3>Build a culture of automation</h3>
        <Columned>
          <p>
            In order to see continued success in the future, SecOps teams need
            to build a culture of automation where, when encountered with any
            time-consuming or monotonous workflow or action, the first thought
            is, “Can we automate some or all of this?” In fact, according to{" "}
            <a
              href="https://www.tines.com/reports/voice-of-the-soc-analyst"
              {...externalLinkAttr}
            >
              our recent report
            </a>
            , two-thirds of analysts think that 50% of their current tasks could
            be automated today. Still, there’s no shortage of work. A culture of
            automation will get teams used to recognizing where they can make
            their tools work harder for them and allow them to shift their focus
            to more pressing matters. Building automations will also become an
            essential skill for security practitioners as well.
          </p>
        </Columned>
        <Spacing size=".5em" />
        <h4>Security leaders can start by doing the following:</h4>
        <Spacing size="1.5em" />
        <NumberedListCardGrid backgroundColor={colorsV4.purple50}>
          <li>
            Annotate as you build your no-code automations so that colleagues
            can understand how the workflow executes.
          </li>
          <li>
            Extract the shared sequences that begin to pop up repeatedly into
            modules that can be reused across your workflows.
          </li>
          <li className="fullWidth">
            Set up monitoring to ensure that when something does break, a human
            gets notified.
          </li>
          <li>
            Continuously improve upon your automations, and think creatively
            about what can make the workflow run faster.
          </li>
          <li>Demonstrate the value you find in automation to leadership.</li>
        </NumberedListCardGrid>
      </ReportCard>

      <ReportSummaryCard
        backgroundColor={colorsV4.purple700}
        textColor={colorsV4.white}
      >
        <blockquote>
          <p>
            Building for the future of SecOps doesn’t have to be daunting. By
            investing in and implementing automation, you’ll be able to improve
            efficiency, respond to incidents quicker, automate manual tasks to
            free up your team for higher impact work, and more. So, what
            processes will you implement today that will lead your SecOps team
            into tomorrow?
          </p>
        </blockquote>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default NoCodePlaybookChapter5;
