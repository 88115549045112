import { getStandardColorThemeNameV4 } from "../../styles/colorsV4.styles";
import {
  DatoCmsArticleCategory,
  DatoCmsEventCategory,
  DatoCmsWebinarCategory,
} from "../../../graphql-types";
import Pill, { PillProps } from "./Pill";

type Props = {
  category:
    | DatoCmsWebinarCategory
    | DatoCmsArticleCategory
    | DatoCmsEventCategory;
} & PillProps;

const CategoryPill = (props: Props) => {
  const color = (props.category as DatoCmsWebinarCategory).color;
  return (
    <Pill color={getStandardColorThemeNameV4(color)} {...props}>
      {props.category.name}
    </Pill>
  );
};

export default CategoryPill;
