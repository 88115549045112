import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import mainIllustration from "./illustrations/WorkbenchMainIllustration.svg";
import workbenchPageAIAndTinesIcon from "./illustrations/workbenchPageAIAndTinesSpotIcon.svg";
import WorkbenchPageSecurityIcon from "./illustrations/workbenchPageSecuritySpotIcon.svg";
import Spacing from "../layout/Spacing";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { rSize } from "../../styles/responsiveSizes.styles";
import { w } from "../../utils/string.utils";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import ParallaxProductGrid from "../decorations/ParallaxProductGrid";
import WorkbenchCustomWorkflowsIllustration from "./illustrations/WorkbenchCustomWorkflowsIllustration.svg";
import WorkbenchEnterpriseReadyIllustration from "./illustrations/WorkbenchEnterpriseReadyIllustration.svg";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPaths";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopSm,
  fromTablet,
  fromTabletMd,
  mediaFromTo,
  onlyPhones,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";

const MainImage = styled.div`
  height: 200px;
  ${fromTablet} {
    height: 320px;
  }
  display: flex;
  justify-content: center;
  position: relative;
  img {
    display: block;
    max-height: 100%;
  }
`;

const TwoSplit = styled.div`
  display: grid;
  position: relative;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    max-width: 18em;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTablet} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "${w("l", 5)} . . ${w("r", 5)}";
    padding-bottom: 48px;
  }
  ${fromDesktop} {
    grid-template-areas: ". ${w("l", 4)} . . ${w("r", 4)} .";
    padding-bottom: 96px;
  }
  ${fromDesktopMd} {
    grid-template-areas: ". . ${w("l", 3)} . . ${w("r", 3)} . .";
  }
`;

const TwoSplitItem = styled.div`
  &:nth-child(1) {
    ${fromTablet} {
      grid-area: l;
    }
    h3 {
      ${fromTablet} {
        margin-right: -1em;
      }
    }
  }
  &:nth-child(2) {
    ${fromTablet} {
      grid-area: r;
    }
  }
  > * {
    + * {
      margin-top: 1.8rem;
    }
  }
  h3 {
    font-size: 2.2rem;
    letter-spacing: -0.01em;
    font-weight: 600;
    line-height: 1.2;
    span {
      white-space: nowrap;
    }
  }
`;

const ThreeSplit = styled.div`
  display: grid;
  position: relative;
  grid-gap: ${rSize("gap")};
  padding-top: 72px;
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-bottom: 72px;
  }
  ${mediaFromTo("tabletMd", "tabletLg")} {
    grid-gap: 0;
  }
`;

const ThreeSplitCard = styled.div`
  border-radius: 1.2rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) auto;
  grid-gap: 1.5em;
  ${fromTablet} {
    grid-gap: 3em;
  }
  ${mediaFromTo("tabletMd", "tabletLg")} {
    border-radius: 0;
    &:nth-child(1) {
      border-top-left-radius: 1.2rem;
      border-bottom-left-radius: 1.2rem;
    }
    &:nth-child(3) {
      border-top-right-radius: 1.2rem;
      border-bottom-right-radius: 1.2rem;
    }
  }
  overflow: hidden;
  position: relative;
  &.purple {
    background-color: ${colors.purple50};
    color: ${colors.purple800};
    border: 1px solid ${colors.purple200};
  }
  &.pink {
    background-color: ${colors.pink50};
    color: ${colors.pink800};
    border: 1px solid ${colors.pink200};
  }
  &.green {
    background-color: ${colors.green50};
    color: ${colors.green800};
    border: 1px solid ${colors.green200};
  }
`;

const ThreeSplitCardHeader = styled.header`
  padding: 2.4rem;
  text-align: left;
  h3 {
    font-family: ${serif};
    font-size: 2.8rem;
    font-weight: 400;
    letter-spacing: -0.02rem;
    margin-bottom: 0.5em;
    span {
      display: block;
    }
  }
  p {
    font-size: 1.4rem;
    max-width: 18.5em;
    span {
      white-space: nowrap;
    }
  }
`;

const ThreeSplitCardFigure = styled.figure`
  margin: 0 0 0 0;
  position: relative;
  height: 280px;
  > img {
    display: block;
  }
`;

export const WorkbenchExplainedSection = () => (
  <PageSection
    clipOverflow
    relative
    className={css`
      ${onlyPhones} {
        text-align: center;
      }
    `}
  >
    <ConnectorPathWaypoint
      id="workbench-page-quote-section"
      index={3}
      color={colors.light200}
      top="0"
      left="50%"
    />
    <SectionHeading2
      lighter
      centered
      className={css`
        ${onlyPhones} {
          span {
            display: block;
          }
        }
      `}
    >
      <span>Workbench is</span> <span>different</span>
    </SectionHeading2>
    <Spacing size="lg" />
    <MainImage>
      <ConnectorPathWaypoint
        id="workbench-page-quote-section"
        index={4}
        color={colors.light200}
        top="50%"
        left="50%"
      />
      <img src={mainIllustration} alt="Workbench main illustration" />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-vertical"
        index={0}
        color={colors.light200}
        top="50%"
        left="50%"
      />
    </MainImage>
    <Spacing size="lg" />
    <TwoSplit>
      <TwoSplitItem>
        <img
          src={workbenchPageAIAndTinesIcon}
          width={89}
          height={100}
          alt="AI + Tines workflows icon"
        />
        <h3>
          Where copilots and <span>AI chat</span> meets{" "}
          <span>Tines workflows</span>
        </h3>
        <p>
          We brought the familiar power of our intuitive workflow builder and
          integrate with anything platform to our AI-powered chat interface.
          Access your data and take action in real time.
        </p>
      </TwoSplitItem>
      <TwoSplitItem>
        <img
          src={WorkbenchPageSecurityIcon}
          width={104}
          height={100}
          alt="Security icon"
        />
        <h3>
          The same strong, <span>secure foundation</span>
        </h3>
        <p>
          All of our AI-powered features run in the same smart, secure, and
          private architecture. Your data never leaves the stack, does not
          travel on the open internet, is not logged, and is never used for
          training.
        </p>
      </TwoSplitItem>
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-vertical"
        index={1}
        color={colors.light200}
        bottom={24}
        left="50%"
      />

      <ConnectorPathWaypoint
        id="workbench-page-explained-section-open-left"
        index={0}
        color={colors.light200}
        bottom={36}
        left="50%"
      />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-open-left"
        index={1}
        color={colors.light200}
        bottom={0}
        left="50%"
        radius={36}
      />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-open-left"
        index={2}
        color={colors.light200}
        bottom={0}
        left={72}
        radius={48}
      />

      <ConnectorPathWaypoint
        id="workbench-page-explained-section-open-right"
        index={0}
        color={colors.light200}
        bottom={24}
        right="50%"
      />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-open-right"
        index={1}
        color={colors.light200}
        bottom={-24}
        right="50%"
        radius={28}
      />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-open-right"
        index={2}
        color={colors.light200}
        bottom={-24}
        right={72}
        radius={48}
      />
    </TwoSplit>

    <ThreeSplit>
      <ThreeSplitCard className="pink">
        <ConnectorPathWaypoint
          id="workbench-page-explained-section-open-left"
          index={3}
          color={colors.pink100}
          top={0}
          left={71}
        />
        <ThreeSplitCardHeader>
          <h3>
            <span>Seamlessly</span> <span>connect</span>
          </h3>
          <p>
            <strong>Bring your data into your chat.</strong>{" "}
            <span>Give your</span> chat superpowers in hundreds of tools like
            Slack, Jira, and VirusTotal.
          </p>
        </ThreeSplitCardHeader>
        <ThreeSplitCardFigure>
          <ParallaxProductGrid count={64} />
        </ThreeSplitCardFigure>
        <ConnectorPathWaypoint
          id="workbench-page-explained-section-close-left"
          index={0}
          color={colors.pink100}
          bottom={0}
          left={71}
        />
      </ThreeSplitCard>

      <ThreeSplitCard className="green">
        <ThreeSplitCardHeader>
          <h3>
            <span>Custom</span> <span>workflows</span>
          </h3>
          <p>
            <strong>Combine with the full power of Tines.</strong>{" "}
            <span>Connect any</span> send-to-story for compound tasks like “Kick
            off incident” or “Isolate device”.
          </p>
        </ThreeSplitCardHeader>
        <ThreeSplitCardFigure>
          <img
            src={WorkbenchCustomWorkflowsIllustration}
            className={css`
              margin-left: 2.4rem;
              min-width: 100%;
            `}
          />
        </ThreeSplitCardFigure>
      </ThreeSplitCard>

      <ThreeSplitCard className="purple">
        <ConnectorPathWaypoint
          id="workbench-page-explained-section-open-right"
          index={3}
          color={colors.purple100}
          top={0}
          right={71}
        />
        <ThreeSplitCardHeader>
          <h3>
            <span>Enterprise-</span>
            <span>ready</span>
          </h3>
          <p>
            <strong>Controls you need, out of the box.</strong>{" "}
            <span>Built-in controls</span> like confirmation, data security,
            audit logs, RBAC - all integrated into the core.
          </p>
        </ThreeSplitCardHeader>
        <ThreeSplitCardFigure
          className={css`
            display: flex;
            align-items: flex-end;
            padding: 0 2.4rem 2.4rem;
          `}
        >
          <img
            src={WorkbenchEnterpriseReadyIllustration}
            className={css`
              ${uptoTablet} {
                width: 100%;
              }
              ${fromDesktopSm} {
                width: 100%;
                flex: 1 1 100%;
              }
            `}
          />
        </ThreeSplitCardFigure>
        <ConnectorPathWaypoint
          id="workbench-page-explained-section-close-right"
          index={0}
          color={colors.purple100}
          bottom={0}
          right={71}
        />
      </ThreeSplitCard>

      <ConnectorPathWaypoint
        id="workbench-page-explained-section-close-left"
        index={1}
        color={colors.light200}
        bottom={-24}
        left={72}
        radius={48}
      />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-close-left"
        index={2}
        color={colors.light200}
        bottom={-24}
        left="50%"
        radius={36}
      />

      <ConnectorPathWaypoint
        id="workbench-page-explained-section-close-right"
        index={1}
        color={colors.light200}
        bottom={0}
        right={72}
        radius={48}
      />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-close-right"
        index={2}
        color={colors.light200}
        bottom={0}
        right="50%"
        radius={48}
      />
    </ThreeSplit>
  </PageSection>
);
