import { styled } from "@linaria/react";
import gsap from "gsap";
import { cx } from "linaria";
import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useRef,
} from "react";
import {
  fromTablet,
  onlyPhones,
  onlyTablets,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  ColorThemeNameV4,
  colorsV4,
  getBrandColorThemeC4,
  isNeutralColorThemeV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { clearAnimatedProps } from "../../utils/animations.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import CornerDotSet from "../utilities/CornerDotSet";
import { PillTagStrong } from "../basic/Pill";
import { ReportBaseSectionStyle } from "./ReportBaseStyles";

type Props = PropsWithChildren<{
  id: string;
  className?: string;
  header?: ReactNode;
  themeName?: ColorThemeNameV4;
  innerRef?: RefObject<HTMLDivElement>;
  darkMode?: boolean;
  fullWidthWhenSmall?: boolean;
  smallerSectionHeading?: boolean;
}>;

const ReportSectionContainer = styled.section`
  ${onlyPhones} {
    padding: 1em;
  }
  ${onlyTablets} {
    margin-left: ${rSize("widerPageMargin")};
    margin-right: ${rSize("widerPageMargin")};
  }
  &.fullWidthWhenSmall {
    ${uptoDesktop} {
      padding: 0;
    }
  }
`;

const Header = styled.header`
  ${ReportBaseSectionStyle}
  position: relative;
  background-color: var(--ThemeColorPrimary);
  color: ${colorsV4.white};
  ${onlyPhones} {
    border-radius: 1em;
  }
  ${fromTablet} {
    margin-left: 0;
    margin-right: 0;
  }
  + * {
    margin-top: ${rSize("gap")};
  }
`;

const HeaderInner = styled.div`
  text-align: center;
  padding-bottom: 2.5em;
  ${PillTagStrong} {
    &:first-child {
      ${onlyPhones} {
        margin-top: 2.5em;
      }
      ${fromTablet} {
        margin-top: 1em;
      }
    }
  }
  ${fromTablet} {
    padding-bottom: ${rSize("gap")};
    font-size: 1.6rem;
  }
  img {
    ${onlyPhones} {
      width: 12.5rem;
      height: 12.5rem;
    }
    ${fromTablet} {
      margin-top: -1em;
      margin-bottom: -1em;
    }
  }
  h2 {
    line-height: 1.1;
    font-weight: 600;
    ${fluidFontSize(38, 52)};
    ${fromTablet} {
      line-height: 1;
      letter-spacing: -0.01em;
    }
    .smallerSectionHeading & {
      ${fluidFontSize(28, 42)};
    }
  }
  p {
    font-weight: 500;
    max-width: 62rem;
    margin-left: 1em;
    margin-right: 1em;
    ${fromTablet} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
`;

const Content = styled.div`
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
`;

const ReportSection = (props: Props) => {
  const theme = getBrandColorThemeC4(props.themeName ?? "canvas");
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const navLinkSelector = `.ReportNav a[href$="#${props.id}"]`;
    const navLinkHighlightColor = props.themeName
      ? theme.textHighlight
      : document
          .querySelector(".ReportNav")
          ?.getAttribute("data-accent-color") ?? colorsV4.purple700;
    const getNavLinkHighlightBackgroundColor = () => {
      return window.innerWidth >= 1024
        ? withOpacity(navLinkHighlightColor, 0)
        : withOpacity(navLinkHighlightColor, 0.2);
    };
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
        toggleActions: "restart pause resume pause",
        invalidateOnRefresh: true,
      },
    });
    tl.to(
      ".ReportNav",
      {
        backgroundColor: props.darkMode
          ? colorsV4.warmBlack
          : isNeutralColorThemeV4(theme)
          ? colorsV4.canvas550
          : colorsV4.white,
        color: props.darkMode ? colorsV4.white : colorsV4.warmBlack,
        duration: 0.001,
      },
      0.001
    );
    tl.fromTo(
      navLinkSelector,
      {
        backgroundColor: withOpacity(navLinkHighlightColor, 0),
      },
      {
        color: navLinkHighlightColor,
        backgroundColor: getNavLinkHighlightBackgroundColor(),
        duration: 0.001,
      },
      0.001
    );
    tl.to(
      document.documentElement,
      {
        backgroundColor: props.darkMode ? colorsV4.warmBlack900 : theme.lighter,
        "--PageBackgroundColor": props.darkMode
          ? colorsV4.warmBlack900
          : theme.lighter,
        color: props.darkMode ? colorsV4.white : colorsV4.warmBlack,
        duration: 0.001,
      },
      0.001
    );
    tl.to(ref.current, { duration: 1 }, 0);
    tl.fromTo(
      navLinkSelector,
      {
        color: navLinkHighlightColor,
        backgroundColor: getNavLinkHighlightBackgroundColor(),
      },
      {
        color: "inherit",
        backgroundColor: withOpacity(navLinkHighlightColor, 0),
        duration: 1,
      }
    );
    clearAnimatedProps(navLinkSelector, ["color", "background-color"]);
    return () => {
      clearAnimatedProps(document.documentElement, [
        "background-color",
        "color",
        "--PageBackgroundColor",
      ]);
      document.documentElement.style.removeProperty("--PageBackgroundColor");
      tl.kill();
    };
  });

  return (
    <ReportSectionContainer
      id={props.id}
      ref={ref}
      className={cx(
        props.className,
        props.fullWidthWhenSmall && "fullWidthWhenSmall",
        props.smallerSectionHeading && "smallerSectionHeading"
      )}
      style={
        {
          "--ThemeColorPrimary": theme.primary,
          "--ThemeColorTextHighlight": theme.textHighlight,
        } as CSSProperties
      }
    >
      {props.header && (
        <Header>
          <CornerDotSet
            color={withOpacity(colorsV4.white, 0.7)}
            offset={rSize("gap")}
          />
          <HeaderInner>{props.header}</HeaderInner>
        </Header>
      )}
      <Content>{props.children}</Content>
    </ReportSectionContainer>
  );
};

export default ReportSection;
