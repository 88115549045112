import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { css } from "linaria";
import { DatoCmsLibraryTool, DatoCmsPartner } from "../../../graphql-types";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromPhoneLg,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import PartnerLogo from "./PartnerLogo";

type Props = {
  partner: DatoCmsPartner;
  tool?: DatoCmsLibraryTool;
};

const PartnerEntryContainer = styled.div`
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple100};
  border-radius: 0.5em;
  text-align: center;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
`;

const logoContainerLinkWrap = css`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  padding: 0 1em 1em 1em;
  font-size: 1.4rem;
  margin-top: -0.5em;
  h4 {
    display: none;
    font-family: inherit;
    font-weight: 600;
  }
  p {
    font-size: 1.2rem;
  }
`;
const LinkGroup = styled.div`
  margin-top: 0.5em;
  font-weight: 600;
  font-size: 1.2rem;
  > * {
    display: block;
    ${uptoPhoneLg} {
      padding: 0.5em;
    }
    ${fromPhoneLg} {
      display: inline-block;
      + * {
        &:before {
          content: "•";
          margin-left: 0.4em;
          margin-right: 0.4em;
          color: ${colors.purple800};
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
  a {
    color: ${colors.purple600};
    text-decoration: none;
    &:hover {
      color: ${colors.purple400};
    }
    &.pink {
      color: ${colors.pink600};
      &:hover {
        color: ${colors.pink400};
      }
    }
    &.green {
      color: ${colors.green600};
      &:hover {
        color: ${colors.green400};
      }
    }
  }
`;

const PartnerEntry = ({ partner, tool }: Props) => {
  const landingPageUrl =
    partner.landingPageUrl ||
    (partner.createALandingPage ? `/partners/${partner.slug}` : "");
  return (
    <PartnerEntryContainer>
      {landingPageUrl ? (
        <Link className={logoContainerLinkWrap} to={landingPageUrl}>
          <PartnerLogo partner={partner} />
        </Link>
      ) : (
        <a
          className={logoContainerLinkWrap}
          href={partner.websiteUrl ?? ""}
          {...externalLinkAttr}
        >
          <PartnerLogo partner={partner} />
        </a>
      )}
      <Content>
        <h4>{partner.name}</h4>
        <LinkGroup>
          {landingPageUrl && (
            <Link to={landingPageUrl} className="pink">
              Learn more
            </Link>
          )}
          {partner.websiteUrl && (
            <a href={partner.websiteUrl} {...externalLinkAttr}>
              {landingPageUrl ? "Website" : "Visit website"}
            </a>
          )}
          {tool && (
            <Link className="green" to={`/library/tools/${tool.slug}`}>
              View stories
            </Link>
          )}
        </LinkGroup>
      </Content>
    </PartnerEntryContainer>
  );
};

export default PartnerEntry;
