import { cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import {
  DatoCmsArticle,
  DatoCmsArticleCategory,
  DatoCmsPerson,
} from "../../../graphql-types";
import AuthorDisplay from "./AuthorDisplay";
import CategoryPill from "../basic/CategoryPill";
import Spacing from "../layout/Spacing";
import ArticleLikePageTitle from "./ArticleLikePageTitle";
import PillList from "../basic/PillList";
import { preventWidows } from "../../utils/typography.utils";

type Props = {
  className?: string;
  entry: DatoCmsArticle;
};

const ArticlePageHeaderContainer = styled.header`
  padding-top: 1px;
`;

const ArticlePageHeader = (props: Props) => {
  const authors = (props.entry.authors ?? []) as DatoCmsPerson[];
  const categories = (props.entry.categories ?? []) as DatoCmsArticleCategory[];
  const title = props.entry.title ?? "";
  return (
    <ArticlePageHeaderContainer
      className={cx("ArticlePageHeader", props.className)}
    >
      {categories.length > 0 && (
        <>
          <PillList>
            {categories.map((c, i) => (
              <Link to={`/blog/${c.slug}`} key={c.slug}>
                <CategoryPill category={c} />
              </Link>
            ))}
          </PillList>
          <Spacing size="gap" />
        </>
      )}
      <ArticleLikePageTitle className={cx(title.length > 100 ? "long" : "")}>
        {preventWidows(title)}
      </ArticleLikePageTitle>
      {authors.length > 0 && (
        <>
          <Spacing size="gap" />
          <AuthorDisplay
            authors={authors}
            showAuthorWorkTitle={props.entry.showAuthorWorkTitle}
          />
        </>
      )}
    </ArticlePageHeaderContainer>
  );
};

export default ArticlePageHeader;
