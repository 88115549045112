import { css } from "linaria";
import Button from "../../components/forms/Button";
import Pill from "../../components/basic/Pill";
import ReportSectionFrame from "../../components/reports/ReportSectionFrame";
import SOCIntroSection from "../../components/reports/soc/chapters/SOCIntroSection";
import HeroSectionWithInset, {
  ColumnedHeroSectionContent,
} from "../../components/reusableSections/HeroSectionWithInset";
import SEO from "../../components/utilities/SEO";
import Spacing from "../../components/layout/Spacing";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import { fluidFontSize } from "../../styles/helpers.styles";
import SOCKeyFindingsSection from "../../components/reports/soc/chapters/SOCKeyFindingsSection";
import {
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import SOCMethodologySection from "../../components/reports/soc/chapters/SOCMethodologySection";
import SOCChapter1 from "../../components/reports/soc/chapters/SOCChapter1";
import SOCChapter2 from "../../components/reports/soc/chapters/SOCChapter2";
import SOCChapter3 from "../../components/reports/soc/chapters/SOCChapter3";
import SOCChapter4 from "../../components/reports/soc/chapters/SOCChapter4";
import SOCChapter5 from "../../components/reports/soc/chapters/SOCChapter5";
import SOCSummaryActionableTakeaways from "../../components/reports/soc/chapters/SOCSummaryActionableTakeaways";
import SOCConclusion from "../../components/reports/soc/chapters/SOCConclusion";
import heroImage from "../../images/illustration-building-shielded-by-tines-535-370.png";
import heroImage2x from "../../images/illustration-building-shielded-by-tines-535-370@2x.png";
import { makeSrcSet2x } from "../../utils/srcset.utils";
import Figure from "../../components/utilities/Figure";
import { whenDesktopNav } from "../../components/site/SiteNav";
import FromTabletDiv from "../../components/mediaQueries/FromTablet";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import SOCNav from "../../components/reports/soc/SOCNav";
import { Serif } from "../../components/typography/Serif";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  KnownDocumentName,
  recordDocumentEvent,
} from "../../utils/documents.utils";

const PageSOCReport: PageComponent = props => {
  const description =
    "Discover insights and recommendations from our survey of almost 500 security analysts – and the takeaways for leadership.";
  const scrollToIntro = () => {
    scrollToHash({
      useHash: "#introduction",
      doNotPushState: true,
    });
  };
  useOnMount(() => {
    recordDocumentEvent({
      type: "view",
      identifier: KnownDocumentName.soc2022,
    });
  });
  const handleDownloadButtonClick = () => {
    recordDocumentEvent({
      type: "download",
      identifier: KnownDocumentName.soc2022,
    });
  };

  return (
    <Page {...props}>
      <SEO
        title="Report: Voice of the SOC Analyst"
        description={description}
        image="/images/og/tines-report-voice-of-the-soc-analyst.png"
      />
      <style
        children=":root {
          transition: background-color 1s;
        }"
      />
      <HeroSectionWithInset>
        <ColumnedHeroSectionContent gap="0" alignItems="center">
          <div>
            <Pill appearance="filled-white" color="purple" children="2022" />
            <Spacing size="md" />
            <h2
              className={css`
                line-height: 1;
                ${fluidFontSize(48, 72)};
              `}
            >
              <Serif>Voice of the</Serif>
              <br />
              SOC Analyst
            </h2>
            <Spacing size="1em" />
            <p>
              Discover insights and recommendations from our survey of almost
              500 security analysts – and the takeaways for leadership.
            </p>
            <Spacing size="2em" />
            <div
              className={css`
                display: inline-flex;
                > * {
                  + * {
                    margin-left: 0.5em;
                    ${fromTablet} {
                      margin-left: 1em;
                    }
                  }
                }
              `}
            >
              <Button appearance="filled-white" onClick={scrollToIntro}>
                Start reading
              </Button>
              <Button
                appearance="outlined"
                color="white"
                download
                onClick={handleDownloadButtonClick}
                href={`/reports/${encodeURIComponent(
                  "Tines Report - Voice of the SOC Analyst.pdf"
                )}`}
              >
                Download PDF
              </Button>
            </div>
          </div>
          <Figure>
            <div
              className={css`
                position: relative;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &:before {
                  content: "";
                  display: block;
                  padding-top: 69.15%;
                }
                ${whenDesktopNav} {
                  width: 375px;
                }
                ${fromDesktopMd} {
                  width: 535px;
                }
              `}
            >
              <img
                src={heroImage}
                srcSet={makeSrcSet2x(heroImage, heroImage2x)}
                className={css`
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translate(-50%, 0);
                  ${whenDesktopNav} {
                    left: 0;
                    transform: translate(6%, 5%);
                  }
                `}
              />
            </div>
          </Figure>
        </ColumnedHeroSectionContent>
        <Spacing size="sectionPadding" />
        <Spacing size="lg" />
      </HeroSectionWithInset>

      <FromTabletDiv>
        <Spacing size="lg" />
      </FromTabletDiv>

      <ReportSectionFrame nav={<SOCNav />}>
        <SOCIntroSection />
        <SOCKeyFindingsSection />
        <SOCMethodologySection />

        <SOCChapter1 />
        <SOCChapter2 />
        <SOCChapter3 />
        <SOCChapter4 />
        <SOCChapter5 />

        <SOCSummaryActionableTakeaways />

        <SOCConclusion />
      </ReportSectionFrame>

      <Spacing size="sectionPadding" />
    </Page>
  );
};

export default PageSOCReport;
