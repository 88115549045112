import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { AIPageHeading2 } from "./_AIPageComponents";
import Spacing from "../layout/Spacing";
import { LogoUdemy } from "../thirdPartyLogos/LogoUdemy";
import { LogoElastic36h } from "../thirdPartyLogos/LogoElastic36h";
import { LogoTrace3H18 } from "../thirdPartyLogos/LogoTrace3H18";
import { LogoMyFitnessPal } from "../thirdPartyLogos/LogoMyFitnessPal";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { rSize, rSizeNoCalc } from "../../styles/responsiveSizes.styles";
import { w } from "../../utils/string.utils";
import { serif } from "../../styles/fonts.styles";
import { colors } from "../../styles/colors.styles";
import { LogoNewsCorp } from "../thirdPartyLogos/LogoNewsCorp";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPaths";

const Header = styled.header`
  text-align: center;
  ${fromTabletLg} {
    max-width: ${widthInGrid(6)};
  }
  margin-left: auto;
  margin-right: auto;
  p {
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const LogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  ${uptoDesktop} {
    > * {
      margin: 1em;
    }
  }
  ${fromTabletLg} {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: -8rem;
    margin-right: -8rem;
  }
  ${fromDesktopMd} {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
  }
`;

const QuoteGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktop} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: 2em 2em auto auto auto auto 4em 1em;
    grid-template-areas: ${`
      "${w(".", 3)} ${w(".", 3)} ${w("q3", 3)} ${w(".", 3)}"
      "${w(".", 3)} ${w("q2", 3)} ${w("q3", 3)} ${w(".", 3)}"
      "${w("q1", 3)} ${w("q2", 3)} ${w("q3", 3)} ${w("q4", 3)}"
      "${w("q1", 3)} ${w("q2", 3)} ${w("q5", 3)} ${w("q4", 3)}"
      "${w("q1", 3)} ${w("q6", 3)} ${w("q5", 3)} ${w("q4", 3)}"
      "${w("q1", 3)} ${w("q6", 3)} ${w("q5", 3)} ${w("q4", 3)}"
      "${w(".", 3)} ${w("q6", 3)} ${w("q5", 3)} ${w(".", 3)}"
      "${w(".", 3)} ${w("f", 3)} ${w("q5", 3)} ${w(".", 3)}"
    `};
  }
`;

const QuoteCard = styled.div`
  padding: 1em;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  text-decoration: none;
  border-radius: ${rSize("radius")};
  border-width: 1px;
  border-style: solid;
  > p {
    font-family: ${serif};
    line-height: 1.2;
    ${fromTablet} {
      font-size: 1.6rem;
    }
    ${fromDesktopMl} {
      letter-spacing: -0.01em;
      font-size: 1.8rem;
    }
    svg {
      vertical-align: baseline;
      transform: translateY(0.2em);
      margin-right: 0.2em;
    }
  }
  footer {
    p {
      font-size: 1.4rem;
    }
  }
  &:nth-child(1) {
    ${fromDesktopMd} {
      grid-area: q1;
    }
    background-color: ${colors.orange100};
    color: ${colors.orange700};
    border-color: ${colors.orange400};
  }
  &:nth-child(2) {
    ${fromDesktopMd} {
      grid-area: q2;
    }
    background-color: ${colors.pink50};
    color: ${colors.pink700};
    border-color: ${colors.pink300};
  }
  &:nth-child(3) {
    ${fromDesktopMd} {
      grid-area: q3;
    }
    background-color: ${colors.orange200};
    color: ${colors.orange700};
    border-color: ${colors.orange400};
  }
  &:nth-child(4) {
    ${fromDesktopMd} {
      grid-area: q4;
    }
    background-color: ${colors.yellow100};
    color: ${colors.yellow700};
    border-color: ${colors.yellow400};
  }
  &:nth-child(5) {
    ${fromDesktopMd} {
      grid-area: q5;
    }
    background-color: ${colors.pink100};
    color: ${colors.pink700};
    border-color: ${colors.pink300};
  }
  &:nth-child(6) {
    ${fromDesktopMd} {
      grid-area: q6;
    }
    background-color: ${colors.yellow200};
    color: ${colors.yellow700};
    border-color: ${colors.yellow400};
  }
`;

const ConnectorPathWaypointsPositioner = styled.div`
  position: absolute;
  top: ${`calc(${rSizeNoCalc("sectionMargin", -1)} + ${rSizeNoCalc("md", 2)})`};
  bottom: 100%;
  left: 0;
  right: 0;
`;

export const AIPageQuoteGridSection = () => {
  return (
    <PageSection relative>
      <ConnectorPathWaypointsPositioner>
        <ConnectorPathWaypoint
          id="ai-page-quote-grid-section"
          index={0}
          color={colors.yellow200}
          top={0}
          left="50%"
        />
        <ConnectorPathWaypoint
          id="ai-page-quote-grid-section"
          index={1}
          color={colors.yellow200}
          bottom="-3em"
          left="50%"
        />
      </ConnectorPathWaypointsPositioner>
      <Header>
        <LightbulbWithSparklesIcon />
        <Spacing size="md" />
        <AIPageHeading2>Now available for free</AIPageHeading2>
        <Spacing size="md" />
        <p>
          AI in Tines is included on community and paid editions of Tines. Hear
          what our customers are already saying:
        </p>
        <Spacing size="md" />
        <LogoRow>
          <LogoUdemy />
          <LogoElastic36h />
          <LogoTrace3H18 />
          <LogoNewsCorp />
          <LogoMyFitnessPal />
        </LogoRow>
      </Header>
      <Spacing size="xl" />
      <QuoteGrid>
        <QuoteCard>
          <p>
            <QuoteMarkIcon /> To see [AI] being implemented in such a well-oiled
            way is really nice. It’s been a common theme when I’ve worked with
            Tines - they deliver on a promise and implement it well. I’m a big
            advocate of the tool and have been for a long time.
          </p>
          <footer>
            <p>
              {/* cspell:disable */}
              <strong>Kyle McGaley</strong>
              {/* cspell:enable */}
            </p>
            <p>Udemy</p>
          </footer>
        </QuoteCard>
        <QuoteCard>
          <p>
            <QuoteMarkIcon /> Tines’ intentional approach to its new AI
            features...align well with the market need to integrate AI within
            automated workflows at scale without the fear of inadvertently
            creating infrastructure vulnerabilities and compliance issues.
          </p>
          <footer>
            <p>
              {/* cspell:disable */}
              <strong>Chris Kissel</strong>
              {/* cspell:enable */}
            </p>
            <p>IDC Research</p>
          </footer>
        </QuoteCard>
        <QuoteCard>
          <p>
            <QuoteMarkIcon /> It’s unlocking new use cases for us. Automatic
            mode, especially, promises to help the team work faster and lower
            the barrier to entry for non-developers on the team.
          </p>
          <footer>
            <p>
              {/* cspell:disable */}
              <strong>Allen Cox</strong>
              {/* cspell:enable */}
            </p>
            <p>MyFitnessPal</p>
          </footer>
        </QuoteCard>
        <QuoteCard>
          <p>
            <QuoteMarkIcon /> Automatic mode will make a huge difference to the
            everyday user like me, the ability to ask Tines to build an Event
            Transform for me by asking it what to do in natural language will
            save me so much time.
          </p>
          <footer>
            <p>
              {/* cspell:disable */}
              <strong>Rob Kernutt</strong>
              {/* cspell:enable */}
            </p>
            <p>Elastic</p>
          </footer>
        </QuoteCard>

        <QuoteCard>
          <p>
            <QuoteMarkIcon /> I believe that we as a company at Udemy are
            definitely looking at leveraging AI a lot more, in terms of our
            day-to-day work and in terms of what we bring to our customers.
            These kind of features are very exciting for the team and very
            exciting for Udemy as a whole.
          </p>
          <footer>
            <p>
              {/* cspell:disable */}
              <strong>Kyle McGaley</strong>
              {/* cspell:enable */}
            </p>
            <p>Udemy</p>
          </footer>
        </QuoteCard>

        <QuoteCard>
          <p>
            <QuoteMarkIcon /> It’s been fantastic. As far as barrier to entry
            goes, there really isn’t any. Getting started with it is so easy.
          </p>
          <footer>
            <p>
              {/* cspell:disable */}
              <strong>Tyler Talaga</strong>
              {/* cspell:enable */}
            </p>
            <p>MyFitnessPal</p>
          </footer>
        </QuoteCard>
      </QuoteGrid>
    </PageSection>
  );
};

const QuoteMarkIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C2.23858 0 0 2.23858 0 5V14C0 16.7614 2.23858 19 5 19H14C16.7614 19 19 16.7614 19 14V5C19 2.23858 16.7614 0 14 0H5ZM9.08997 8.36134H9.34131V7H8.92241C7.56518 7 6.74414 7.85714 6.74414 9.33613V11H8.36946V9.45378C8.36946 8.73109 8.63756 8.36134 9.08997 8.36134ZM12.0055 8.36134H12.2568V7H11.8379C10.464 7 9.64291 7.85714 9.64291 9.33613V11H11.2682V9.45378C11.2682 8.73109 11.5363 8.36134 12.0055 8.36134Z"
    />
  </svg>
);

const LightbulbWithSparklesIcon = () => (
  <svg width="119" height="110" viewBox="0 0 119 110" fill="none">
    <path
      d="M60.1213 78.3472C58.2031 78.3472 51.9453 78.1018 51.9453 74.946C51.9453 74.1828 52.4869 73.564 53.1539 73.564C53.7 73.564 54.1616 73.9771 54.3113 74.5447C54.8505 74.9457 56.8925 75.5832 60.1213 75.5832C63.3501 75.5832 65.3918 74.9457 65.9308 74.5447C66.0817 73.9771 66.5425 73.564 67.0882 73.564C67.7561 73.564 68.2972 74.1826 68.2972 74.946C68.2972 78.1018 62.039 78.3472 60.1213 78.3472Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M60.1213 75.6265C58.2031 75.6265 51.9453 75.3813 51.9453 72.2255C51.9453 71.4623 52.4869 70.8433 53.1539 70.8433C53.7 70.8433 54.1616 71.2566 54.3113 71.8239C54.8505 72.2252 56.8925 72.8627 60.1213 72.8627C63.3501 72.8627 65.3918 72.2252 65.9308 71.8239C66.0817 71.2566 66.5425 70.8433 67.0882 70.8433C67.7561 70.8433 68.2972 71.4621 68.2972 72.2255C68.2972 75.3813 62.039 75.6265 60.1213 75.6265Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M60.1213 72.8091C58.2031 72.8091 51.9453 72.5639 51.9453 69.4079C51.9453 68.6447 52.4869 68.0259 53.1539 68.0259C53.7 68.0259 54.1616 68.439 54.3113 69.0068C54.8505 69.4076 56.8925 70.0451 60.1213 70.0451C63.3501 70.0451 65.3918 69.4076 65.9308 69.0068C66.0817 68.439 66.5425 68.0259 67.0882 68.0259C67.7561 68.0259 68.2972 68.6445 68.2972 69.4079C68.2972 72.5639 62.039 72.8091 60.1213 72.8091Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M60.045 70.3124C63.5281 70.3124 66.3517 69.5509 66.3517 68.6115C66.3517 67.6721 63.5281 66.9106 60.045 66.9106C56.5619 66.9106 53.7383 67.6721 53.7383 68.6115C53.7383 69.5509 56.5619 70.3124 60.045 70.3124Z"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.0483 12.7603C46.4799 12.7603 35.4805 23.7595 35.4805 37.3281C35.4805 44.7961 38.8134 51.4852 44.0721 55.9912L44.0726 55.9914C51.7693 62.9635 53.7416 68.6119 53.7416 68.6119H60.0483H66.355C66.355 68.6119 68.3277 62.9635 76.0244 55.9914V55.9912C81.2836 51.4855 84.6166 44.7964 84.6166 37.3281C84.6166 23.7595 73.6171 12.7603 60.0483 12.7603Z"
      fill="#FDBD74"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.045 70.3124C63.5281 70.3124 66.3517 69.5509 66.3517 68.6115C66.3517 67.6721 63.5281 66.9106 60.045 66.9106C56.5619 66.9106 53.7383 67.6721 53.7383 68.6115C53.7383 69.5509 56.5619 70.3124 60.045 70.3124Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.2933 69.3097C63.3731 69.3097 65.0592 68.7908 65.0592 68.1507C65.0592 67.5106 63.3731 66.9917 61.2933 66.9917C59.2134 66.9917 57.5273 67.5106 57.5273 68.1507C57.5273 68.7908 59.2134 69.3097 61.2933 69.3097Z"
      fill="#32313B"
    />
    <path
      d="M61.357 62.5977C61.7878 62.2286 62.0614 61.6815 62.0614 61.0698C62.0614 59.9587 61.161 59.0581 60.0494 59.0581C58.9386 59.0581 58.0375 59.9587 58.0375 61.0698C58.0375 61.6815 58.312 62.2283 58.7423 62.5977C57.922 63.0562 57.3672 63.9323 57.3672 64.9392V66.7962C57.3672 68.2776 58.568 68.7868 60.0492 68.7868C61.5309 68.7868 62.7317 68.2778 62.7317 66.7962V64.9392C62.7322 63.9325 62.1773 63.0564 61.357 62.5977Z"
      fill="#FBB598"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.7759 31.8213C63.7759 33.6386 54.7461 33.6386 54.7461 35.4559C54.7461 37.2725 63.7759 37.2725 63.7759 39.0893C63.7759 40.9066 54.7461 40.9066 54.7461 42.7239C54.7461 44.5412 63.7759 44.5412 63.7759 46.3588C63.7759 48.1763 54.7461 48.1763 54.7461 49.9941C54.7461 51.8118 63.7759 51.8118 63.7759 53.6296C63.7759 54.4554 61.8549 55.7393 59.911 55.9226"
      stroke="#FFE5CA"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.433 83.3487C66.433 82.3633 63.5755 81.564 60.0503 81.564C56.5252 81.564 53.668 82.3633 53.668 83.3487C53.668 84.1005 54.8697 89.0975 54.8697 89.8491C54.8697 90.8349 56.5255 91.6338 60.0505 91.6338C63.576 91.6338 65.2317 90.8349 65.2317 89.8491C65.2315 89.0975 66.433 84.1005 66.433 83.3487Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M60.0505 85.1334C63.5754 85.1334 66.433 84.3342 66.433 83.3484C66.433 82.3626 63.5754 81.5635 60.0505 81.5635C56.5255 81.5635 53.668 82.3626 53.668 83.3484C53.668 84.3342 56.5255 85.1334 60.0505 85.1334Z"
      fill="#B36847"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M53.5741 83.1245L52.1172 75.7422"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.4766 83.3375L68.1342 75.6963"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.2162 84.5657C56.2162 84.5657 57.417 81.8044 60.5988 81.8044C60.5988 81.8044 56.2162 81.5639 53.935 83.1249C53.935 83.1249 53.0946 83.9053 56.2162 84.5657Z"
      fill="#32313B"
    />
    <path
      d="M60.0503 110C70.9641 110 79.8115 108.892 79.8115 107.525C79.8115 106.159 70.9641 105.051 60.0503 105.051C49.1365 105.051 40.2891 106.159 40.2891 107.525C40.2891 108.892 49.1365 110 60.0503 110Z"
      fill="#32313B"
    />
    <path
      d="M16.5829 5.73658C16.5776 6.42602 16.1586 7.37256 15.6512 7.83894L13.5451 9.77689C13.0374 10.2438 13.1866 10.6307 13.8763 10.6354L16.7383 10.6566C17.4276 10.6616 18.3737 11.0813 18.8407 11.5885L20.7783 13.6942C21.2454 14.2017 21.6318 14.0526 21.6368 13.363L21.6579 10.5016C21.6632 9.81211 22.0821 8.86586 22.5896 8.39899L24.6956 6.46106C25.2034 5.99411 25.0541 5.60729 24.3644 5.60257L21.5025 5.58138C20.8129 5.57686 19.8671 5.15686 19.4001 4.64963L17.4625 2.54371C16.9954 2.03628 16.609 2.18536 16.604 2.87493L16.5829 5.73658Z"
      fill="#F5A847"
      stroke="#32313B"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M111.371 36.2106C110.916 36.5181 110.106 36.6701 109.571 36.5475L107.35 36.0396C106.814 35.9171 106.628 36.1895 106.936 36.6442L108.212 38.5317C108.52 38.9863 108.671 39.7964 108.549 40.3316L108.041 42.5527C107.919 43.0881 108.191 43.2744 108.646 42.9666L110.533 41.6899C110.988 41.3824 111.798 41.2305 112.333 41.3529L114.554 41.8608C115.09 41.9833 115.276 41.7109 114.968 41.2562L113.691 39.3687C113.383 38.9142 113.232 38.1042 113.355 37.569L113.863 35.3477C113.985 34.8123 113.713 34.626 113.258 34.9338L111.371 36.2106Z"
      fill="#FFAF7E"
      stroke="#32313B"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M79.058 47.634C78.8388 48.1373 78.2363 48.6997 77.7189 48.8828L75.5711 49.6443C75.0534 49.8277 75.0419 50.1576 75.5455 50.3765L77.635 51.286C78.1383 51.5051 78.7001 52.1079 78.8837 52.6253L79.645 54.7728C79.8286 55.2904 80.1582 55.3019 80.3772 54.7984L81.2865 52.7092C81.5058 52.2059 82.1081 51.6437 82.6256 51.4603L84.7734 50.6988C85.2911 50.5154 85.3027 50.1855 84.799 49.9666L82.7096 49.0571C82.2059 48.8384 81.6444 48.2353 81.4609 47.7179L80.6995 45.5703C80.516 45.0527 80.1864 45.0412 79.9673 45.5447L79.058 47.634Z"
      fill="#FFD29F"
      stroke="#32313B"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
