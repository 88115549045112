/* eslint-disable @typescript-eslint/no-unsafe-argument */
import dayjs from "dayjs";
import { graphql } from "gatsby";
import { BootcampEvergreenPageQuery } from "../../graphql-types";
import ClientRedirectPageTemplate from "../templates/ClientRedirectPage.template";
import { PageComponent } from "../types/gatsby.types";
import ogImage from "../../static/images/og/tines-bootcamp.png";
import { colors } from "../styles/colors.styles";

/**
 * 🏕
 */
const PageBootcampRedirectHandler: PageComponent<{
  data: BootcampEvergreenPageQuery;
}> = props => {
  let redirectTo = "";
  const bootcamps = props.data.webinars.nodes;
  const firstAvailableBootcamp =
    bootcamps
      .sort((a, b) => dayjs(a.timeStart).diff(b.timeStart))
      .find(bootcamp => dayjs(bootcamp.timeStart).isAfter(dayjs())) ??
    bootcamps[0];

  if (firstAvailableBootcamp) {
    redirectTo = `/bootcamp/${firstAvailableBootcamp.slug}`;
  } else {
    redirectTo = `/bootcamp-user-guide`;
  }

  return (
    <ClientRedirectPageTemplate
      {...props}
      browserThemeColor={colors.green50}
      backgroundColor={colors.green50}
      navThemeColor="green"
      textColor={colors.green800}
      pageContext={{
        title: "Tines Bootcamp",
        description:
          "In our effort to enable everyone to get the most out of their automation goals, we would like to invite you to join us in our Tines Bootcamp!",
        ogImage,
        to: redirectTo,
        delay: 500,
        message: firstAvailableBootcamp
          ? "Taking you to the latest Bootcamp..."
          : "Taking you to the Tines Bootcamp User Guide...",
      }}
    />
  );
};

export const bootcampEvergreenPageQuery = graphql`
  query BootcampEvergreenPage {
    webinars: allDatoCmsWebinar(
      filter: { category: { slug: { eq: "bootcamp" } } }
      sort: { timeStart: DESC }
    ) {
      nodes {
        id: originalId
        title
        slug
        timeStart
      }
    }
  }
`;

export default PageBootcampRedirectHandler;
