import ComboFontHeading from "../../../typography/ComboFontHeading";
import Pill from "../../../basic/Pill";
import ReportCard from "../../ReportCard";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import DonutChart from "../../charts/DonutChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import Spacing from "../../../layout/Spacing";
import ReportChartContainer from "../../ReportChartContainer";

const MHChapter5 = () => {
  return (
    <ReportSection
      id="employer-resources"
      themeName="green"
      header={
        <>
          <Pill color="green">Part Five</Pill>
          <ComboFontHeading
            snippets={[
              [
                <>
                  Employer
                  <br />
                </>,
              ],
              [<>resources</>, "serif"],
            ]}
          />
          <p>
            Considering the impact of workplace duties and dynamics on mental
            health, and the importance of helping employees be healthy and safe,
            more workplaces are implementing mental health and wellness
            resources, often in the form of Employee Assistance Programs (EAPs),
            by providing subscriptions to mental health apps, or by running
            on-site programming. But are respondents receiving the resources
            they need in their workplace?
          </p>
        </>
      }
    >
      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>57% say their workplace provides mental health support</h3>
        <p>
          As we’ve seen in the previous sections, respondents’ daily work
          duties, situations, and stresses are impacting their mental health,
          and their mental health is impacting their work. For 56.6%, their
          workplace does provide mental health support and resources for their
          staff, while 43.4% say their workplace does not.
        </p>
        <p>
          Additionally, respondents in the United States are more likely to have
          an employer provide mental health support (64.2%) than respondents
          in Europe (49.1%).
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>Does your employer provide mental health support?</h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Yes", 581],
              ["No", 446],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.green, colorsV4.warmBlack]}
            height={275}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>54% say their workplace prioritizes mental health</h3>
        <p>
          Do workplaces care about and prioritize mental health in their
          operations? Only half (54.1%) say they do, while 45.9% say their
          workplace does not prioritize mental health.
        </p>
        <p>
          Also, respondents in the United States are more likely to say their
          employer prioritizes mental health (58.3%) than respondents
          in Europe (50.1%).
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            Do you believe your employer is prioritizing your mental health?
          </h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Yes", 556],
              ["No", 471],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.green, colorsV4.warmBlack]}
            height={275}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportSummaryCard>
        <blockquote>
          <p>
            The importance of being able to handle workplace stress, unexpected
            situations, burnout, and other factors seems to be key for managing
            mental health and creating a strong work environment.
          </p>
        </blockquote>
        <p>
          As employers realize this as well, they’re beginning to provide more
          tools and resources to their staff in order to help them better
          approach situations like the ones outlined above. So far, 57% say
          their workplace provides mental health support to their employees.
          However, only 54% say their workplace prioritizes mental health. While
          it’s a number that’s likely improved over the past few years, there’s
          still progress to be made.
        </p>
        <Spacing size="1em" />
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default MHChapter5;
