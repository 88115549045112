import { css } from "linaria";
import ContentCardBase from "./_ContentCardBase";
import {
  PrimaryColorName,
  brandColorThemeVar,
} from "../../styles/colors.styles";
import { styled } from "@linaria/react";
import { DatoCmsContentCard, DatoCmsPodcast } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import {
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";

const style = css`
  &:before {
    content: "";
    display: block;
    position: absolute;
    aspect-ratio: 214 / 64;
    ${uptoTabletMd} {
      display: none;
    }
    ${fromTabletMd} {
      left: -50px;
      top: 225px;
      width: 214px;
      height: 64px;
    }
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='214' height='64' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'%3E%3Cpath d='M212 50V15M204 53V11M196 41V24M188 45V19M180 59V6M172 53V11M164 45V19M156 48V17M148 38V26M140 45V19M132 56V9M124 53V11M116 45V19M108 38V26M100 47V18M92 43V21M84 48V17M76 53V11M68 43V21M60 45V19M52 41V24M44 50V15M36 56V9M28 62V2M20 50V15M12 53V11M4 45V19'/%3E%3C/svg%3E");
    opacity: 0.3;
    mix-blend-mode: overlay;
  }
  figure {
    position: absolute;
    bottom: 0;
    right: 0;
    min-height: 50%;
    background-color: ${brandColorThemeVar(500)};
    justify-content: flex-start;
    border-top-left-radius: 6px;
    ${uptoTabletMd} {
      left: 12px;
    }
    ${fromTabletMd} {
      width: 210px;
      aspect-ratio: 210/200;
      max-width: calc(100% - 8em);
      border-top-left-radius: 8px;
    }
    > div {
      max-width: 50%;
    }
    p {
      line-height: 1;
      font-weight: 600;
      opacity: 1;
      &:first-child {
        font-size: 1.4rem;
        ${fromTabletMd} {
          font-size: 1.6rem;
        }
      }
      &:last-child {
        font-size: 1rem;
        margin-top: 0.5em;
      }
    }
  }
  figure {
    padding: 0.5em;
    ${fromTabletMd} {
      padding: 0.75em;
    }
  }
  &.large {
    figure {
      padding: 0.75em;
      ${fromTabletMd} {
        padding: 1em;
      }
    }
  }
`;

const GuestSpeakerImageTransparentWrap = styled.span`
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 95%;
  max-height: 95%;
  transform: translate(30%);
  ${fromTabletMd} {
    transform: translate(20%);
  }
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }
`;

const ContentCardPodcast = ({
  podcast,
  overrides,
  large,
}: {
  podcast: DatoCmsPodcast;
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  return (
    <ContentCardBase
      className={style}
      large={large}
      color={(podcast.color ?? "purple") as PrimaryColorName}
      link="/podcast"
      variant="800"
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Podcast"}</strong>
          </p>
          <h4>{overrides?.heading || "The Future of Security Automation"}</h4>
          <ReactMarkdown>
            {overrides?.description ||
              "Interviews and insights from leading voices in security."}
          </ReactMarkdown>
        </div>
        <p>
          <strong>Listen now</strong>
        </p>
      </header>
      <figure>
        <div>
          <p>
            {podcast.guestSpeaker?.name} {podcast.guestSpeaker?.surname}
          </p>
          <p>
            {[
              podcast.guestSpeaker?.title,
              podcast.guestSpeaker?.organization?.name,
            ]
              .filter(i => i)
              .join(",\n")}
          </p>
        </div>
        {podcast.guestSpeakerImageTransparent?.url && (
          <GuestSpeakerImageTransparentWrap>
            <img
              src={podcast.guestSpeakerImageTransparent.url}
              style={{
                aspectRatio: `${podcast.guestSpeakerImageTransparent.width}/${podcast.guestSpeakerImageTransparent.height}`,
              }}
            />
          </GuestSpeakerImageTransparentWrap>
        )}
      </figure>
    </ContentCardBase>
  );
};

export default ContentCardPodcast;
