import PageSection from "../components/reusableSections/PageSection";
import StandardPageHeader from "../components/reusableSections/StandardPageHeader";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import { colorsV4 } from "../styles/colorsV4.styles";
import Page from "./Page.template";
import { PageComponent } from "../types/gatsby.types";
import { graphql } from "gatsby";
import { DatoCmsPage, GeneralPageBySlugQuery } from "../../graphql-types";
import StandardArticleStructuredTextWithCustomBlocks from "../components/articles/StructuredTextWithCustomBlocks";
import { ArticleLikePageContent } from "../components/articles/ArticleLikePageContent";
import ImageFrame from "../components/basic/ImageFrame";
import { css } from "@linaria/core";
import { rSize } from "../styles/responsiveSizes.styles";
import {
  WiderArticlePageBody,
  WiderPageBodyInner,
  WiderPageBodyWrap,
} from "../components/hub/HubPageComponents";
import { cx } from "linaria";
import {
  fromDesktop,
  fromTablet,
  fromTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import { widthInGrid } from "../constants/globalGrid.constants";
import { useOnMount } from "../utils/lifeCycle.utils";
import { getCookie } from "../utils/cookies.utils";
import { readMarketingAttribution } from "../utils/marketingAttribution.utils";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { colors } from "../styles/colors.styles";

const generalPageStyle = css`
  &.addBottomBordersToH2s {
    h2 {
      border-bottom: 2px solid ${colorsV4.canvas700};
      padding-bottom: 0.5em;
      ${fromTablet} {
        font-size: 2.8rem;
      }
      ${fromDesktop} {
        font-size: 3.2rem;
      }
    }
    h3 {
      padding-top: 0.75em;
      font-size: 2.2rem;
      ${fromDesktop} {
        font-size: 2.6rem;
      }
    }
    h4 {
      font-size: 2rem;
    }
  }
  ${WiderPageBodyWrap} {
    padding-left: 0;
    padding-right: 0;
    &.hasRightColumn {
      ${fromTabletMd} {
        grid-template-columns: minmax(0, 1fr) 20rem;
      }
      ${fromDesktop} {
        grid-template-columns: minmax(0, 1fr) ${widthInGrid(3)};
      }
    }
  }
  ${WiderPageBodyInner} {
    margin-left: 0;
    ${fromTablet} {
      padding-right: 1em;
    }
  }
  aside {
    background-color: ${colors.light100};
    padding: 1em 1.5em;
    border-radius: ${rSize("radius")};
    max-height: calc(100vh - var(--pageTopFixedElementsHeight, 64px));
  }
`;

const generalPageCoverImageStyle = css`
  margin-bottom: ${rSize("lg")};
  img {
    border-radius: ${rSize("radius")};
  }
`;

const GeneralPageTemplate: PageComponent<{
  data: GeneralPageBySlugQuery;
}> = props => {
  const page = props.data.page!;
  const hasToc = !!page.generateToc;
  useOnMount(() => {
    if (page.reportPageVisitToWebhookUrl) {
      fetch(page.reportPageVisitToWebhookUrl, {
        method: "POST",
        body: JSON.stringify({
          email: getCookie("email_address") ?? "",
          pageUrl: window.location.href,
          marketingAttributions: readMarketingAttribution(),
        }),
      });
    }
  });
  const content = (
    <>
      {page.coverImage && (
        <ImageFrame
          className={generalPageCoverImageStyle}
          image={page.coverImage}
        />
      )}
      <StandardArticleStructuredTextWithCustomBlocks
        value={page.content as DatoCmsPage["content"]}
        centered
        linkedHeadingMaxLevel={4}
      />
    </>
  );
  return (
    <Page
      {...props}
      className={cx(
        generalPageStyle,
        page.addBottomBordersToH2S && "addBottomBordersToH2s"
      )}
    >
      <SEO
        title={page.title ?? ""}
        image={page.coverImage?.url}
        noindex={page.noindex}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="genericPage"
        recordId={page.id}
      />
      <StandardPageHeader
        pageTitle={page.title}
        centered={!hasToc}
        largerPadding
      />
      <Spacing size="sectionPadding" />
      <PageSection>
        {hasToc ? (
          <WiderArticlePageBody
            largerText
            includeLevelsInToc={["h2", "h3", "h4"]}
          >
            {content}
          </WiderArticlePageBody>
        ) : (
          <ArticleLikePageContent>{content}</ArticleLikePageContent>
        )}
      </PageSection>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const generalPageBySlugQuery = graphql`
  query GeneralPageBySlug($slug: String) {
    page: datoCmsPage(slug: { eq: $slug }) {
      id: originalId
      title
      slug
      excerpt
      generateToc
      addBottomBordersToH2S
      reportPageVisitToWebhookUrl
      noindex
      coverImage {
        url
        width
        height
      }
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              width
              height
              url
            }
            caption
            link
            altTag
            showInFullPageWidth
            applyBorder
          }
          ... on DatoCmsPullQuote {
            id: originalId
            content
            source
            color
          }
          ... on DatoCmsVideoBlock {
            id: originalId
            title
            url
            videoFile {
              video {
                mp4Url
                streamingUrl
                thumbnailUrl
              }
              width
              height
              basename
              alt
              url
            }
            autoplay
            loop
          }
          ... on DatoCmsIframeEmbed {
            id: originalId
            src
            title
            aspectRatio
            aspectRatioMobile
            height
            heightMobile
          }
          ... on DatoCmsCustomHtmlBlock {
            id: originalId
            htmlContent
          }
          ... on DatoCmsChecklistBlock {
            id: originalId
            customIcon
            color
            listContent {
              value
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            heading
            spotIllustration {
              url
            }
            themeColor
            buttonLabel
            buttonLink
          }
          ... on DatoCmsStoryEmbed {
            id: originalId
            story {
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            storyUrl
            pillText
            fullWidth
            overrideColor
            utmCampaign
            utmSource
            utmMedium
          }
          ... on DatoCmsInArticleStoryGrid {
            id: originalId
            preheading
            heading
            description
            linkTextLabel
            linkUrl
            image {
              url
              width
              height
            }
            maxNumberOfStories
            stories {
              id: originalId
              name
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            collection {
              id: originalId
              name
              slug
            }
            tool {
              id: originalId
              name
              slug
            }
          }
          ... on DatoCmsHtmlWysiwygBlock {
            id: originalId
            content
          }
          ... on DatoCmsCalloutBanner {
            id: originalId
            content
            calloutType
          }
          ... on DatoCmsDataCardGrid {
            id: originalId
            cards {
              data
              label
              color
            }
          }
          ... on DatoCmsStructuredTextBlock {
            id: originalId
            structuredText {
              value
              blocks
            }
            backdropColor
            appearance
          }
        }
      }
    }
  }
`;

export default GeneralPageTemplate;
