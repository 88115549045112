import ComboFontHeading from "../../../typography/ComboFontHeading";
import Pill from "../../../basic/Pill";
import SpotIllustration from "../../../illustrations/SpotIllustration";
import ReportCard, { ReportCardInnerAligner } from "../../ReportCard";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import DonutChart from "../../charts/DonutChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import { styled } from "@linaria/react";
import { fromTablet } from "../../../../styles/breakpointsAndMediaQueries.styles";
import LeaderboardChart, {
  LeaderboardChartItem,
} from "../../charts/LeaderboardChart";
import Spacing from "../../../layout/Spacing";
import BarChart from "../../charts/BarChart";
import ReportChartContainer from "../../ReportChartContainer";

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const SOCChapter1 = () => {
  return (
    <ReportSection
      id="job-satisfaction-and-workloads"
      themeName="purple"
      header={
        <>
          <div>
            <SpotIllustration name="balancingBlocks" />
          </div>
          <Pill>Part One</Pill>
          <ComboFontHeading
            snippets={[
              [
                <>
                  Job satisfaction
                  <br />
                </>,
              ],
              [<>and workloads</>, "serif"],
            ]}
          />
          <p>
            Security analysts play a vital role in ensuring that their
            organizations stay safe and secure. But barriers to their work, like
            a lack of staff, overwork, and tedious tasks are causing frustration
            and burnout. In order to understand better how security leaders can
            solve their team’s pain points, we first have to start with a
            baseline of how analysts are faring today.
          </p>
        </>
      }
    >
      <Grid>
        <ReportCard paddingOnDesktop={rSize("gap", 2)} centered>
          <ReportCardInnerAligner>
            <div>
              <h3>69% of analysts are very satisfied with their job</h3>
              <p>
                When it comes to overall job satisfaction, 69% of analysts
                replied that they are very satisfied with their current job.
                However, 18.6% replied that they are only somewhat satisfied,
                and 12.4% are not very satisfied.
              </p>
            </div>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very satisfied", 323],
                ["Somewhat satisfied", 87],
                ["Not very satisfied", 58],
              ]}
              donutPercentage={0.375}
              colors={[colorsV4.purple, colorsV4.pink, colorsV4.warmBlack]}
            />
          </ReportCardInnerAligner>
        </ReportCard>
        <ReportCard paddingOnDesktop={rSize("gap", 2)} centered>
          <ReportCardInnerAligner>
            <div>
              <h3>68% of analysts are very engaged with their work</h3>
              <p>
                Not only are a little over two-thirds very satisfied with their
                job, 67.9% say they’re very engaged with the work they do as a
                security analyst as well. 19.7% replied that they are somewhat
                engaged with their work, while 12.4% say they’re not
                very engaged.
              </p>
              <Spacing size=".5em" />
            </div>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very engaged", 318],
                ["Somewhat engaged", 92],
                ["Not very engaged", 58],
              ]}
              donutPercentage={0.375}
              colors={[colorsV4.purple, colorsV4.pink, colorsV4.warmBlack]}
            />
          </ReportCardInnerAligner>
        </ReportCard>
        <ReportCard paddingOnDesktop={rSize("gap", 2)} centered>
          <ReportCardInnerAligner>
            <div>
              <h3>82% feel respected by their peers outside the SOC</h3>
              <p>
                Having a hand in the day-to-day protection of their organization
                means that analysts are aware of the actions that the rest of
                their organization may not be aware of. Fortunately, 81.6%
                replied that they do feel respected by their peers outside of
                the SOC. However, 18.4% say they do not, possibly suggesting
                that others in the organization simply aren’t aware of the
                amount of work security analysts do.
              </p>
            </div>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Yes", 382],
                ["No", 86],
              ]}
              donutPercentage={0.375}
              colors={[colorsV4.purple, colorsV4.warmBlack]}
            />
          </ReportCardInnerAligner>
        </ReportCard>
        <ReportCard paddingOnDesktop={rSize("gap", 2)} centered>
          <ReportCardInnerAligner>
            <div>
              <h3>69% say their SOC team is understaffed</h3>
              <p>
                With a known cyber security talent shortage, it’s no wonder that
                69% of analysts say their team is currently understaffed.
                However, this could also mean that teams are being overwhelmed
                with mundane, repetitive tasks that are taking their time and
                skill away from other high-impact projects — making them feel
                understaffed as well. 31% replied that they currently have the
                right amount of staff for their needs.
              </p>
            </div>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Yes", 323],
                ["No", 145],
              ]}
              donutPercentage={0.375}
              colors={[colorsV4.purple, colorsV4.warmBlack]}
            />
          </ReportCardInnerAligner>
        </ReportCard>
        <ReportCard paddingOnDesktop={rSize("gap", 2)} centered>
          <div>
            <h3>71% are experiencing some level of burnout at work</h3>
            <p>
              Despite 69% saying they’re very satisfied with their job, nearly
              half of the analysts who replied (47.6%) said that they feel very
              burned out at work, with an additional 23.7% saying they feel
              somewhat burned out. What’s important to note is that analysts are
              passionate about and engaged with the work they do, but other
              factors like long hours and not having the right tools are causing
              them to lose their zeal and motivation, and face mental and
              physical exhaustion — a big driver of analyst turnover. We’ll
              uncover some of the reasons for this later on, and hopefully, find
              out how we can get more analysts to join the 28.6% who say they do
              not feel burned out at work.
            </p>
            <Spacing size="1em" />
          </div>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["I feel very burned out at work", 223],
              ["I feel somewhat burned out at work", 111],
              ["I don't feel burned out at work", 134],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.warmBlack, colorsV4.orange, colorsV4.purple]}
            legendPosition="right"
          />
        </ReportCard>
        <ReportCard paddingOnDesktop={rSize("gap", 2)} centered>
          <div>
            <h3>For 60%, workloads have increased over the past year</h3>
            <p>
              One of the reasons for burnout could be that 60% of analysts have
              had more work than ever over the past year, which could be due to
              a number of factors: being short-staffed, the added challenges of
              working remotely during the pandemic, or more alerts and threats
              due to vulnerabilities caused by remote work. 25.9% say their
              workload hasn’t changed over the past year, while 14.1% say they
              have less work than previous years.
            </p>
            <Spacing size="1em" />
          </div>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["More work than ever", 281],
              ["About the same amount of work", 121],
              ["Less work than ever", 66],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.orange, colorsV4.green, colorsV4.purple]}
            legendPosition="right"
          />
        </ReportCard>
      </Grid>

      <ReportSummaryCard>
        <h3>Summary</h3>
        <p>
          SOC analysts love their work: the majority are very satisfied with
          their jobs, very engaged with their work, and feel respected by their
          peers. At the same time, 71% are burned out to some extent in their
          work environment, and while workloads have increased for over half of
          them, their teams are still understaffed.
        </p>
        <p>
          Even if they do love their jobs, burnout can lead to employee churn;
          as we’ll see later, the majority are looking to switch jobs in the
          next year. In the next sections, we’ll investigate some of the reasons
          why security analysts are experiencing mental and physical drain.
        </p>
        <Spacing size="1em" />
        <h4>Top three skills needed to succeed as an analyst</h4>
        <p>
          What’s going to be the most valuable skill a security analyst can have
          to help them succeed in the future?
        </p>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.purple}
            textInCircle="30.1%"
          >
            <h4>#1: Learning to code</h4>
            <p>
              The number one skill identified is learning to code, according to
              nearly one-third of respondents. While it may seem unrelated to
              the day-to-day tasks, analysts see that knowing how to code will
              help with task automation.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.orange}
            textInCircle="13.5%"
          >
            <h4 style={{ letterSpacing: "-0.01em" }}>
              #2: Learning computer forensics techniques
            </h4>
            <p>
              The second most valuable skill will be learning computer forensics
              techniques, as knowing the process of recovering data from crashed
              servers and drives after a failure or attack is a critical skill
              to helping analysts uncover what went wrong.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.green}
            textInCircle="10%"
          >
            <h4>#3: Knowing how to operationalize Mitre ATT&CK</h4>
            <p>
              The third most valuable skill will be knowing how to
              operationalize Mitre ATT&CK , or knowing how to do threat
              intelligence and modeling in order to be more proactive
              against attacks.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <Spacing size="gap" />
        <p>
          However, instead of being a coding wizard, there are options for
          no-code automation, meaning that analysts can spend more time on
          developing skills directly related to security analysis.
        </p>
        <Spacing size="0.5em" />
        <ReportChartContainer centered>
          <h4>
            What is the #1 skill you feel will be the most important to succeed
            as an analyst in the future?
          </h4>
          <BarChart
            header={["Skill", "Respondents"]}
            data={[
              ["Learning to code", 141],
              ["Operationalize Mitre ATT&CK", 47],
              ["Learn penetration testing", 45],
              ["Computer forensics techniques", 63],
              ["Malware analysis techniques", 38],
              ["Advanced query language techniques", 35],
              ["Threat hunting techniques", 25],
              ["SOAR Integration", 22],
              ["Keeping up to date on threat actors' TTPs", 31],
              ["Obtaining high-level training and certifications", 21],
            ]}
            total={468}
            sortByValue
            height={460}
          />
        </ReportChartContainer>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default SOCChapter1;
