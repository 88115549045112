import { styled } from "@linaria/react";
import { AIPageCardTag, AIPageHeading2 } from "./_AIPageComponents";
import PageSection from "../reusableSections/PageSection";
import AllCaps from "../typography/AllCaps";
import Spacing from "../layout/Spacing";
import {
  GRID_MAX_WIDTH_PX,
  getWidthPxInMaxGrid,
} from "../../constants/globalGrid.constants";
import { AIUseCaseKaleidoscope } from "./AIUseCaseKaleidoscope";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { Link } from "gatsby";
import { w } from "../../utils/string.utils";
import { serif } from "../../styles/fonts.styles";
import NoWrap from "../typography/Nowrap";
import automaticModeExampleOneForeground from "./graphics/automaticModeExampleOneForeground.svg";
import automaticModeExampleOneBackground from "./graphics/automaticModeExampleOneBackground.svg";
import aiActionExampleOneForeground from "./graphics/aiActionExampleOneForeground.svg";
import aiActionExampleTwoForeground from "./graphics/aiActionExampleTwoForeground.svg";
import aiActionExampleThreeForeground from "./graphics/aiActionExampleThreeForeground.svg";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPaths";
import {
  fromDesktop,
  fromDesktopMd,
  fromPhoneLg,
  fromTablet,
  uptoDesktopMd,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Header = styled.header`
  text-align: center;
  padding-top: ${rSize("md", 0.5)};
`;

const ExampleCardRow = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
  }
`;
const ExampleCard = styled.div`
  position: relative;
  ${uptoDesktopMd} {
    height: auto;
    aspect-ratio: 1/1;
    img {
      width: 100%;
    }
  }
  ${fromDesktopMd} {
    height: ${getWidthPxInMaxGrid(6)};
  }
  padding: ${rSize("lg")} 0 ${rSize("lg")} ${rSize("lg")};
  border-width: 1px;
  border-style: solid;
  border-radius: ${rSize("radius")};
  overflow: hidden;
  h3 {
    position: relative;
    font-size: 2rem;
    ${fromPhoneLg} {
      font-size: 2.4rem;
    }
    ${fromTablet} {
      font-size: 2rem;
    }
    ${fromDesktopMd} {
      font-size: 2.4rem;
    }
    margin-top: 1em;
    ${fromTablet} {
      margin-top: 0.5em;
    }
    margin-right: ${rSize("lg")};
    margin-bottom: 1em;
    max-width: 12em;
    letter-spacing: -0.01em;
    z-index: 1;
  }
  &.automaticMode {
    color: ${colors.orange800};
    border-color: ${colors.orange300};
    ${AIPageCardTag} {
      background-color: ${colors.orange100};
    }
  }
  &.aiAction {
    color: ${colors.yellow800};
    border-color: ${colors.yellow300};
    ${AIPageCardTag} {
      background-color: ${colors.yellow100};
    }
  }
`;

const KaleidoscopeWrap = styled.div`
  margin-left: ${rSize("pageMargin", -1)};
  margin-right: ${rSize("pageMargin", -1)};
`;

const LibraryCTAWrap = styled.div`
  display: grid;
  grid-gap: 2em;
  ${uptoDesktopMd} {
    text-align: center;
    justify-content: center;
    padding-top: 2em;
  }
  ${fromDesktop} {
    padding-bottom: 4em;
  }
  ${fromDesktopMd} {
    padding-bottom: ${rSize("xxl")};
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-areas: ${`'. . icon ${w("heading", 3)} ${w("text", 4)} . .'`};
    grid-gap: ${rSize("gap")};
  }
  svg {
    ${uptoDesktopMd} {
      margin-left: auto;
      margin-right: auto;
    }
    ${fromDesktopMd} {
      grid-area: icon;
    }
  }
  h3 {
    ${fromDesktopMd} {
      grid-area: heading;
    }
    font-size: 2.6rem;
    font-weight: 400;
    font-family: ${serif};
  }
  p {
    ${uptoDesktopMd} {
      max-width: 38em;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const LibraryCTAText = styled.div`
  ${fromDesktopMd} {
    grid-area: text;
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const AutomaticModeExampleOneBackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: auto !important;
`;
const AutomaticModeExampleOneForegroundImg = styled.img`
  position: relative;
`;

const AIActionExampleForegroundImg = styled.img`
  position: relative;
`;

const ConnectorWaypointsPositionerTopDiv = styled.div`
  position: absolute;
  top: ${rSize("sectionMargin", -0.5)};
  bottom: 100%;
  left: 0;
  right: 0;
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
`;

const ConnectorWaypointsPositionerBottomDiv = styled.div`
  position: absolute;
  bottom: ${rSize("sectionMargin", -0.5)};
  top: 100%;
  left: 0;
  right: 0;
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
`;

const ConnectorWaypointsPositionerMaxWidth = styled.div`
  position: relative;
  max-width: ${GRID_MAX_WIDTH_PX};
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;

export const AIPageExamplesSection = () => {
  return (
    <PageSection relative>
      <ConnectorWaypointsPositionerTop />
      <ConnectorWaypointsPositionerBottom />

      <Header>
        <AllCaps as="p">New capabilities</AllCaps>
        <Spacing size="md" />
        <AIPageHeading2>What will you build?</AIPageHeading2>
      </Header>

      <Spacing size="xxl" />

      <ExampleCardRow>
        <ExampleCard className="automaticMode">
          <AIPageCardTag>Automatic mode</AIPageCardTag>
          <h3>
            Creating a timeline of <NoWrap>user activity</NoWrap>
          </h3>
          <AutomaticModeExampleOneBackgroundImg
            src={automaticModeExampleOneBackground}
          />
          <AutomaticModeExampleOneForegroundImg
            src={automaticModeExampleOneForeground}
          />
        </ExampleCard>
        <ExampleCard className="aiAction">
          <AIPageCardTag>AI action</AIPageCardTag>
          <h3>Check a document for sensitive information</h3>
          <AIActionExampleForegroundImg src={aiActionExampleOneForeground} />
        </ExampleCard>
      </ExampleCardRow>

      <Spacing size="xxl" />

      <KaleidoscopeWrap>
        <AIUseCaseKaleidoscope />
      </KaleidoscopeWrap>

      <Spacing size="xxl" />

      <ExampleCardRow>
        <ExampleCard className="aiAction">
          <AIPageCardTag>AI action</AIPageCardTag>
          <h3>
            Check to see if an email <NoWrap>is CEO fraud</NoWrap>
          </h3>
          <AIActionExampleForegroundImg src={aiActionExampleTwoForeground} />
        </ExampleCard>
        <ExampleCard className="aiAction">
          <AIPageCardTag>AI action</AIPageCardTag>
          <h3>
            Analyze a pull request <NoWrap>for vulnerabilities</NoWrap>
          </h3>
          <AIActionExampleForegroundImg src={aiActionExampleThreeForeground} />
        </ExampleCard>
      </ExampleCardRow>

      <Spacing size="sectionPadding" />

      <LibraryCTAWrap>
        <LibraryIcon />
        <h3>
          Get inspired <NoWrap>with our library</NoWrap>
        </h3>
        <LibraryCTAText>
          <p>
            Explore other ways to interact with AI in Tines in our library: full
            workflow examples using{" "}
            <Link to="/library/tools/openai">OpenAI</Link> and{" "}
            <Link to="/library/stories/1236252?name=create-virustotal-domain-reputation-summaries-using-nvidia-ai">
              Nvidia
            </Link>{" "}
            alongside AI-powered {/* cspell:disable */}
            <Link to="/library/stories/1124955?name=create-ai-powered-tools-in-tines">
              apps
            </Link>
            {/* cspell:enable */}.
          </p>
          <p>
            <Link to="/library/product-features/ai">
              <strong>Explore the library →</strong>
            </Link>
          </p>
        </LibraryCTAText>
      </LibraryCTAWrap>
    </PageSection>
  );
};

const ConnectorWaypointsPositionerTop = () => (
  <ConnectorWaypointsPositionerTopDiv>
    <ConnectorWaypointsPositionerMaxWidth>
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-start"
        index={0}
        color={colors.yellow200}
        top={0}
        left="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-start"
        index={1}
        top={rSize("md")}
        left="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-start"
        index={2}
        top={rSize("md")}
        left={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-start"
        index={2}
        color={colors.yellow200}
        top={rSize("md", 2)}
        left={rSize("xxl")}
      />

      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-start"
        index={0}
        color={colors.yellow200}
        top={0}
        right="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-start"
        index={1}
        top={rSize("md")}
        right="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-start"
        index={2}
        top={rSize("md")}
        right={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-start"
        index={2}
        color={colors.yellow200}
        top={rSize("md", 2)}
        right={rSize("xxl")}
      />
    </ConnectorWaypointsPositionerMaxWidth>
  </ConnectorWaypointsPositionerTopDiv>
);

const ConnectorWaypointsPositionerBottom = () => (
  <ConnectorWaypointsPositionerBottomDiv>
    <ConnectorWaypointsPositionerMaxWidth>
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-end"
        index={0}
        color={colors.yellow200}
        top={0}
        left={rSize("xxl")}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-end"
        index={1}
        top={rSize("md")}
        left={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-end"
        index={2}
        top={rSize("md")}
        left="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-left-end"
        index={2}
        color={colors.yellow200}
        top={rSize("md", 2)}
        left="50%"
      />

      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-end"
        index={0}
        color={colors.yellow200}
        top={0}
        right={rSize("xxl")}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-end"
        index={1}
        top={rSize("md")}
        right={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-end"
        index={2}
        top={rSize("md")}
        right="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-examples-section-right-end"
        index={2}
        color={colors.yellow200}
        top={rSize("md", 2)}
        right="50%"
      />
    </ConnectorWaypointsPositionerMaxWidth>
  </ConnectorWaypointsPositionerBottomDiv>
);

const LibraryIcon = () => (
  <svg width="58" height="69" viewBox="0 0 58 69" fill="none">
    <path
      d="M31.3047 61.6329H51.0963L57.3874 55.0454H31.3047V61.6329Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.4604 60.2616L39.5412 55.5963H50.1386C50.3794 55.5963 50.6129 55.5144 50.8009 55.3641V55.3641C51.0526 55.1628 51.1991 54.8581 51.1991 54.5358V4.65465C51.1991 2.62896 49.557 0.986816 47.5313 0.986816H9.39817C9.39817 0.986816 6.17879 1.61642 3.27333 4.03768C1.13451 5.82033 0.886719 7.40945 0.886719 7.40945V61.4686H43.1706L44.7958 60.2618L39.4604 60.2616Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.518 2.62012H9.28259L3.94802 5.88678L2.80469 58.4801H44.5824L49.518 53.5801V2.62012Z"
      fill="#F0EBE5"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.7958 9.63072C44.7958 7.60503 43.1536 5.96289 41.128 5.96289H3.94948L2.42362 6.50974L0.886719 7.40961V61.4686H43.1706L44.7958 60.0219V9.63072Z"
      fill="#6359B3"
    />
    <path
      d="M44.7958 9.63072C44.7958 7.60503 43.1536 5.96289 41.128 5.96289H3.94948L2.42362 6.50974L0.886719 7.40961V61.4686H43.1706L44.7958 60.0219V9.63072Z"
      fill="#6359B3"
    />
    <path
      d="M3.94948 5.40769C3.64285 5.40769 3.39428 5.65626 3.39428 5.96289C3.39428 6.26952 3.64285 6.51809 3.94948 6.51809V5.40769ZM0.886719 61.4686H0.331519C0.331519 61.7753 0.58009 62.0238 0.886719 62.0238L0.886719 61.4686ZM43.1706 61.4686V62.0238C43.3067 62.0238 43.4381 61.9738 43.5397 61.8833L43.1706 61.4686ZM44.7958 60.0219L45.1649 60.4366C45.2833 60.3313 45.351 60.1804 45.351 60.0219H44.7958ZM41.128 5.40769H3.94948V6.51809H41.128V5.40769ZM0.331519 7.40961V61.4686H1.44192V7.40961H0.331519ZM0.886719 62.0238H43.1706V60.9134H0.886719V62.0238ZM43.5397 61.8833L45.1649 60.4366L44.4266 59.6072L42.8014 61.0539L43.5397 61.8833ZM45.351 60.0219V9.63072H44.2406V60.0219H45.351ZM41.128 6.51809C42.847 6.51809 44.2406 7.91166 44.2406 9.63072H45.351C45.351 7.2984 43.4603 5.40769 41.128 5.40769V6.51809Z"
      fill="#32313B"
    />
    <path
      d="M41.3367 7.40967H0.886719V61.4689H41.3367C42.3495 61.4689 43.1706 60.6478 43.1706 59.6349V9.24359C43.1706 8.23074 42.3495 7.40967 41.3367 7.40967Z"
      fill="#9A8EF5"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.1136 46.8972C30.1136 46.8972 30.1136 33.1659 30.1136 31.6932C30.1136 30.2202 30.43 28.9175 24.7337 26.4386C19.1897 24.0261 15.5469 26.6496 15.5469 26.6496L15.5469 47.016L30.1136 46.8972Z"
      fill="#FFE1B3"
    />
    <path
      d="M38.1487 11.4404H5.68258C5.17616 11.4404 4.76562 11.851 4.76562 12.3574V56.5835C4.76562 57.0899 5.17616 57.5004 5.68258 57.5004H38.1487C38.6551 57.5004 39.0656 57.0899 39.0656 56.5835V12.3574C39.0656 11.851 38.6551 11.4404 38.1487 11.4404Z"
      stroke="#FBB598"
      strokeWidth="1.568"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.75839 49.1729L7.26086 51.3737V55.258L4.76332 57.4587V61.4686L2.26562 63.6694V67.8866H25.3292L27.7935 65.7151H27.8268L30.3241 63.5145L32.8218 61.3138V49.1729H9.75839Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.5961 49.6201L30.2081 51.3431V55.5059L27.9484 57.349V60.9763L25.332 63.6523L25.451 67.8744L32.7653 61.3138L32.8751 50.2557L32.5961 49.6201Z"
      fill="#6359B3"
    />
    <path
      d="M25.2463 63.9346H2.36719V67.5472H25.2463V63.9346Z"
      fill="#8578E6"
    />
    <path
      d="M25.0362 63.6523H2.55859L5.05613 61.3926H27.5934L25.0362 63.6523Z"
      fill="#B0A6F9"
    />
    <path
      d="M25.3291 63.6694H2.26562V67.8866H25.3291V63.6694Z"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.5323 57.2895H5.05469L7.55207 55.0298H30.0895L27.5323 57.2895Z"
      fill="#B0A6F9"
    />
    <path
      d="M30.2386 51.4023H7.35938V55.0148H30.2386V51.4023Z"
      fill="#8578E6"
    />
    <path d="M27.6097 57.6016H4.73047V61.214H27.6097V57.6016Z" fill="#8578E6" />
    <path
      d="M4.76172 57.4585H27.7948"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.0327 51.2833H7.55469L10.0524 49.0239H32.5895L30.0327 51.2833Z"
      fill="#EB81AF"
    />
    <path
      d="M30.325 51.374H7.26172"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.8207 61.3135L38.5964 61.4682L25.3281 67.8863L32.8207 61.3135Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.75839 49.1729L7.26086 51.3737V55.258L4.76332 57.4587V61.4686L2.26562 63.6694V67.8866H25.3292L27.7935 65.7151H27.8268L30.3241 63.5145L32.8218 61.3138V49.1729H9.75839Z"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.8207 49.1729L30.323 51.3737V55.258L27.8257 57.4587V61.4686L25.3281 63.6694V67.8866"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.76172 61.4683H27.8252"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.26172 55.2583H30.325"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.8538 49.0238V47.0161L15.7683 47.0343L13.0103 49.0238H9.83058L7.26172 51.2832H30.325L32.8227 49.0238H29.8538Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.2697 23.083C14.8902 23.083 9.71875 28.2547 9.71875 34.634L9.71875 49.0243H13.0082V35.0103C13.0082 30.4476 16.7071 26.7488 21.2697 26.7488C25.8323 26.7488 29.5311 30.4476 29.5311 35.0103V49.0243H32.8206V34.634C32.8206 28.2547 27.6491 23.083 21.2697 23.083Z"
      fill="#D4CFFC"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.7658 46.8834V29.0801C15.7658 29.0801 13.0078 30.6292 13.0078 35.7803C13.0078 40.9313 13.0078 48.8729 13.0078 48.8729L15.7658 46.8834Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.1562 34.7294L15.6088 33.5427L15.7669 29.0801C15.7669 29.0799 13.1562 31.1692 13.1562 34.7294Z"
      fill="#32313B"
    />
    <path
      d="M44.1289 7.03065L49.5501 2.65869"
      stroke="#32313B"
      strokeWidth="1.1104"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
