/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { styled } from "@linaria/react";
import dayjs from "dayjs";
import { graphql } from "gatsby";
import SEO from "../components/utilities/SEO";
import WebinarEntryLink from "../components/webinars/WebinarEntryLink";
import {
  fromDesktop,
  fromTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { DatoCmsWebinar, WebinarIndexPageQuery } from "../../graphql-types";
import {
  isPastWebinarWithLink,
  isUpcomingOrLiveWebinar,
} from "../utils/webinar.utils";
import { css } from "linaria";
import {
  LayoutWithSidebar,
  LayoutWithSidebarContent,
} from "../components/layout/LayoutWithSidebar";
import StandardSidebar from "../components/layout/StandardSidebar";
import SpaceChildren from "../components/layout/SpaceChildren";
import Spacing from "../components/layout/Spacing";
import ogImage from "../../static/images/og/tines-webinars.png";
import SpotIllustration from "../components/illustrations/SpotIllustration";
import { useOnMount } from "../utils/lifeCycle.utils";
import { SearchableEntry } from "../types/helper.types";
import { formatAsSearchString } from "../utils/search.utils";
import { useState } from "react";
import GenericSearchBar from "../components/search/GenericSearchBar";
import { scrollToTop } from "../utils/scroll.utils";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";

const WebinarSectionHeading = styled.h2`
  font-size: inherit;
  font-weight: 600;
  + * {
    margin-top: ${rSize("lg")};
  }
`;

const WebinarGridSection = styled.div<{ columnCount?: number }>`
  display: grid;
  grid-gap: ${rSize("xl")} ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktop} {
    grid-template-columns: ${p =>
      `repeat(${p.columnCount ?? 3}, minmax(0, 1fr))`};
  }
`;

const PageWebinarIndex: PageComponent<{
  data: WebinarIndexPageQuery;
}> = props => {
  useOnMount(() => {
    props.data.webinars.nodes.forEach(webinar => {
      (webinar as unknown as SearchableEntry).__searchString =
        formatAsSearchString(`${webinar.title} ${webinar.excerpt}`);
    });
  });
  const formState = useState({
    query: "",
  });
  const formattedQuery = formState[0].query;
  const webinars = formattedQuery
    ? props.data.webinars.nodes.filter(n =>
        (n as unknown as SearchableEntry).__searchString?.includes(
          formattedQuery
        )
      )
    : props.data.webinars.nodes;
  const futureWebinars = webinars
    .filter(webinar => isUpcomingOrLiveWebinar(webinar as DatoCmsWebinar))
    .sort((a, b) => (dayjs(a.timeStart).isAfter(b.timeStart) ? 1 : -1));
  const shouldShowUpNext = futureWebinars.length === 1;
  const upNext = shouldShowUpNext ? futureWebinars[0] : null;
  const upcomingWebinars = shouldShowUpNext
    ? futureWebinars.slice(1)
    : futureWebinars;
  const pastWebinars = webinars
    .filter(webinar => isPastWebinarWithLink(webinar as DatoCmsWebinar))
    .sort((a, b) => dayjs(b.timeStart).diff(dayjs(a.timeStart)));
  const handleSearchQueryChange = () => {
    scrollToTop();
  };
  const description =
    "Join our webinars to learn how Tines can help you automate complex repetitive workflows with simple building blocks.";
  return (
    <Page {...props} navThemeColor="purple">
      <SEO title="Webinars" description={description} image={ogImage} />
      <AdminToolBarWithEditInDatoCmsButton itemType="webinar" />

      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <SpotIllustration
            name="webinar"
            className={css`
              ${fromTabletLg} {
                margin-top: -5%;
                margin-left: -5%;
                margin-bottom: -5%;
              }
            `}
          />
          <h1>Webinars</h1>
          <GenericSearchBar
            formState={formState}
            field="query"
            icon="right"
            onChange={handleSearchQueryChange}
          />
          <p>{description}</p>
        </StandardSidebar>

        <LayoutWithSidebarContent>
          <SpaceChildren size="sectionPadding">
            {upNext && (
              <div id="upcoming-live">
                <WebinarEntryLink webinar={upNext as DatoCmsWebinar} featured />
              </div>
            )}
            {upcomingWebinars.length > 0 && (
              <div>
                <WebinarSectionHeading>Upcoming</WebinarSectionHeading>
                <WebinarGridSection
                  columnCount={
                    upcomingWebinars.length < 3 || upcomingWebinars.length === 4
                      ? 2
                      : 3
                  }
                >
                  {upcomingWebinars.map(webinar => (
                    <WebinarEntryLink
                      key={webinar.id}
                      webinar={webinar as DatoCmsWebinar}
                      featured
                    />
                  ))}
                </WebinarGridSection>
              </div>
            )}
            {pastWebinars.length > 0 && (
              <div id="on-demand">
                <WebinarSectionHeading>Watch on-demand</WebinarSectionHeading>
                <WebinarGridSection>
                  {pastWebinars.map(webinar => (
                    <WebinarEntryLink
                      key={webinar.id}
                      webinar={webinar as DatoCmsWebinar}
                    />
                  ))}
                </WebinarGridSection>
              </div>
            )}
          </SpaceChildren>
        </LayoutWithSidebarContent>
      </LayoutWithSidebar>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const webinarIndexPageQuery = graphql`
  query WebinarIndexPage {
    webinars: allDatoCmsWebinar(
      filter: {
        category: { slug: { eq: "webinars" } }
        isPrivate: { ne: true }
        # exclude the two test webinars that are always in CMS
        originalId: { nin: ["72675259", "108933288"] }
      }
    ) {
      nodes {
        id: originalId
        title
        slug
        timeStart
        timeEnd
        recordedVideoLink
        excerpt
        colorTheme
        upcomingWebinarEntryBackdrop {
          url
          width
          height
        }
        category {
          slug
        }
        hosts {
          id: originalId
          name
          surname
          title
          photoIcon {
            url
            width
            height
          }
          organization {
            id: originalId
            name
          }
        }
      }
    }
  }
`;

export default PageWebinarIndex;
