/* eslint-disable @typescript-eslint/no-misused-promises */
import { styled } from "@linaria/react";
import { css, cx } from "linaria";
import { ReactNode, useRef, useState } from "react";
import {
  fromDesktop,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  EventDirectoryItem,
  GeolocationCoords,
  UseStateReturnType,
} from "../../types/helper.types";
import { getUserGeolocation } from "../../utils/geolocation.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { resolveAfter } from "../../utils/promises.utils";
import { formatAsSearchString } from "../../utils/search.utils";
import {
  getUrlQueryParams,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import EventDirectoryItemEntry from "./EventDirectoryItemEntry";
import LoadingIndicator from "../utilities/LoadingIndicator";
import SpotIllustration from "../illustrations/SpotIllustration";
import GenericSearchBar from "../search/GenericSearchBar";
import Spacing from "../layout/Spacing";
import { LayoutWithSidebar } from "../layout/LayoutWithSidebar";
import StandardSidebar, {
  StandardSidebarLinkList,
} from "../layout/StandardSidebar";

type Props = {
  searchFormState: UseStateReturnType<{ query: string }>;
  items: EventDirectoryItem[];
  selectedItem: EventDirectoryItem | null;
  onSelectItem: (item: EventDirectoryItem | null) => void;
  onRetrievingUserGeolocation: (coords: GeolocationCoords) => void;
  map: ReactNode;
  ready?: boolean;
};

const EventDirectoryItemIndexWrapper = styled.div`
  text-align: left;
  ${fromDesktop} {
    position: relative;
    margin-top: 2em;
  }
`;

const ControlSet = styled.div`
  font-size: 1.6rem;
`;

const SearchBarWrap = styled.div`
  input {
    &:hover {
      background-color: ${withOpacity(colors.purple, 0.05)};
    }
    &:focus {
      background-color: ${withOpacity(colors.purple, 0.1)};
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  align-content: stretch;
  ${fromTabletLg} {
    grid-template-columns: 1fr 1fr;
  }
  ${fromDesktop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;
  padding: 1em ${rSize("gap")};
  img {
    width: 100px;
    height: 100px;
  }
  p {
    opacity: 0.6;
  }
`;

const BreakWord = styled.span`
  word-wrap: break-word;
`;

const LoadingIndicatorSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4em ${rSize("gap")};
`;

const GeolocationStatusBarWrap = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
`;
const GeolocationStatusBar = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto minmax(0, 1fr);
  grid-gap: 0.5em;
  background-color: ${withOpacity(colors.purple, 0.1)};
  border-radius: 1.2rem;
  padding: 0.5em 1em;
  font-size: 1.6rem;
  font-weight: 500;
  min-height: 3em;
  ${fromDesktop} {
    border: 2px solid ${withOpacity(colors.purple, 0.1)};
  }
`;

const showTypes = [
  { slug: "", label: "All events" },
  { slug: "near-me", label: "Near me" },
  { slug: "virtual", label: "Virtual" },
];

const EventDirectoryItemIndex = (props: Props) => {
  const [show, setShow] = useState("");
  const [gettingGeolocation, setGettingGeolocation] = useState(false);
  const itemsRef = useRef(props.items);
  itemsRef.current = props.items;
  const [itemsToShow, setItemsToShow] = useState(props.items);
  const [userGeolocation, setUserGeolocation] =
    useState<GeolocationCoords | null>(null);
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const makeFilterSwitcher = (slug: string) => async () => {
    setShow(slug);
    setUrlQueryParam("show", slug);
    switch (slug) {
      case "near-me":
        if (!userGeolocation && !gettingGeolocation) {
          setGettingGeolocation(true);
          const userGeolocation = await getUserGeolocation();
          if (userGeolocation?.city) setUserCity(userGeolocation.city);
          if (userGeolocation?.state) setUserState(userGeolocation.state);
          if (userGeolocation?.country) setUserCountry(userGeolocation.country);
          setUserGeolocation(userGeolocation);
          if (userGeolocation)
            props.onRetrievingUserGeolocation(userGeolocation);
          await resolveAfter();
          setGettingGeolocation(false);
        }
        setItemsToShow(
          [...itemsRef.current].sort(
            (a, b) =>
              (a.distanceFromUser ?? Infinity) -
              (b.distanceFromUser ?? Infinity)
          )
        );
        break;
      case "virtual":
        setItemsToShow(
          itemsRef.current.filter(
            item =>
              item.type === "webinar" ||
              item.categories.find(c => c.slug === "virtual")
          )
        );
        break;
      default:
        setItemsToShow([
          ...itemsRef.current.filter(item => item.featured),
          ...itemsRef.current.filter(item => !item.featured),
        ]);
        break;
    }
  };
  useOnMount(() => {
    makeFilterSwitcher(getUrlQueryParams<{ show: string }>().show || "")();
  });
  const query = formatAsSearchString(props.searchFormState[0].query);
  const itemsMatchingQuery = query
    ? itemsToShow.filter(i => i.__searchString.includes(query))
    : itemsToShow;
  return (
    <div>
      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <SpotIllustration
            name="worldMap"
            className={css`
              ${fromTabletLg} {
                margin-top: -5%;
                margin-left: -5%;
                margin-bottom: -5%;
              }
            `}
          />
          <h1>Upcoming events</h1>
          <ControlSet>
            <SearchBarWrap>
              <GenericSearchBar
                formState={props.searchFormState}
                field="query"
                placeholder="Search for events"
                keepInUrl
                icon="right"
              />
            </SearchBarWrap>
            <Spacing size="1em" />
            <StandardSidebarLinkList>
              {showTypes.map(s => (
                <button
                  key={s.slug}
                  className={cx(show === s.slug && "active")}
                  onClick={makeFilterSwitcher(s.slug)}
                >
                  {s.label}
                </button>
              ))}
            </StandardSidebarLinkList>
          </ControlSet>
        </StandardSidebar>
        <div>
          {props.map}
          <EventDirectoryItemIndexWrapper>
            {show === "near-me" ? (
              <GeolocationStatusBarWrap>
                {gettingGeolocation && (
                  <GeolocationStatusBar>
                    <LoadingIndicator size={18} color={colors.purple} />
                    <span>Retrieving your location...</span>
                  </GeolocationStatusBar>
                )}
                {(userCity || userCountry) && (
                  <GeolocationStatusBar>
                    <svg
                      width="14"
                      height="20"
                      viewBox="0 0 14 20"
                      fill="#FF4646"
                    >
                      <path d="M7 19.0017C5.73694 17.9244 4.56619 16.7433 3.5 15.4707C1.9 13.5597 8.78894e-07 10.7137 8.78894e-07 8.00174C-0.00069302 6.6167 0.409509 5.26259 1.17869 4.11077C1.94788 2.95895 3.04147 2.0612 4.32107 1.53114C5.60067 1.00108 7.00875 0.86253 8.36712 1.13303C9.72548 1.40353 10.9731 2.07092 11.952 3.05074C12.6038 3.6996 13.1203 4.47128 13.4719 5.32111C13.8234 6.17095 14.0029 7.08207 14 8.00174C14 10.7137 12.1 13.5597 10.5 15.4707C9.4338 16.7432 8.26306 17.9244 7 19.0017ZM7 5.00174C6.20435 5.00174 5.44129 5.31781 4.87868 5.88042C4.31607 6.44303 4 7.20609 4 8.00174C4 8.79739 4.31607 9.56045 4.87868 10.1231C5.44129 10.6857 6.20435 11.0017 7 11.0017C7.79565 11.0017 8.55871 10.6857 9.12132 10.1231C9.68393 9.56045 10 8.79739 10 8.00174C10 7.20609 9.68393 6.44303 9.12132 5.88042C8.55871 5.31781 7.79565 5.00174 7 5.00174Z" />
                    </svg>
                    <span>
                      Looks like you are located near{" "}
                      {[
                        userCity,
                        userCountry === "United States"
                          ? userState
                          : userCountry,
                      ].join(", ")}
                      .
                    </span>
                  </GeolocationStatusBar>
                )}
              </GeolocationStatusBarWrap>
            ) : (
              <Spacing size="1em" />
            )}

            {props.ready ? (
              itemsMatchingQuery.length === 0 ? (
                <EmptyState>
                  <SpotIllustration name="emptyTreasureChest" />
                  {query ? (
                    <p>
                      No {show === "virtual" ? "virtual" : ""} events matched
                      your query "<BreakWord>{query}</BreakWord>
                      ".
                    </p>
                  ) : (
                    <p>
                      No {show === "virtual" ? "virtual" : ""} events yet!
                      Please check back later.
                    </p>
                  )}
                </EmptyState>
              ) : (
                <Grid>
                  {itemsMatchingQuery.map(i => (
                    <EventDirectoryItemEntry
                      key={i.id}
                      item={i}
                      isSelected={props.selectedItem === i}
                      onSelect={props.onSelectItem}
                    />
                  ))}
                </Grid>
              )
            ) : (
              <LoadingIndicatorSection>
                <LoadingIndicator />
              </LoadingIndicatorSection>
            )}
          </EventDirectoryItemIndexWrapper>
        </div>
      </LayoutWithSidebar>
    </div>
  );
};

export default EventDirectoryItemIndex;
