import { styled } from "@linaria/react";
import { FormEvent, ReactNode, useState } from "react";
import { DatoCmsEvent } from "../../../graphql-types";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import Button from "../forms/Button";
import SpotIllustration from "../illustrations/SpotIllustration";
import TextInput, { TextInputSet } from "../forms/TextInput";
import USAddressFieldSet from "../forms/USAddressFieldSet";
import { eventIsOpenForRegistration } from "../../utils/events.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import YesOrNoField from "../forms/YesOrNoField";
import EventRegistrationClosedNotice from "./EventRegistrationClosedNotice";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { resolveAfter } from "../../utils/promises.utils";
import { eventRegistrationWebhook } from "../../constants/webhooks.constants";
import InlineSelect from "../forms/InlineSelect";
import { reportErrorSilently } from "../../utils/error.utils";
import PrivacyNotice from "../utilities/PrivacyNotice";
import Footnotes from "../basic/Footnotes";
import OpenEndedQuestionField from "../forms/OpenEndedQuestionField";
import { getFormMetaInfo } from "../../utils/form.utils";

type Props = {
  event: DatoCmsEvent;
  heading?: ReactNode;
  yesNoQuestions: string[];
  openEndedQuestions: string[];
  collectAddress?: boolean;
  streetFieldPlaceholder?: string | null;
};

const FormContainer = styled.form`
  position: relative;
  border-radius: 1.5em;
  padding: 1em;
  ${onlyPhones} {
    padding-top: 2em;
  }
  ${fromTablet} {
    padding: 2em;
    border-radius: 2em 2em 2em 2.5em;
  }
  background-color: ${colorsV4.canvas550};
  h3 {
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 1em;
  }
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: [start] 1fr [end];
  margin-top: 1em;
  margin-bottom: 0.5em;
  grid-gap: 1em;
`;

const SimpleFieldSet = styled.div`
  background-color: ${colorsV4.canvas700};
  border-radius: 1em;
  padding: 1em;
  > * + * {
    margin-top: 0.75em;
  }
  p {
    font-weight: 500;
    line-height: 1.2;
  }
`;

const SuccessMessageContainer = styled.div`
  background-color: ${colorsV4.canvas700};
  padding: 1em 1.5em 2.5em 1.5em;
  border-radius: 1.25em;
  text-align: center;
  h2 {
    font-size: 2rem;
  }
`;

const SuccessMessage = () => (
  <SuccessMessageContainer className="EventRegistrationFormSuccessMessage">
    <SpotIllustration name="paperPlane" size="150" />
    <h2>Thanks for submitting, we’ll be in touch.</h2>
  </SuccessMessageContainer>
);

const makeFormBase = () => ({
  ["First name"]: "",
  ["Last name"]: "",
  Title: "",
  Company: "",
  Email: "",
  Phone: "",
});

function EventRegistrationForm(props: Props) {
  const { event } = props;

  const [registrationOpen, setRegistrationOpen] = useState(
    eventIsOpenForRegistration(event)
  );

  const [awaitingResponse, setAwaitingResponse] = useState(false);

  const formState = useState(() => ({
    ...makeFormBase(),
    ...(props.collectAddress
      ? {
          Street: "",
          City: "",
          State: "",
          ["Zip Code"]: "",
        }
      : {}),
  }));

  const questionnaireState = useState(() =>
    [...props.openEndedQuestions, ...props.yesNoQuestions].reduce(
      (object, item) => ({ ...object, [item]: "" }),
      {}
    )
  );

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (eventIsOpenForRegistration(event) === false) {
      window.alert("Sorry, the registration has closed.");
      return;
    }
    setAwaitingResponse(true);
    if (!(await emailIsLegitimate(formState[0].Email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }

    const payload = {
      form: formState[0],
      questionnaire: questionnaireState[0],
      eventId: event.id,
      ...getFormMetaInfo(),
    };

    try {
      await fetch(eventRegistrationWebhook, {
        method: "post",
        body: JSON.stringify(payload),
      });
      setHasSubmitted(true);
      await resolveAfter();
      await scrollToHash({ useHash: "#event-form", doNotPushState: true });
    } catch (e) {
      reportErrorSilently(e);
      window.alert(`Hmm, something wasn't quite right. Please try again.`);
    } finally {
      setAwaitingResponse(false);
    }
  };

  useOnMount(() => {
    const handle = setInterval(() => {
      setRegistrationOpen(eventIsOpenForRegistration(event));
    }, 1000);
    return () => {
      clearInterval(handle);
    };
  });

  return (
    <div id="event-form" className="EventRegistrationForm">
      {hasSubmitted ? (
        <SuccessMessage />
      ) : registrationOpen ? (
        <>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <FormContainer onSubmit={handleSubmit}>
            {props.heading && <h3>{props.heading}</h3>}
            <FormGrid>
              <TextInputSet>
                {Object.keys(makeFormBase()).map(field => {
                  const required =
                    field !== "Phone" || (event.requirePhone ?? false);
                  return (
                    <TextInput
                      key={field}
                      backgroundColor={colorsV4.canvas300}
                      name={field}
                      formState={formState}
                      required={required}
                      placeholder={`${
                        field === "Title" ? "Job Title" : field
                      } ${required ? "*" : "(Optional)"}`}
                      type={
                        field === "Email"
                          ? "email"
                          : field === "Phone"
                          ? "tel"
                          : "text"
                      }
                    />
                  );
                })}
              </TextInputSet>

              {props.collectAddress && (
                <USAddressFieldSet
                  formState={formState}
                  inputBackgroundColor={colorsV4.canvas300}
                  streetFieldPlaceholder={props.streetFieldPlaceholder}
                />
              )}

              {props.openEndedQuestions.map(q => (
                <OpenEndedQuestionField
                  key={q}
                  question={q}
                  value={
                    questionnaireState[0][
                      q as keyof typeof questionnaireState["0"]
                    ]
                  }
                  setValue={v => {
                    questionnaireState[1](object => ({
                      ...object,
                      [q]: v,
                    }));
                  }}
                />
              ))}

              {props.yesNoQuestions.map(q => (
                <YesOrNoField
                  key={q}
                  question={q}
                  value={
                    questionnaireState[0][
                      q as keyof typeof questionnaireState["0"]
                    ]
                  }
                  setValue={v => {
                    questionnaireState[1](object => ({
                      ...object,
                      [q]: v,
                    }));
                  }}
                />
              ))}

              {props.event.includeTinesCustomerSatisfactionQuestion && (
                <SimpleFieldSet>
                  <p>
                    On a scale of 1-10, how likely are you to recommend Tines to
                    a customer or colleague?
                  </p>
                  <InlineSelect
                    formState={formState}
                    name="How likely are you to recommend Tines to a customer or colleague?"
                    backgroundColor={colorsV4.canvas300}
                    borderRadius=".75em"
                    options={Array(10)
                      .fill(0)
                      .map((n, i) => ({
                        label: `${i + 1}`,
                        value: `${i + 1}`,
                      }))}
                  />
                </SimpleFieldSet>
              )}

              {props.event.includeTinesTShirtSizeSelector && (
                <SimpleFieldSet>
                  <p>Select your size for your Tines T-shirt:</p>
                  <InlineSelect
                    formState={formState}
                    name="Tines T-Shirt Size"
                    backgroundColor={colorsV4.canvas300}
                    borderRadius=".75em"
                    options={[
                      { label: "S", value: "S" },
                      { label: "M", value: "M" },
                      { label: "L", value: "L" },
                      { label: "XL", value: "XL" },
                      { label: "XXL", value: "XXL" },
                    ]}
                  />
                </SimpleFieldSet>
              )}

              <Button
                loading={awaitingResponse}
                children="Submit"
                type="submit"
                name="submit"
                fullWidth
                padding="1em"
              />

              <div>
                <Footnotes>
                  <p>
                    Tines reserves the right, including without prior notice, to
                    limit the number of event attendees, to cancel any event
                    registration, and to refuse entry to events for any reason
                    whatsoever at our sole discretion.
                  </p>
                  <PrivacyNotice />
                </Footnotes>
              </div>
            </FormGrid>
          </FormContainer>
        </>
      ) : (
        <EventRegistrationClosedNotice eventName={event.name!} />
      )}
    </div>
  );
}

export default EventRegistrationForm;
