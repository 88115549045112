/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { styled } from "@linaria/react";
import dayjs from "dayjs";
import { Link, graphql } from "gatsby";
import { css, cx } from "linaria";
import DateTimeFormatter from "../components/basic/DateTimeFormatter";
import PersonInfoItem from "../components/general/PersonInfoItem";
import WebinarPageContentGrid from "../components/webinars/WebinarPageContentGrid";
import PageSection from "../components/reusableSections/PageSection";
import SEO from "../components/utilities/SEO";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import Spacing from "../components/layout/Spacing";
import StandardArticle from "../components/articles/StandardArticle";
import GlobalTimezoneSelect from "../components/forms/TimezoneSelect";
import WebinarRegistrationForm from "../components/webinars/WebinarRegistrationForm";
import {
  fromTablet,
  fromTabletLg,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import {
  brandColorThemeVar,
  colors,
  getBrandColorTheme,
} from "../styles/colors.styles";
import { fluidFontSize, fluidLineHeight } from "../styles/helpers.styles";
import { PageComponent } from "../types/gatsby.types";
import {
  DatoCmsPerson,
  DatoCmsWebinar,
  WebinarBySlugQuery,
} from "../../graphql-types";
import { useOnMount } from "../utils/lifeCycle.utils";
import {
  getHumanizedDuration,
  getStandardTimezoneFromReadable,
} from "../utils/time.utils";
import {
  isLiveWebinarOrJustEnded,
  isPastWebinar,
  isUpcomingOrLiveWebinarOrJustEnded,
  isUpcomingWebinar,
} from "../utils/webinar.utils";
import Page from "./Page.template";
import WebinarVideoBlock from "../components/webinars/WebinarVideoBlock";
import { useRef, useState } from "react";
import VideoBlock from "../components/blocks/VideoBlock.block";
import InfoBox from "../components/general/InfoBox";
import { runAfter } from "../utils/promises.utils";
import ImageFrame from "../components/basic/ImageFrame";
import { getCookie } from "../utils/cookies.utils";
import { readMarketingAttribution } from "../utils/marketingAttribution.utils";
import NewsletterCTASection from "../components/reusableSections/NewsletterCTASection";
import { Serif } from "../components/typography/Serif";
import axios from "axios";
import { reportErrorSilently } from "../utils/error.utils";
import ReactMarkdown from "react-markdown";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import AllCaps from "../components/typography/AllCaps";
import { Paths } from "../utils/pathBuilders.utils";
import { rSize } from "../styles/responsiveSizes.styles";
import WebinarCommentingPanel from "../components/webinars/WebinarCommentingPanel";
import WiderPageSection from "../components/layout/WiderPageSection";

const WebinarPageContent = styled.div``;

const webinarHeaderImageFrame = css`
  border-radius: ${rSize("radius")};
`;

const imageFrameBeforeContent = css`
  border-radius: ${rSize("radius")};
`;

const CategoryList = styled.div`
  a {
    text-decoration: none;
  }
  > * {
    margin-top: 0.75em;
    margin-right: 0.75em;
  }
`;

const Title = styled.h1`
  ${fluidFontSize(36, 48)};
  ${fluidLineHeight(40, 52)};
  margin-top: 0.75em;
  font-weight: 500;
  &.long {
    ${fluidFontSize(30, 36)};
    ${fluidLineHeight(36, 44)};
  }
`;

const TimeDisplaySet = styled.div`
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
`;

const TimeStartDisplay = styled.p`
  display: flex;
  align-items: center;
  font-weight: 600;
  .upcoming & {
    font-size: 1.8rem;
    ${fromTablet} {
      font-size: 2rem;
    }
  }
  > * {
    display: block;
    margin-right: 0.3em;
  }
  > svg {
    margin-right: 0.5em;
  }
`;

const TimezoneSelectWrapper = styled.div`
  display: flex;
  align-items: baseline;
  label {
    font-weight: 600;
  }
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;

const DurationDisplay = styled.p`
  strong {
    font-weight: 600;
  }
`;

const WebinarSinglePageAside = styled.div`
  h3 {
    margin-bottom: 1em;
    font-weight: 600;
    font-size: 2rem;
  }
`;

const WebinarHostList = styled.div`
  > * {
    + * {
      margin-top: 1em;
    }
  }
  ${onlyPhones} {
    margin-bottom: 1em;
  }
`;

const VideoFrameAndCommentPanelWrap = styled.div`
  margin-bottom: ${rSize("gap")};
  &.hasCommentPanel {
    display: grid;
    grid-gap: 1em;
    ${fromTabletLg} {
      grid-template-columns: minmax(0, 1fr) auto;
    }
  }
`;

const VideoFrame = styled.div`
  background-color: ${colors.darkest};
  border-radius: ${rSize("radius")};
  display: flex;
  align-items: center;
  justify-content: stretch;
  > * {
    flex: 1 1 100%;
  }
`;

const WebinarPageTemplate: PageComponent<{
  data: WebinarBySlugQuery;
}> = props => {
  const [webinar, setWebinar] = useState(props.data.webinar as DatoCmsWebinar);
  const webinarRef = useRef(webinar);
  webinarRef.current = webinar;

  const [enablePageBackgroundTransition, setEnablePageBackgroundTransition] =
    useState(false);
  const [shouldShowLiveStream, setShouldShowLiveStream] = useState(false);
  const [shouldShowOnDemandVideo, setShouldShowOnDemandVideo] = useState(
    !!(isPastWebinar(webinar) && webinar.recordedVideoLink)
  );
  const [isUpcoming, setIsUpcoming] = useState(isUpcomingWebinar(webinar));
  const [isLiveOrJustEnded, setIsLiveOrJustEnded] = useState(
    isLiveWebinarOrJustEnded(webinar)
  );
  const [hasEnded, setHasEnded] = useState(isPastWebinar(webinar));
  const [isUpcomingOrLive, setIsUpcomingOrLive] = useState(
    isUpcomingOrLiveWebinarOrJustEnded(webinar)
  );
  const [canRegister, setCanRegister] = useState(isUpcomingOrLive);
  const aside = (
    <WebinarSinglePageAside>
      {webinar.hosts && webinar.hosts.length > 0 && (
        <>
          <h3>{webinar.hosts.length === 1 ? "Hosted by" : "Speakers"}</h3>
          <WebinarHostList>
            {webinar.hosts.map(host => (
              <PersonInfoItem person={host as DatoCmsPerson} key={host!.id} />
            ))}
          </WebinarHostList>
        </>
      )}
    </WebinarSinglePageAside>
  );

  const fetchLatestWebinarInfo = async () => {
    try {
      const { data } = await axios.get(
        `/api/webinar?id=${webinarRef.current.id}`
      );
      setWebinar(Object.assign(webinar, data as DatoCmsWebinar));
    } catch (e) {
      reportErrorSilently(e);
    }
  };

  useOnMount(() => {
    fetch(
      "https://hq.tines.io/webhook/96e18ff7c17951da34bde6d626e83cda/ad672a40463c3e1902aa7be31c58a500",
      {
        method: "POST",
        body: JSON.stringify({
          email: getCookie("email_address") ?? "",
          webinarId: webinar.id,
          marketingAttributions: readMarketingAttribution(),
        }),
      }
    );
    fetchLatestWebinarInfo();
    runAfter(() => setEnablePageBackgroundTransition(true), 1000);

    if (webinar.recordedVideoLink) setShouldShowOnDemandVideo(true);

    const { liveStreamLinkYoutube } = props.data.webinar ?? {};
    if (!liveStreamLinkYoutube) return;

    const checkTime = () => {
      setIsUpcoming(isUpcomingWebinar(webinar));
      setIsUpcomingOrLive(isUpcomingOrLiveWebinarOrJustEnded(webinar));
      const hasEnded = isPastWebinar(webinar);
      const isLiveOrJustEnded = isLiveWebinarOrJustEnded(webinar);
      setHasEnded(hasEnded);
      setIsLiveOrJustEnded(isLiveOrJustEnded);

      if (isLiveOrJustEnded) {
        setShouldShowLiveStream(true);
        setCanRegister(false);
      } else {
        setShouldShowLiveStream(false);
        if (hasEnded && webinar.recordedVideoLink) {
          setCanRegister(false);
          setShouldShowOnDemandVideo(true);
        }
      }
    };

    const interval = setInterval(checkTime, 1000);

    checkTime();

    return () => clearInterval(interval);
  });

  const description = `${dayjs
    .tz((webinar.timeStart as string | null)?.replace("T", "").split("+")[0])
    .tz(getStandardTimezoneFromReadable(webinar.timezone) ?? "utc")
    .format(`MMM D YYYY, h:mm A`)} • ${webinar.timezone ?? "UTC"} Time • ${
    webinar.excerpt
  }`;

  const isBootcamp = webinar.category?.slug === "bootcamp";
  const isWebinar = webinar.category?.slug === "webinar";
  const headerImage = webinar.category?.headerImageWide;
  const ogImage =
    webinar.customPreviewImage ??
    webinar.category?.coverImage ??
    webinar.coverImage;

  const theme = getBrandColorTheme(
    webinar.category?.color || webinar.colorTheme
  );
  const shouldUseDarkMode = shouldShowLiveStream || webinar.darkMode;
  const bgColor = shouldUseDarkMode ? colors.dark900 : theme.c50;
  const textColor = shouldUseDarkMode ? colors.white : theme.c800;
  const navTheme = theme.name === "dark" ? "purple" : theme.name;

  return (
    <Page
      {...props}
      browserThemeColor={bgColor}
      browserThemeColorDesktop={bgColor}
      backgroundColor={bgColor}
      theme={theme.name}
      navThemeColor={navTheme}
      textColor={textColor}
    >
      <AdminToolBarWithEditInDatoCmsButton
        itemType="webinar"
        recordId={webinar.id}
      />
      {enablePageBackgroundTransition && (
        <style children={`:root { transition: background-color 1s }`} />
      )}
      <SiteNavHeightPlaceholder withSpacingTop />
      <WebinarPageContent
        className={cx(isUpcomingOrLive && "upcoming")}
        data-category={webinar.category?.slug ?? "webinars"}
        data-dark-mode={shouldUseDarkMode}
      >
        <SEO
          title={webinar?.title ?? "Tines Webinar"}
          description={description}
          image={ogImage?.url}
          ogType="article"
          noindex
        />
        <Spacing size="lg" />

        {shouldShowLiveStream && webinar.liveStreamLinkYoutube ? (
          <WiderPageSection>
            <VideoFrameAndCommentPanelWrap
              className={cx(webinar.enableLiveCommenting && "hasCommentPanel")}
            >
              <VideoFrame>
                <VideoBlock
                  url={`${webinar.liveStreamLinkYoutube}?autoplay=1`}
                  title={webinar.title}
                  dataName="webinar-live-stream-video-frame"
                />
              </VideoFrame>
              {webinar.enableLiveCommenting && (
                <WebinarCommentingPanel webinar={webinar} />
              )}
            </VideoFrameAndCommentPanelWrap>
            {webinar.liveStreamLinkLinkedin && (
              <>
                <Spacing size=".5em" />
                <a
                  href={webinar.liveStreamLinkLinkedin}
                  className={css`
                    display: block;
                    text-decoration: none;
                    border-radius: 0.5em;
                    transition: 0.1s;
                    &:hover {
                      background-color: ${brandColorThemeVar(10)};
                    }
                  `}
                >
                  <InfoBox>
                    {webinar.enableLiveCommenting ? (
                      <>
                        You can also watch the webinar streaming on{" "}
                        <strong>LinkedIn Live</strong> →
                      </>
                    ) : (
                      <>
                        Have a question to ask the{" "}
                        {webinar.hosts?.length === 1 ? "host" : "hosts"}? Watch
                        streaming on <strong>LinkedIn Live</strong> →
                      </>
                    )}
                  </InfoBox>
                </a>
              </>
            )}
          </WiderPageSection>
        ) : (
          !!headerImage && (
            <PageSection>
              <ImageFrame
                className={webinarHeaderImageFrame}
                image={headerImage}
              />
            </PageSection>
          )
        )}

        <PageSection>
          <Spacing size="4.5em" />

          {(!headerImage ||
            (shouldShowLiveStream && webinar.liveStreamLinkYoutube)) && (
            <>
              <WebinarPageContentGrid
                shouldPadRightOnDesktopLg
                content={
                  <>
                    <CategoryList>
                      <Link to={Paths.webinars()}>
                        <AllCaps larger>
                          {webinar.category?.name ?? "Webinar"}
                        </AllCaps>
                      </Link>
                    </CategoryList>
                    <Title
                      className={cx(
                        webinar.title && webinar.title.length > 100 && "long"
                      )}
                    >
                      {webinar.title}
                    </Title>
                  </>
                }
              />

              <Spacing size="3em" />
            </>
          )}

          {shouldShowOnDemandVideo && (
            <>
              <WebinarPageContentGrid
                shouldPadRightOnDesktopLg
                content={<WebinarVideoBlock webinar={webinar} />}
                aside={aside}
              />
              <Spacing size="3em" />
            </>
          )}

          <WebinarPageContentGrid
            content={
              <>
                <TimeDisplaySet>
                  <TimeStartDisplay>
                    {!hasEnded && (
                      <svg
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill={brandColorThemeVar(500)}
                      >
                        <path d="M3.17188 24.9219C3.17188 25.4231 3.57686 25.8281 4.07812 25.8281H24.9219C25.4231 25.8281 25.8281 25.4231 25.8281 24.9219V13.0273H3.17188V24.9219ZM24.9219 5.21094H20.1641V3.39844C20.1641 3.27383 20.0621 3.17188 19.9375 3.17188H18.3516C18.227 3.17188 18.125 3.27383 18.125 3.39844V5.21094H10.875V3.39844C10.875 3.27383 10.773 3.17188 10.6484 3.17188H9.0625C8.93789 3.17188 8.83594 3.27383 8.83594 3.39844V5.21094H4.07812C3.57686 5.21094 3.17188 5.61592 3.17188 6.11719V11.1016H25.8281V6.11719C25.8281 5.61592 25.4231 5.21094 24.9219 5.21094Z" />
                      </svg>
                    )}
                    {hasEnded && (
                      <span>
                        Originally {isWebinar ? "broadcast" : "hosted"} on
                      </span>
                    )}
                    <DateTimeFormatter
                      className="WebinarTimeDisplay"
                      value={webinar.timeStart as string | null}
                      format={
                        !hasEnded
                          ? `dddd, MMMM D YYYY @ h:mm A`
                          : `D MMMM, YYYY.`
                      }
                    />
                  </TimeStartDisplay>

                  {isUpcoming && (
                    <TimezoneSelectWrapper>
                      <label>Timezone</label>
                      <GlobalTimezoneSelect />
                    </TimezoneSelectWrapper>
                  )}

                  {isUpcoming && webinar.timeStart && webinar.timeEnd && (
                    <DurationDisplay>
                      <strong>Duration:</strong>{" "}
                      {getHumanizedDuration(webinar.timeStart, webinar.timeEnd)}
                    </DurationDisplay>
                  )}
                </TimeDisplaySet>

                <Spacing size="3em" />

                {canRegister && (
                  <>
                    <WebinarRegistrationForm
                      webinar={webinar}
                      showPoweredByTinesTag={isBootcamp}
                    />
                    <Spacing size="3em" />
                  </>
                )}

                <StandardArticle>
                  {webinar.imageBeforeContent && (
                    <>
                      <ImageFrame
                        className={imageFrameBeforeContent}
                        image={webinar.imageBeforeContent}
                      />
                      <Spacing size="1.5em" />
                    </>
                  )}
                  <ReactMarkdown>{webinar.contentMarkdown ?? ""}</ReactMarkdown>
                </StandardArticle>
              </>
            }
            asidePositionOnMobile="start"
            aside={shouldShowOnDemandVideo ? null : aside}
          />
        </PageSection>
        <Spacing size="sectionPadding" />
        {hasEnded && (
          <PageSection>
            <NewsletterCTASection color={theme.name}>
              <h2>
                Missed{" "}
                <Serif>
                  this {webinar.category?.singularLowercase ?? "webinar"}?
                </Serif>
              </h2>
              <Spacing size=".5em" />
              <p>
                Sign up to our newsletter to get notified of our future{" "}
                {webinar.category?.pluralLowercase ?? "webinars"}, events and
                more.
              </p>
            </NewsletterCTASection>
          </PageSection>
        )}
        <Spacing size="sectionPadding" />
      </WebinarPageContent>
    </Page>
  );
};

export const webinarBySlugQuery = graphql`
  query WebinarBySlug($slug: String) {
    webinar: datoCmsWebinar(slug: { eq: $slug }) {
      id: originalId
      title
      slug
      timeStart
      timeEnd
      timezone
      liveStreamLinkYoutube
      enableLiveCommenting
      liveStreamLinkLinkedin
      recordedVideoLink
      excerpt
      imageBeforeContent {
        url
        width
        height
      }
      contentMarkdown
      colorTheme
      darkMode
      category {
        name
        slug
        color
        singularLowercase
        coverImage {
          url
          width
          height
        }
        headerImageWide {
          url
          width
          height
        }
      }
      customPreviewImage {
        url
        width
        height
      }
      coverImage {
        url
        width
        height
      }
      hosts {
        id: originalId
        name
        surname
        title
        photoIcon {
          url
          width
          height
        }
        organization {
          id: originalId
          name
        }
      }
    }
  }
`;

export default WebinarPageTemplate;
