import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import { w } from "../../utils/string.utils";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import brokenRobotIcon from "./illustrations/WorkbenchBrokenRobotIcon.svg";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPaths";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-rows: auto;
  grid-template-areas:
    "q"
    "h"
    "p1"
    "p2"
    "i";
  ${uptoDesktop} {
    text-align: center;
    max-width: 28em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }
  ${fromDesktop} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      "${w("h", 5)} ${w("q", 2)} ${w("i", 5)}"
      "${w("p1", 5)} ${w(".", 2)} ${w("p2", 5)}";
  }
  ${fromDesktopMd} {
    grid-template-areas:
      ". ${w("h", 4)} ${w("q", 2)} ${w("i", 4)} ."
      ". ${w("p1", 4)} ${w(".", 2)} ${w("p2", 4)} .";
  }
`;

const Heading = styled.h2`
  grid-area: h;
  font-size: 2.8rem;
  ${uptoDesktop} {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  ${fromTablet} {
    font-size: 3.2rem;
  }
  line-height: 1.2;
  letter-spacing: -0.015em;
  font-family: ${serif};
  font-weight: 400;
`;

const QuoteMarkBubble = styled.div`
  grid-area: q;
  position: relative;
  width: 72px;
  height: 72px;
  border: 1.5px solid ${colors.purple100};
  ${fromDesktop} {
    border-width: 2px;
  }
  background-color: ${colors.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  svg {
    display: block;
  }
`;

const P1 = styled.p`
  grid-area: p1;
`;

const P2 = styled.p`
  grid-area: p2;
`;

const IconWrap = styled.div`
  grid-area: i;
  margin-left: auto;
  ${uptoDesktop} {
    margin-right: auto;
  }
`;

const WorkbenchPageIntroSection = () => {
  return (
    <PageSection>
      <Grid>
        <QuoteMarkBubble>
          <ConnectorPathWaypoint
            id="workbench-page-quote-section"
            index={1}
            color={colors.purple100}
            top="0"
            left="50%"
          />
          <svg width="16" height="11" viewBox="0 0 16 11" fill="#7F69CE">
            <path d="M7.538 3.7437H6.80851C5.49544 3.7437 4.71733 4.7605 4.71733 6.7479V11H0V6.42437C0 2.35714 2.38298 0 6.32219 0H7.538V3.7437ZM16 3.7437H15.2705C13.9088 3.7437 13.1307 4.7605 13.1307 6.7479V11H8.41337V6.42437C8.41337 2.35714 10.7964 0 14.7842 0H16V3.7437Z" />
          </svg>
          <ConnectorPathWaypoint
            id="workbench-page-quote-section"
            index={2}
            color={colors.purple100}
            bottom="0"
            left="50%"
          />
        </QuoteMarkBubble>
        <Heading>
          Generative AI marks this decade’s most meaningful technology
          innovation.
        </Heading>
        <P1>
          It has the potential to change how <em>everyone</em> works. However,
          its use cases have been underwhelming as it's limited by access to
          proprietary data or take any action in real time.
        </P1>
        <P2>
          The result is a proliferation of siloed copilots and restrictive chat
          tools. They are isolated to the confines of a singular tool and force
          you to switch between systems.
        </P2>
        <IconWrap>
          <img src={brokenRobotIcon} />
        </IconWrap>
      </Grid>
    </PageSection>
  );
};

export default WorkbenchPageIntroSection;
