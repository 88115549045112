import { styled } from "@linaria/react";
import { cx } from "linaria";
import { fromPhoneLg } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { EventDirectoryItem } from "../../types/helper.types";
import EventDirectoryItemDates from "./EventDirectoryItemDates";
import EventDirectoryItemLocation from "./EventDirectoryItemLocation";
import Pill, { PillTagStrong } from "../basic/Pill";
import PlaceholderImageFrame from "../general/PlaceholderImageFrame";
import StaticImageFrame from "../basic/StaticImageFrame";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  item: EventDirectoryItem;
  isSelected?: boolean;
  landscape?: boolean;
  onSelect?: (item: EventDirectoryItem) => void;
};

const EventDirectoryItemEntryWrapper = styled.div`
  font-size: 1.4rem;
  cursor: pointer;
  overflow: hidden;
  overflow: clip;
  background-color: ${colorsV4.white};
  border: 2px solid ${colorsV4.canvas700};
  border-radius: ${rSize("radius")};
  transform: translateZ(0);
  &:hover {
    border-color: ${colorsV4.purple100};
  }
  &.landscape {
    ${fromPhoneLg} {
      display: flex;
    }
  }
`;

const Figure = styled.figure`
  padding: 0;
  margin: 0;
  .landscape & {
    ${fromPhoneLg} {
      width: 16em;
      flex: 0 0 auto;
    }
  }
`;
const Inner = styled.div`
  padding: 1em 1.5em;
  > * {
    + * {
      margin-top: 0.375em;
    }
  }
`;
const EventTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
`;
const EventDateTime = styled.p``;
const EvenLocation = styled.p`
  margin-bottom: -3px;
  color: ${withOpacity(colorsV4.warmBlack, 0.625)};
`;
const PillList = styled.div`
  margin-top: -0.25em;
  margin-left: -0.25em;
  > * {
    margin-top: 0.5em;
    margin-right: 0.5em;
  }
  ${PillTagStrong} {
    padding: 0.25em 0.75em;
  }
`;

const EventDirectoryItemEntry = (props: Props) => {
  const { type, id, place, categories } = props.item;
  const handleClick = () => {
    props.onSelect?.(props.item);
  };
  const isBootcamp = !!categories.find(c => c.slug === "bootcamp");
  const isVirtual =
    type === "webinar" || !!categories.find(c => c.slug === "virtual");
  return (
    <EventDirectoryItemEntryWrapper
      className={cx(
        props.isSelected && "selected",
        isVirtual && "virtual",
        props.landscape && "landscape"
      )}
      id={`${type}-${id}`}
      data-type={type}
      data-id={id}
      onClick={handleClick}
    >
      <Figure>
        {props.item.coverImageUrl ? (
          <StaticImageFrame
            src={props.item.coverImageUrl}
            aspectRatio="1200/630"
          />
        ) : (
          <PlaceholderImageFrame />
        )}
      </Figure>
      <Inner>
        <EventTitle>{props.item.name}</EventTitle>
        <EventDateTime>
          <EventDirectoryItemDates item={props.item} />
        </EventDateTime>
        {place && (
          <EvenLocation>
            <EventDirectoryItemLocation item={props.item} />
          </EvenLocation>
        )}
        {isVirtual && (
          <PillList>
            {isBootcamp ? (
              <Pill color="green">Bootcamp</Pill>
            ) : (
              type === "webinar" && <Pill>Webinar</Pill>
            )}
            <Pill color="orange">Virtual</Pill>
          </PillList>
        )}
      </Inner>
    </EventDirectoryItemEntryWrapper>
  );
};

export default EventDirectoryItemEntry;
