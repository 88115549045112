import ContentCardBase from "./_ContentCardBase";
import { css } from "linaria";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import {
  PrimaryColorName,
  brandColorThemeVar,
} from "../../styles/colors.styles";

const style = css`
  [data-path="/"] & {
    border-color: ${brandColorThemeVar(700)};
    &:hover {
      border-color: ${brandColorThemeVar(800)};
    }
  }
`;

const ContentCardDocs = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  return (
    <ContentCardBase
      className={style}
      link="/docs"
      color={(overrides?.color ?? "purple") as PrimaryColorName}
      variant="50"
      large={large}
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Learn"}</strong>
          </p>
          <h4>
            {overrides?.heading || "Learn to use Tines with our documentation"}
          </h4>
          {overrides?.description && (
            <ReactMarkdown>{overrides.description}</ReactMarkdown>
          )}
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Go to docs"}</strong>
        </p>
      </header>
      <figure>
        <Icon />
      </figure>
    </ContentCardBase>
  );
};

export default ContentCardDocs;

const Icon = () => (
  <svg width="105" height="85" viewBox="0 0 105 85" fill="none">
    <path
      d="M103.84 47.0579H54.0668L20.9639 83.9768H73.2479L103.84 47.0579Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
    />
    <path
      d="M96.9563 26.575H40.1142L4.87842 63.9534V83.9771H64.182L96.9563 46.5986C96.9563 46.5986 94.7612 44.2276 94.7612 36.6548C94.7612 29.0819 96.9563 26.575 96.9563 26.575Z"
      fill="#FFF5F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.1822 63.9534H4.87842L40.1142 26.575H96.9561L64.1822 63.9534Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.2228 51.8794C65.1381 51.8794 73.176 49.7119 73.176 47.0381C73.176 44.3643 65.1381 42.1968 55.2228 42.1968C45.3075 42.1968 37.2695 44.3643 37.2695 47.0381C37.2695 49.7119 45.3075 51.8794 55.2228 51.8794Z"
      fill="#32313B"
    />
    <path
      d="M4.8785 63.9529H64.1821C64.1821 63.9529 61.1746 67.1064 61.1746 73.9647C61.1746 80.8231 64.1821 83.9766 64.1821 83.9766H4.8785C4.8785 83.9766 1.06152 81.2269 1.06152 73.9647C1.06152 66.7026 4.8785 63.9529 4.8785 63.9529Z"
      fill="#FFF5F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.91381 67.4086H64.1821V63.9529H7.43372H4.8785L4.8779 63.9533C4.86922 63.9595 3.46887 64.9764 2.36687 67.3904C2.36404 67.3967 2.36102 67.4023 2.3582 67.4086H2.3586C1.64713 68.9741 1.06152 71.1223 1.06152 73.9647C1.06152 76.8072 1.64693 78.9553 2.3586 80.5209H2.3582C2.85867 81.9573 3.87937 83.0644 4.4561 83.6074C4.70845 83.8517 4.86983 83.9703 4.87689 83.9754L4.8785 83.9766H7.43372H64.1821V80.5209H4.91381C4.20234 78.9553 3.61674 76.8072 3.61674 73.9647C3.61674 71.1223 4.20234 68.9741 4.91381 67.4086Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.9563 30.0306L64.1821 67.4091V63.9534L96.9563 26.575V30.0306Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.9563 46.5983L64.1821 83.9767V80.521L96.9563 43.1426V46.5983Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.91377 67.4092C4.91377 67.4092 3.6167 70.3073 3.6167 73.9653C3.6167 77.6234 4.91377 80.5215 4.91377 80.5215H5.14634V67.4092H4.91377Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.7796 68.7204H4.89404L5.49921 67.4092H62.3847L61.7796 68.7204Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.5172 40.1663C66.5172 37.4824 59.5812 35.3066 51.0252 35.3066C42.469 35.3066 35.5332 37.4824 35.5332 40.1663C35.5332 41.0192 35.5332 45.4306 35.5332 46.2833C35.5332 48.9672 42.4692 51.143 51.0252 51.143C59.5812 51.143 66.5172 48.9672 66.5172 46.2833C66.5172 45.4306 66.5172 41.019 66.5172 40.1663Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0252 45.026C59.5812 45.026 66.5172 42.8502 66.5172 40.1663C66.5172 37.4824 59.5812 35.3066 51.0252 35.3066C42.4692 35.3066 35.5332 37.4824 35.5332 40.1663C35.5332 42.8502 42.4692 45.026 51.0252 45.026Z"
      fill="#F5D0E0"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.5847 42.5051C57.0873 42.5051 60.7373 41.3235 60.7373 39.8659C60.7373 38.4084 57.0873 37.2268 52.5847 37.2268C48.0822 37.2268 44.4321 38.4084 44.4321 39.8659C44.4321 41.3235 48.0822 42.5051 52.5847 42.5051Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0254 0.897461C43.0031 0.897461 36.5 7.40056 36.5 15.423C36.5 20.3372 38.9412 24.6809 42.6767 27.3089C43.3454 27.7793 44.8011 28.6864 44.8011 30.7708C44.8011 31.5807 44.8011 33.864 44.8011 33.864V36.6655C44.8011 39.4507 47.0589 41.7086 49.8441 41.7086H51.0254H52.2067C54.9918 41.7086 57.2497 39.4507 57.2497 36.6655V33.864C57.2497 33.864 57.2497 31.5807 57.2497 30.7708C57.2497 28.6864 58.7053 27.7793 59.3738 27.3089C63.1095 24.6809 65.5507 20.3372 65.5507 15.423C65.5509 7.40056 59.0476 0.897461 51.0254 0.897461Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0253 41.9106C49.2054 41.9106 43.2671 41.678 43.2671 38.683C43.2671 37.9588 43.7807 37.3718 44.4143 37.3718C44.9321 37.3718 45.3698 37.7638 45.5125 38.3026C46.024 38.6832 47.9615 39.2882 51.0253 39.2882C54.089 39.2882 56.0266 38.6832 56.5384 38.3026C56.681 37.7638 57.1187 37.3718 57.6365 37.3718C58.2701 37.3718 58.7837 37.9588 58.7837 38.683C58.7835 41.678 52.8452 41.9106 51.0253 41.9106Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
    />
    <path
      d="M51.0253 39.328C49.2054 39.328 43.2671 39.0955 43.2671 36.1005C43.2671 35.3763 43.7807 34.7893 44.4143 34.7893C44.9321 34.7893 45.3698 35.1812 45.5125 35.72C46.024 36.1007 47.9615 36.7057 51.0253 36.7057C54.089 36.7057 56.0266 36.1007 56.5384 35.72C56.681 35.1812 57.1187 34.7893 57.6365 34.7893C58.2701 34.7893 58.7837 35.3763 58.7837 36.1005C58.7835 39.0953 52.8452 39.328 51.0253 39.328Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
    />
    <path
      d="M51.0253 36.6547C49.2054 36.6547 43.2671 36.4221 43.2671 33.4272C43.2671 32.703 43.7807 32.116 44.4143 32.116C44.9321 32.116 45.3698 32.5079 45.5125 33.0467C46.024 33.4274 47.9615 34.0323 51.0253 34.0323C54.089 34.0323 56.0266 33.4274 56.5384 33.0467C56.681 32.5079 57.1187 32.116 57.6365 32.116C58.2701 32.116 58.7837 32.703 58.7837 33.4272C58.7835 36.4219 52.8452 36.6547 51.0253 36.6547Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
    />
    <path
      d="M65.4231 46.9193C66.3843 46.9193 67.1635 45.9484 67.1635 44.7508C67.1635 43.5531 66.3843 42.5823 65.4231 42.5823C64.4618 42.5823 63.6826 43.5531 63.6826 44.7508C63.6826 45.9484 64.4618 46.9193 65.4231 46.9193Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
    />
    <path
      d="M72.6582 64.4715L80.4305 56.0012"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M76.6341 59.703C76.081 59.6891 76.0594 59.6858 76.0062 59.1212C75.9483 58.5114 76.3955 57.97 77.0053 57.9121C77.1257 57.9002 87.2215 56.8532 87.3716 50.9365C87.4779 46.7438 81.6875 45.7138 80.5429 45.6847C78.5741 45.6347 66.7173 45.8653 66.5977 45.8675C65.9846 45.8848 65.4787 45.3926 65.467 44.78C65.4547 44.1674 65.9419 43.6609 66.5545 43.6492C67.0447 43.6395 78.5814 43.4154 80.5994 43.4666C83.4739 43.5394 89.7285 45.5361 89.5897 50.9928C89.505 54.3273 87.2762 56.9723 83.1445 58.6407C80.208 59.8268 76.8895 59.687 76.7689 59.6983C76.7237 59.7026 76.6787 59.704 76.6341 59.703Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
    />
    <path
      d="M73.6445 63.396C74.3947 62.5786 74.7169 59.1669 76.5572 57.9219C77.4357 57.3274 80.0736 56.6492 81.5048 54.958"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M76.039 58.5325L76.9133 59.9445L74.627 62.2306L76.039 58.5325Z"
      fill="#32313B"
    />
    <path
      d="M71.7471 46.7253H78.6756L79.8714 45.7568H71.8669L71.7471 46.7253Z"
      fill="#32313B"
    />
    <path
      d="M62.1201 68.8884L64.2047 67.6781L62.3846 67.4092L62.1201 68.8884Z"
      fill="#32313B"
    />
    <path
      d="M50.9586 33.9226C54.2637 33.9226 56.9431 33.2001 56.9431 32.3088C56.9431 31.4176 54.2637 30.6951 50.9586 30.6951C47.6535 30.6951 44.9741 31.4176 44.9741 32.3088C44.9741 33.2001 47.6535 33.9226 50.9586 33.9226Z"
      stroke="#32313B"
      strokeWidth="1.00861"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
