import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  DatoCmsArticle,
  DatoCmsExternalResource,
} from "../../../graphql-types";
import ImageFrame from "../basic/ImageFrame";
import Pill from "../basic/Pill";
import PlaceholderImageFrame from "./PlaceholderImageFrame";
import { externalLinkAttr } from "../../constants/externalLinks.constants";

type Props = {
  record: DatoCmsExternalResource;
  featured?: boolean;
  showFeaturedTag?: boolean;
};

const EntryLink = styled.a`
  text-decoration: none;
  width: 100%;
  > * {
    + * {
      margin-top: 2rem;
    }
  }
`;

const Inner = styled.div<Props>`
  position: relative;
  > * + * {
    ${uptoTablet} {
      margin-top: 2rem;
    }
    ${fromTablet} {
      margin-top: ${p => (p.featured ? 0 : "2rem")};
    }
  }
`;

const featuredArticleEntryLink = css`
  ${fromTablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "header image";
    grid-gap: ${rSize("gap")};
    align-items: start;
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "header image image";
  }
`;

const CoverImage = styled.div<Props>`
  grid-area: image;
  overflow: hidden;
  border-radius: 1em;
  ${fromTablet} {
    border-radius: ${p => (p.featured ? "1.5em" : "1em")};
  }
  transform: translateZ(0);
`;

const Header = styled.header`
  grid-area: header;
  > * + * {
    margin-top: 1rem;
  }
`;
const Heading = styled.h3<{ featured?: boolean }>`
  font-size: 2.2rem;
  line-height: ${p => (p.featured ? 1.15 : 1.2)};
  font-weight: 600;
  ${fromDesktop} {
    font-size: ${p => (p.featured ? "2.8rem" : "2.2rem")};
  }
  ${fromDesktopMl} {
    font-size: ${p => (p.featured ? "3.2rem" : "2.4rem")};
  }
`;
const Excerpt = styled.div`
  line-height: 1.4;
  code {
    font-family: ${font("monospace")} !important;
    color: ${colorsV4.orange700};
    font-size: 95%;
    background-color: ${withOpacity(colorsV4.orange, 0.1)};
    padding: 0.1em 0.4em;
    border-radius: 0.35em;
  }
`;
const AuthorLine = styled.p`
  strong {
    font-weight: 600;
  }
`;

const ExternalResourceCard = (props: Props) => {
  const externalLink = props.record.externalLink || "";
  const { title = externalLink, image } = props.record;
  return (
    <EntryLink href={externalLink} {...externalLinkAttr}>
      <Inner
        {...props}
        className={props.featured ? featuredArticleEntryLink : undefined}
      >
        <CoverImage {...props}>
          {image ? (
            <ImageFrame
              image={image}
              lazy={!props.featured}
              key={props.featured ? externalLink : undefined}
              intrinsicWidth={1200}
              intrinsicHeight={630}
              aspectRatio="1200 / 630"
              cover
            />
          ) : (
            <PlaceholderImageFrame />
          )}
        </CoverImage>
        <Header>
          {props.featured && props.showFeaturedTag && <Pill>Featured</Pill>}
          <Heading featured={props.featured}>{title}</Heading>
          {!!(props.record as DatoCmsArticle).authors?.length && (
            <AuthorLine>
              Written by{" "}
              <strong>
                {(props.record as DatoCmsArticle)
                  .authors!.map(a => `${a?.name} ${a?.surname}`)
                  .join(", ")}
              </strong>
            </AuthorLine>
          )}
          {props.record.description && (
            <Excerpt>{props.record.description}</Excerpt>
          )}
        </Header>
      </Inner>
    </EntryLink>
  );
};

export default ExternalResourceCard;
