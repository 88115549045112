import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktopMl,
  fromPhoneLg,
  fromTablet,
  fromTabletMd,
  onlyPhones,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import PageSection from "../reusableSections/PageSection";
import { colors } from "../../styles/colors.styles";
import { cx } from "linaria";
import { useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import demoVideoPreview from "../../../static/demoVideos/tines-product-demo-preview-1652w.mp4";
import demoVideo from "../../../static/demoVideos/tines-product-demo-1652w.mp4";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { GridCanvas } from "../decorations/GridCanvas";
import Spacing from "../layout/Spacing";
import { AIPageHeading2 } from "./_AIPageComponents";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPaths";

const Inner = styled.div`
  position: relative;
  ${uptoTabletMd} {
    margin-top: -3em;
  }
  padding-top: 3em;
  ${fromTabletMd} {
    padding-top: 0;
  }
`;

const Contents = styled.div`
  position: relative;
`;

const Header = styled.header`
  p {
    margin-left: auto;
    margin-right: auto;
  }
`;

const ProductDemoVideoFramePositioner = styled.div`
  ${fromTabletMd} {
    margin-left: ${getWidthPxInMaxGrid(1, true)};
    margin-right: ${getWidthPxInMaxGrid(1, true)};
  }
  iframe {
    border: 0.8rem solid ${colors.yellow200};
    border-radius: ${rSize("radius")};
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
`;

const GridCanvasWrap = styled.div`
  position: absolute;
  top: 0;
  left: -0.5em;
  right: -0.5em;
  bottom: 0;
  ${fromPhoneLg} {
    left: -1em;
    right: -1em;
  }
  ${fromTabletMd} {
    top: 33%;
    left: 0;
    right: 0;
  }
  background-color: ${colors.lightest};
  border: 1px solid ${colors.yellow300};
  border-radius: ${rSize("radius")};
  ${fromDesktopMl} {
    margin-left: ${rSize("gap", -1)};
    margin-right: ${rSize("gap", -1)};
  }
`;
export const AIPageDemoVideoSection = () => (
  <PageSection centered relative>
    <ConnectorPathWaypoint
      id="ai-page-demo-video-section"
      index={0}
      color={colors.yellow100}
      top={rSize("pageMargin", -0.5)}
      left="50%"
    />
    <ConnectorPathWaypoint
      id="ai-page-demo-video-section"
      index={1}
      color={colors.yellow200}
      bottom={rSize("pageMargin", -0.5)}
      left="50%"
    />
    <Inner>
      <GridCanvasWrap>
        <GridCanvas color={colors.yellow200} parallax animateIn />
      </GridCanvasWrap>
      <Contents>
        <Header>
          <AIPageHeading2 lighter>See it in action</AIPageHeading2>
          <Spacing />
          <p>
            Learn more about AI in Tines from our Head of Product, Stephen
            O’Brien.
          </p>
        </Header>
        <Spacing size="xxl" />
        <ProductDemoVideoFramePositioner>
          <iframe
            src="https://www.youtube.com/embed/u5b6EwQbCQk?si=2cGVrQO6WFLS8MVl"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </ProductDemoVideoFramePositioner>
        <Spacing size="sectionPadding" />
      </Contents>
    </Inner>
  </PageSection>
);

const VideoFrame = styled.div`
  position: relative;
  border-radius: 12px;
  border: 6px solid ${colors.yellow200};
  background-color: ${colors.yellow50};
  overflow: hidden;
  ${fromTabletMd} {
    border-radius: 24px;
    border-width: 8px;
  }
  z-index: 1;
  transform: translateZ(0);
  background-color: ${colors.white};
  video {
    display: block;
    background-color: ${colors.white};
    width: calc(100% + 2px);
    margin: -1px;
  }
`;

const DemoVideo = styled.video`
  position: absolute;
  top: -1px;
  left: 0px;
  bottom: -2px;
  right: -1px;
  @keyframes demoVideoEnter {
    from {
      transform: scale(0.9);
      filter: brightness(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      filter: brightness(1);
      opacity: 1;
    }
  }
  animation: demoVideoEnter 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 1;
  border-radius: 6px;
  ${fromTabletMd} {
    border-radius: 12px;
  }
`;

const PreviewVideoWrap = styled.div`
  background-color: ${colors.white};
  transition: opacity 0.75s, transform 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
  @keyframes previewVideoEnter {
    from {
      transform: scale(1.2);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  animation: previewVideoEnter 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  .hasStartedPlaying & {
    transform: scale(1.2);
    opacity: 0;
    pointer-events: none;
  }
`;

const PreviewVideo = styled.video`
  opacity: 0.9;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
`;

const PlayButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.yellow300};
  border: 1px solid ${colors.yellow500};
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  height: 100%;
  svg {
    display: block;
  }
  transform: scale(0.9);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const PlayButtonPulse = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.yellow500};
  border: 1px solid ${colors.yellow300};
  border-radius: 50%;
  pointer-events: none;
  opacity: 0;
`;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: 0;
  border: 0;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.2rem;
  height: 7.2rem;
  ${fromTablet} {
    top: 48%;
    width: 8.2rem;
    height: 8.2rem;
  }
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
  ${PlayButtonInner} {
    transform: scale(0.5);
  }
  &.visible {
    opacity: 1;
    ${PlayButtonPulse} {
      @keyframes PlayButtonPulseAnimation {
        from {
          opacity: 0.2;
          transform: scale(1);
        }
        to {
          opacity: 0;
          transform: scale(2.5);
        }
      }
      animation: PlayButtonPulseAnimation 1.8s
        cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
      animation-delay: 1.5s;
    }
    ${PlayButtonInner} {
      transform: scale(1);
    }
  }
  .hasStartedPlaying & {
    opacity: 0;
    ${PlayButtonInner} {
      transform: scale(0.9);
    }
  }
  svg {
    ${onlyPhones} {
      width: 28px;
    }
  }
  &:hover {
    ${PlayButtonInner} {
      background-color: ${colors.yellow50};
      transition: transform 0.2s;
      transform: scale(1.05);
    }
    svg {
      fill: ${colors.yellow300};
    }
  }
`;

export const DemoVideoFrame = () => {
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const [playButtonInView, setPlayButtonInView] = useState(false);
  const videoFrameRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const playButtonRef = useRef<HTMLButtonElement>(null);
  const play = () => {
    setHasStartedPlaying(true);
    setTimeout(() => {
      const video = videoRef.current;
      if (video) {
        for (let i = 0; i < video.textTracks.length; i++) {
          video.textTracks[i].mode = "hidden";
        }
        try {
          video.play();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
      }
    }, 400);
  };
  useOnMount(() => {
    const videoFrameObserver = new IntersectionObserver(entries => {
      if (!entries[0].isIntersecting) {
        if (videoRef.current) {
          if (!videoRef.current.paused) {
            videoRef.current?.pause();
          }
        }
      }
    });
    if (videoFrameRef.current)
      videoFrameObserver.observe(videoFrameRef.current);
    const playButtonObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setTimeout(() => {
          setPlayButtonInView(true);
        }, 1000);
        playButtonObserver.disconnect();
      }
    });
    if (playButtonRef.current)
      playButtonObserver.observe(playButtonRef.current);
    return () => {
      videoFrameObserver.disconnect();
      playButtonObserver.disconnect();
    };
  });
  const reset = () => {
    setHasStartedPlaying(false);
    setPlayButtonInView(false);
    setTimeout(() => {
      setPlayButtonInView(true);
    }, 100);
  };

  const videoAspectRatio = "1652/1020";

  return (
    <VideoFrame
      className={cx(hasStartedPlaying && "hasStartedPlaying")}
      ref={videoFrameRef}
      style={{
        aspectRatio: `${1652 + 8 * 2}/${1020 + 8 * 2}`,
      }}
    >
      {hasStartedPlaying && (
        <DemoVideo
          controls
          preload="metadata"
          ref={videoRef}
          onEnded={reset}
          style={{
            aspectRatio: videoAspectRatio,
          }}
        >
          <source src={demoVideo} type="video/mp4" />
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={"/demoVideos/tines-product-demo-subtitles.vtt"}
            default
          />
        </DemoVideo>
      )}
      <PreviewVideoWrap onClick={play}>
        <PreviewVideo
          src={demoVideoPreview}
          autoPlay
          playsInline
          loop
          muted
          style={{
            aspectRatio: videoAspectRatio,
          }}
        />
      </PreviewVideoWrap>
      <PlayButton
        onClick={play}
        ref={playButtonRef}
        className={cx(playButtonInView && "visible")}
      >
        <PlayButtonPulse />
        <PlayButtonPulse />
        <PlayButtonInner>
          <svg
            width="33"
            height="31"
            viewBox="0 0 33 31"
            fill={colors.yellow50}
          >
            <path
              d="M8.58399 30.077L31.582 16.799C32.582 16.2217 32.582 14.7783 31.582 14.201L8.58399 0.923033C7.58399 0.345682 6.33399 1.06737 6.33399 2.22207L6.33399 28.7779C6.33399 29.9326 7.58399 30.6543 8.58399 30.077Z"
              stroke={colors.yellow500}
            />
          </svg>
        </PlayButtonInner>
      </PlayButton>
    </VideoFrame>
  );
};
