import Pill from "../../basic/Pill";
import ReportCard from "../ReportCard";
import ReportSection from "../ReportSection";
import { Serif } from "../../typography/Serif";
import SpotIllustration from "../../illustrations/SpotIllustration";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import ReportSummaryCard from "../ReportSummaryCard";
import ReportCaseStudyCard from "../ReportCaseStudyCard";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import caseStudyImage from "../../../../static/images/reports/tines-sophos.png";
import NoWrap, { NoBreak } from "../../typography/Nowrap";
import { Columned } from "../../layout/Columned";
import { Separator } from "../../utilities/Hr";
import Spacing from "../../layout/Spacing";

const NoCodePlaybookChapter3 = () => {
  return (
    <ReportSection
      id="five-common-misconceptions"
      themeName="pink"
      smallerSectionHeading
      header={
        <>
          <div>
            <SpotIllustration name="brokenEgg" />
          </div>
          <Pill color="pink">Part Three</Pill>
          <h2>
            Five common misconceptions about
            <br />
            <Serif>no-code security automation</Serif>
          </h2>
          <p>
            Five biggest misconceptions security teams have about the{" "}
            <NoWrap>no-code automation</NoWrap>.
          </p>
        </>
      }
    >
      <ReportCard largerHeading>
        <Pill color="pink">Misconception 1</Pill>
        <h3>
          <Serif>“I could just write a script to do this.”</Serif>
        </h3>
        <h4>No-code truth</h4>
        <Columned>
          <p>
            You could just write a script — if you know how to. But security
            practitioners often don't have that skill, meaning they have to
            outsource their automation creation to others. Additionally, the
            easy part with code is writing it the first time. The hard part is
            the deployment, security upgrades, maintenance, versioning, and
            downtime that comes afterward; this is especially challenging when
            associated team members move on to other organizations. No-code
            automation keeps workflow automation within the security team, and
            is as easy as dragging-and-dropping actions into a storyline.
            Technical users who do know code can instead focus on the output of
            the overall workflow — rather than the process of coding it.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="pink">Misconception 2</Pill>
        <h3>
          <Serif>“This isn’t powerful enough for our workflow.”</Serif>
        </h3>
        <h4>No-code truth</h4>
        <Columned>
          <p>
            No-code automation platforms provide the building blocks — the right
            building blocks — to security teams, who can then architect the
            workflows that they need, from simple login confirmations to
            complex, <NoBreak>all-encompassing</NoBreak> vulnerability
            management.
          </p>
          <p>
            Just like how you can build almost anything out of a small number of
            LEGO<sup>®</sup> bricks, the same is true for no-code automation;
            there's no ceiling to how complex a workflow or how many steps can
            be automated — the only limit really is imagination.{" "}
          </p>
          <p>
            Additionally, with{" "}
            <a
              href="https://techbeacon.com/security/4-key-challenges-next-gen-security-operations-centers"
              {...externalLinkAttr}
            >
              nearly a quarter of organizations
            </a>{" "}
            saying they’re bogged down in menial tasks, and with{" "}
            <a
              href="https://www.sirp.io/wp-content/uploads/2020/07/SIRP_2020_Security_Analysts_Survey.pdf"
              {...externalLinkAttr}
            >
              half of analysts
            </a>{" "}
            indicating that time spent on mundane work is what they dislike
            about their jobs, automation is more necessary than ever.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="pink">Misconception 3</Pill>
        <h3>
          <Serif>“Automation means replacing team members.”</Serif>
        </h3>
        <h4>No-code truth</h4>
        <Columned>
          <p>From what I've seen, this very rarely happens in practice.</p>
          <p>
            First, there is always more work to do and bigger problems to solve;
            cybersecurity is an ever-changing field that requires constant
            attention and improvement. Those who are automating their tasks then
            gain that as a new skill, and can continue to make those processes
            more efficient and effective.
          </p>
          <p>
            What also happens is that when analysts begin to automate their
            repetitive, manual tasks, it frees them up to focus their skills and
            attention on high-impact work like improving the organization's
            security approach, rolling out new technology, or providing outreach
            and training to other teams.
          </p>
          <p>
            Additionally, because of no-code automation’s ease of use, analysts
            can maintain and evolve their own workflows, which is especially
            beneficial as processes, tools and threats continue to evolve.{" "}
          </p>
          <p>
            Automation simply unlocks the potential of team members – and team
            members who are engaged in and excited by their work stick around.
          </p>
          <p>
            Security teams are already short-staffed as it is — our recent
            report on the “
            <a
              href="https://www.tines.com/reports/voice-of-the-soc-analyst"
              {...externalLinkAttr}
            >
              Voice of the SOC Analyst
            </a>
            ” found that the number one thing preventing teams from doing their
            best work is “lack of people” — so taking menial tasks off of an
            analyst’s plate frees them up to fill the gaps of understaffing.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="pink">Misconception 4</Pill>
        <h3>
          <Serif>
            “Automation will implement rash decisions during remediation.”
          </Serif>
        </h3>
        <h4>No-code truth</h4>
        <Columned>
          <p>
            Automation isn't necessarily all or nothing, as many may assume.
            Instead, good automation platforms make it easy to put a human in
            the loop for important decisions.
          </p>
          <p>
            Instead of automating black and white remediation actions like
            blocking an account after a suspicious login, ask the affected user
            or an analyst for their input first. This can easily be facilitated
            through automated Slack messages or chatbots — "Did you recently log
            in from a certain location?" — and automating the rest of the
            workflow based on their response — whether it was, "Yes, it was me"
            or "No it wasn't”.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <Pill color="pink">Misconception 5</Pill>
        <h3>
          <Serif>
            “No-code automation platforms should have built-in case management.”
          </Serif>
        </h3>
        <h4>No-code truth</h4>
        <Columned>
          <p>
            Many security teams have used bundled SOAR platforms that include
            automation as a feature, and also offer other organizational tools
            like case management or chat. But we've reached a point where teams
            are turning away from multi-product platforms towards laser-focused
            tools that provide best-in-class solutions, like JIRA, Slack, and
            others.
          </p>
          <p>
            Why would it be any different with a no-code platform that solely
            focuses on automation? The power of best-in-class workflow
            automation platforms is that they can fuse all the individual tools
            an organization uses — custom and off-the-shelf — to maximize its
            data and resources.
          </p>
        </Columned>
      </ReportCard>

      <ReportSummaryCard
        backgroundColor={colorsV4.pink700}
        textColor={colorsV4.white}
      >
        <blockquote>
          <p>
            Being mindful of these common misconceptions can streamline your
            security team’s ability to get a high-impact no-code automation
            program up and running. Next, we’ll explore the best practices that
            can improve your chances of success.
          </p>
        </blockquote>
      </ReportSummaryCard>

      <ReportCaseStudyCard color="pink" imageSrc={caseStudyImage}>
        <h2>
          How Sophos Uses <NoBreak>No-Code</NoBreak> Security Automation to Keep
          Employees and Data Around the World Safe
        </h2>
        <Separator />
        <Spacing size="0.25em" />
        <h3>The challenge</h3>
        <Columned>
          <p>
            The internal security team at Sophos uses a wide range of products
            to deliver a comprehensive service that keeps the company and its
            employees safe from cybersecurity threats. Making all of these
            products work together can be a huge task in such a large
            organization. This means they needed a way of simplifying the
            creation of complex workflows that orchestrate between many
            products.
          </p>
        </Columned>
        <h3>The impact of Tines no-code security automation platform</h3>
        <Columned>
          <p>
            According to Tom Sage, Senior Security Engineer, Sophos: "Tines’s
            component-based approach is a major part of its appeal to us. It
            means we don’t have to reinvent the wheel every time we want to
            automate something new. Components we’ve already built can be put to
            use in new contexts multiple times with no additional effort.
          </p>
          <p>
            “In all, we’ve automated more than 20 use cases in Tines. These
            include push notifications that check with users that they created
            an account. For example, if a user account goes from ‘disabled’ to
            ‘enabled’ we can automatically prompt the user to confirm they
            authorized this. And we use Tines to correlate company admin
            accounts to email accounts to provide two-factor authentication
            prompts to the right users at the right time.
          </p>
          <p>
            “We also have workflows that correlate data from different services
            to alert us when a user is added to a privileged group that gives
            them lots of rights on the network, or when a new host is discovered
            on the perimeter of the network. And if there’s something we can’t
            do in Tines, their responsive, helpful support team is there to
            quickly set us on the right path. That’s not something we could say
            about the previous automation product we used!”
          </p>
        </Columned>
      </ReportCaseStudyCard>
    </ReportSection>
  );
};

export default NoCodePlaybookChapter3;
