import { styled } from "@linaria/react";
import {
  DatoCmsBeginnerStoriesSection,
  DatoCmsCollectionsGridSection,
  DatoCmsCustomerFocusSection,
  DatoCmsHowItWorksSection,
  DatoCmsLatestStoriesSection,
  DatoCmsLibraryCollection,
  DatoCmsSpotlightStorySection,
  DatoCmsStoryGridSection,
  DatoCmsToolsSection,
  DatoCmsUnionForDatoCmsLibraryCollectionPageSections,
  DatoCmsYdwwtWinners2023Section,
} from "../../../../graphql-types";
import HowItWorksSection from "./HowItWorksSection";
import { StoryGridSection } from "./StoryGridSection";
import { font } from "../../../styles/fonts.styles";
import CollectionsGridSection from "./CollectionsGridSection";
import SubmitStoryCtaSection from "./SubmitStoryCtaSection";
import ToolsSection from "./ToolsSection";
import SpotlightStorySection from "./SpotlightStorySection";
import { BeginnerStoriesSection } from "./BeginnerStoriesSection";
import { StoryDescriptor } from "../../../types/helper.types";
import { LatestStoriesSection } from "./LatestStoriesSection";
import { CustomerFocusSection } from "./CustomerFocusSection";
import { YdwwtWinners2023Section } from "./YdwwtWinners2023Section";
import { colors } from "../../../styles/colors.styles";

export type LibraryCollectionPageSectionRecord =
  DatoCmsUnionForDatoCmsLibraryCollectionPageSections & {
    __typename: string;
  };

type Props = {
  collection: DatoCmsLibraryCollection;
  section: LibraryCollectionPageSectionRecord;
  stories: StoryDescriptor[];
};

const Code = styled.div`
  background-color: ${colors.green100};
  padding: 1em;
  border-radius: 0.5em;
  font-size: 1.3rem;
  margin-top: 1em;
  font-family: ${font("monospace")};
`;

const LibraryCollectionPageSection = ({
  collection,
  section,
  stories,
}: Props) => {
  return (
    <section>
      {(() => {
        switch (true) {
          case section.__typename.includes("StoryGridSection"):
            return (
              <StoryGridSection section={section as DatoCmsStoryGridSection} />
            );
          case section.__typename.includes("SpotlightStorySection"):
            return (
              <SpotlightStorySection
                section={section as DatoCmsSpotlightStorySection}
              />
            );
          case section.__typename.includes("BeginnerStoriesSection"):
            return (
              <BeginnerStoriesSection
                section={section as DatoCmsBeginnerStoriesSection}
                stories={stories}
              />
            );
          case section.__typename.includes("LatestStoriesSection"):
            return (
              <LatestStoriesSection
                section={section as DatoCmsLatestStoriesSection}
                stories={stories}
              />
            );
          case section.__typename.includes("HowItWorksSection"):
            return (
              <HowItWorksSection
                section={section as DatoCmsHowItWorksSection}
              />
            );
          case section.__typename.includes("CollectionsGridSection"):
            return (
              <CollectionsGridSection
                section={section as DatoCmsCollectionsGridSection}
              />
            );
          case section.__typename.includes("SubmitStoryCtaSection"):
            return (
              <SubmitStoryCtaSection
              // section={section as DatoCmsSubmitStoryCtaSection}
              />
            );
          case section.__typename.includes("CustomerFocusSection"):
            return (
              <CustomerFocusSection
                collection={collection}
                section={section as DatoCmsCustomerFocusSection}
              />
            );
          case section.__typename.includes("ToolsSection"):
            return <ToolsSection section={section as DatoCmsToolsSection} />;
          case section.__typename.includes("YdwwtWinners2023Section"):
            return (
              <YdwwtWinners2023Section
                section={section as DatoCmsYdwwtWinners2023Section}
              />
            );
          default:
            return <Code>{JSON.stringify(section)}</Code>;
        }
      })()}
    </section>
  );
};

export default LibraryCollectionPageSection;
