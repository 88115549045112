import { styled } from "@linaria/react";
import HeroSection, {
  HeroSectionDescription,
} from "../components/sections/HeroSection";
import SEO from "../components/utilities/SEO";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import Spacing from "../components/layout/Spacing";
import { SolutionPageHeadlineWrap } from "../components/solutions/SolutionPageHero";
import AllCaps from "../components/typography/AllCaps";
import BookADemoButton from "../components/utilities/BookADemoButton";
import LogoMars20h from "../components/thirdPartyLogos/LogoMars20h";
import { LogoBEYONCyber } from "../components/thirdPartyLogos/LogoBEYONCyber";
import { LogoOakRidge } from "../components/thirdPartyLogos/LogoOakRidge";
import { LogoExperian } from "../components/thirdPartyLogos/LogoExperian";
import { css } from "linaria";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import { ProServIllustrationBase } from "../components/professionalServices/illustrations/ProServIllustrationBase";
import { ProServIlloTopLeftFloatingElements } from "../components/professionalServices/illustrations/ProServIlloTopLeftFloatingElements";
import { ProServIlloTopRightFloatingElements } from "../components/professionalServices/illustrations/ProServIlloTopRightFloatingElements";
import { ProServFiveStagesSection } from "../components/professionalServices/ProServFiveStagesSection";
import { ProServCustomerLoveSection } from "../components/professionalServices/ProServCustomerLoveSection";
import { ProServWhyTinesSection } from "../components/professionalServices/ProServWhyTinesSection";
import { ProServMeetTheTeamSection } from "../components/professionalServices/ProServMeetTheTeamSection";
import { ProServPartnersSection } from "../components/professionalServices/ProServPartnersSection";
import { ProServFAQSection } from "../components/professionalServices/ProServFAQSection";
import { graphql } from "gatsby";
import {
  DatoCmsProfessionalServicesPage,
  ProfessionalServicesPageQuery,
} from "../../graphql-types";
import ogImage from "../../static/images/og/tines-professional-services.png";
import {
  fromTablet,
  onlyPhones,
  uptoDesktopMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { NoLetterSpacing } from "../components/typography/NoLetterSpacing";
import { NoBreak } from "../components/typography/Nowrap";

const HeaderWrap = styled.div`
  position: relative;
  h1 {
    max-width: 14em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const HeaderInnerWrap = styled.div`
  position: relative;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;
`;

const LogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${onlyPhones} {
    flex-wrap: wrap;
    max-width: 325px;
    margin-left: auto;
    margin-right: auto;
  }
  > * {
    width: auto;
    ${onlyPhones} {
      height: 22px;
      margin: 0.5em 0.75em;
    }
    ${fromTablet} {
      margin-left: 1em;
      margin-right: 1em;
    }
    &:first-child {
      height: 18px;
      ${fromTablet} {
        height: 24px;
      }
      width: auto;
    }
  }
`;

const IllustrationWrap = styled.div`
  position: relative;
  z-index: 1;
  ${onlyPhones} {
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
  }
  svg {
    height: auto;
    ${onlyPhones} {
      width: 150vw;
      max-width: unset;
      margin-left: -25vw;
    }
    ${fromTablet} {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const ProServIlloTopLeftFloatingElementsPositioner = styled.div`
  ${uptoDesktopMd} {
    display: none;
  }
  position: absolute;
  bottom: 0;
  right: 100%;
`;

const ProServIlloTopRightFloatingElementsPositioner = styled.div`
  ${uptoDesktopMd} {
    display: none;
  }
  position: absolute;
  bottom: 0;
  left: 100%;
`;

const PageProfessionalServices: PageComponent<{
  data: ProfessionalServicesPageQuery;
}> = props => {
  const page = props.data.record as DatoCmsProfessionalServicesPage;
  useOnMount(() => {
    reportIntent("Viewed professional services page");
  });
  const description =
    "Our team is with you every step of the way. For when you need additional support, our services team is here to accelerate and increase the value you get from Tines.";

  return (
    <Page
      {...props}
      textColor={colors.purple800}
      navThemeColor="purple"
      clipOverflow
    >
      <SEO
        title={page.seoTitle || "Professional services"}
        description={page.seoDescription || description}
        image={ogImage}
      />

      <HeroSection wider contentRelative>
        <HeaderWrap>
          <HeaderInnerWrap>
            <AllCaps as="p">Professional Services</AllCaps>
            <Spacing size="1.5em" />
            <SolutionPageHeadlineWrap>
              <h1>
                Realize your{" "}
                <NoBreak>
                  work<NoLetterSpacing>fl</NoLetterSpacing>ow
                </NoBreak>
                 goals faster
              </h1>
              <HeroSectionDescription
                centered
                className={css`
                  max-width: 32em !important;
                `}
              >
                {description}
              </HeroSectionDescription>
              <Spacing size="md" />
              <LogoRow>
                <LogoMars20h />
                <LogoBEYONCyber />
                <LogoOakRidge />
                {/* <LogoKingston /> */}
                <LogoExperian />
              </LogoRow>
              <Spacing size="md" />
              <BookADemoButton darker />
              <Spacing size="md" />
            </SolutionPageHeadlineWrap>

            <ProServIlloTopLeftFloatingElementsPositioner>
              <ProServIlloTopLeftFloatingElements />
            </ProServIlloTopLeftFloatingElementsPositioner>

            <ProServIlloTopRightFloatingElementsPositioner>
              <ProServIlloTopRightFloatingElements />
            </ProServIlloTopRightFloatingElementsPositioner>
          </HeaderInnerWrap>
        </HeaderWrap>

        <IllustrationWrap>
          <ProServIllustrationBase />
        </IllustrationWrap>
      </HeroSection>

      <ProServFiveStagesSection />
      <Spacing size="sectionMargin" />

      <ProServCustomerLoveSection page={page} />
      <Spacing size="sectionMargin" />

      <ProServWhyTinesSection />
      <Spacing size="sectionMargin" />

      <ProServMeetTheTeamSection page={page} />
      <Spacing size="sectionMargin" />

      <ProServPartnersSection />
      <Spacing size="sectionMargin" />

      <ProServFAQSection page={page} questionColor={colors.purple600} />
      <Spacing size="sectionMargin" />

      <ExplosionCTASection />
    </Page>
  );
};

export default PageProfessionalServices;

export const professionalServicesPageQuery = graphql`
  query ProfessionalServicesPage {
    record: datoCmsProfessionalServicesPage {
      seoTitle
      seoDescription
      featuredCaseStudies {
        id: originalId
        slug
        title
        color
        customer {
          logoDarkGray {
            url
            width
            height
          }
          logoWhite {
            url
            width
            height
          }
        }
      }
      teamMembers {
        id: originalId
        fullName
        linkedin
        team
        photoIcon {
          url
          width
          height
        }
      }
      faqs {
        id: originalId
        answer {
          blocks
          value
        }
        question {
          blocks
          value
        }
      }
    }
  }
`;
