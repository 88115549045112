import { css } from "linaria";
import Button from "../../components/forms/Button";
import ComboFontHeading from "../../components/typography/ComboFontHeading";
import Pill from "../../components/basic/Pill";
import ReportSectionFrame from "../../components/reports/ReportSectionFrame";
import HeroSectionWithInset, {
  ColumnedHeroSectionContent,
} from "../../components/reusableSections/HeroSectionWithInset";
import SEO from "../../components/utilities/SEO";
import Spacing from "../../components/layout/Spacing";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import { fluidFontSize } from "../../styles/helpers.styles";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import FromTabletDiv from "../../components/mediaQueries/FromTablet";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import MHNav from "../../components/reports/mental-health/MHNav";
import MHIntroSection from "../../components/reports/mental-health/chapters/MHIntroSection";
import MHKeyFindingsSection from "../../components/reports/mental-health/chapters/MHKeyFindingsSection";
import MHMethodologySection from "../../components/reports/mental-health/chapters/MHMethodologySection";
import { styled } from "@linaria/react";
import MHChapter1 from "../../components/reports/mental-health/chapters/MHChapter1";
import MHChapter2 from "../../components/reports/mental-health/chapters/MHChapter2";
import MHChapter3 from "../../components/reports/mental-health/chapters/MHChapter3";
import MHChapter4 from "../../components/reports/mental-health/chapters/MHChapter4";
import MHChapter5 from "../../components/reports/mental-health/chapters/MHChapter5";
import MHConclusionSection from "../../components/reports/mental-health/chapters/MHConclusionSection";
import SpotIllustration from "../../components/illustrations/SpotIllustration";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { whenDesktopNav } from "../../components/site/SiteNav";
import ogImage from "../../../static/images/og/tines-report-state-of-mental-health-in-cybersecurity.png";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  KnownDocumentName,
  recordDocumentEvent,
} from "../../utils/documents.utils";

const NoBreak = styled.span`
  white-space: nowrap;
`;

const HeroTextContentWrap = styled.div`
  ${whenDesktopNav} {
    max-width: 58rem;
  }
`;
const HeroIllustrationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${whenDesktopNav} {
    margin: 2em;
  }
`;

const Halo = styled.div`
  position: absolute;
  top: -3em;
  right: -3em;
  bottom: -3em;
  left: -3em;
  border-radius: 50%;
  background-color: ${withOpacity(colorsV4.white, 0.2)};
  pointer-events: none;
  animation: HaloPulse 4s infinite alternate-reverse;
  @keyframes HaloPulse {
    from {
      transform: scale(1);
      opacity: 0.5;
    }
    to {
      transform: scale(0.8);
      opacity: 1;
    }
  }
`;

const IllustrationBubble = styled.div`
  background-color: ${withOpacity(colorsV4.white, 0.38)};
  padding: 2.5em;
  border-radius: 50%;
  img {
    position: relative;
    display: block;
    ${fromTablet} {
      width: 200px;
      height: 200px;
    }
  }
`;

const PageSOCReport: PageComponent = props => {
  const description =
    "We surveyed 1,027 security professionals to put some hard up-to-date numbers behind their mental health.";
  const scrollToIntro = () => {
    scrollToHash({
      useHash: "#introduction",
      doNotPushState: true,
    });
  };
  useOnMount(() => {
    recordDocumentEvent({
      type: "view",
      identifier: KnownDocumentName.mentalHealth2022,
    });
  });

  return (
    <Page {...props}>
      <SEO
        title="Report: State of Mental Health in Cybersecurity"
        description={description}
        image={ogImage}
      />
      <style
        children=":root {
          transition: background-color 1s;
        }"
      />
      <HeroSectionWithInset backgroundColor="orange">
        <ColumnedHeroSectionContent gap="0" alignItems="center">
          <HeroTextContentWrap>
            <Pill appearance="filled-white" color="orange" children="2022" />
            <Spacing size="md" />
            <ComboFontHeading
              snippets={[
                [
                  <>
                    State of
                    <br />
                  </>,
                  "serif",
                ],
                [
                  <>
                    Mental Health in
                    <br />
                  </>,
                ],
                "Cybersecurity",
              ]}
              className={css`
                line-height: 1;
                ${fluidFontSize(42, 64)};
              `}
            />
            <Spacing size="1em" />
            <p>
              We surveyed 1,027 security professionals to put some hard{" "}
              <NoBreak>up-to-date</NoBreak> numbers behind their mental health.
            </p>
            <Spacing size="2em" />
            <div
              className={css`
                display: inline-flex;
                > * + * {
                  margin-left: 0.5em;
                  ${fromTablet} {
                    margin-left: 1em;
                  }
                }
              `}
            >
              <Button
                appearance="filled-white"
                onClick={scrollToIntro}
                color="orange"
              >
                Start reading
              </Button>
            </div>
          </HeroTextContentWrap>
          <HeroIllustrationWrap>
            <Halo />
            <IllustrationBubble>
              <SpotIllustration name="zenBrain" />
            </IllustrationBubble>
          </HeroIllustrationWrap>
        </ColumnedHeroSectionContent>
        <Spacing size="sectionPadding" />
        <Spacing size="lg" />
      </HeroSectionWithInset>

      <FromTabletDiv>
        <Spacing size="lg" />
      </FromTabletDiv>

      <ReportSectionFrame nav={<MHNav />}>
        <MHIntroSection />
        <MHKeyFindingsSection />
        <MHMethodologySection />
        <MHChapter1 />
        <MHChapter2 />
        <MHChapter3 />
        <MHChapter4 />
        <MHChapter5 />
        <MHConclusionSection />
      </ReportSectionFrame>

      <Spacing size="sectionPadding" />
    </Page>
  );
};

export default PageSOCReport;
