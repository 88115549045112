/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const dayjs = require("dayjs");
const { formatAsSearchString } = require("./string");

const asEventDirectoryItem = (record, type, allRecords) => {
  const s = {
    id: record.id,
    slug: record.slug,
    name: record.title || record.name || "Tines Event",
    excerpt: record.excerpt ?? "",
    timezone: record.timezone ?? "",
    timeStart: record.timeStart ?? "",
    timeEnd: record.timeEnd ?? "",
    featured: record.featured || false,
    place: record.place ?? null,
    type,
    url: "",
    coverImageUrl: record.coverImage?.url ?? "",
    categories: record.categories ?? (record.category ? [record.category] : []),
    relatedEventIds: [
      ...(record.relatedEvents ?? []).map(e => e.id),
      ...(allRecords ?? [])
        .filter(r => (r.relatedEvents ?? []).find(e => e.id === record.id))
        .map(r => r.id),
    ],
  };
  const isVirtual =
    type === "webinar" || s.categories.find(c => c.slug === "virtual");
  if (type === "webinar" && record.category?.slug === "bootcamp") {
    s.coverImageUrl = record.category.coverImage?.url ?? record.coverImage?.url;
    s.url = `/${record.category?.slug ?? "webinars"}/${record.slug}`;
  }
  if (type === "event") {
    s.externalRegistrationLink = record.externalRegistrationLink ?? null;
    s.hasLandingPage = record.shouldCreateLandingPage ?? false;
  }
  s.__searchString = formatAsSearchString(
    [
      s.name,
      s.place?.name,
      s.place?.street,
      s.place?.city,
      s.place?.state,
      s.place?.country,
      s.categories.map(c => c.name).join(" "),
      isVirtual ? "virtual" : "",
      dayjs(s.timeStart).format("MMMM D, YYYY"),
      dayjs(s.timeStart).format("MMM D"),
    ]
      .filter(i => i)
      .join("_")
  );
  return s;
};
exports.asEventDirectoryItem = asEventDirectoryItem;
