import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import ogImage from "../../static/images/og/tines-product-page.png";
import { colors, withOpacity } from "../styles/colors.styles";
import HeroSection from "../components/sections/HeroSection";
import AllCaps from "../components/typography/AllCaps";
import { ButtonGroupCenterFlex } from "../components/forms/Button";
import BookADemoButton from "../components/utilities/BookADemoButton";
import { NoLetterSpacing } from "../components/typography/NoLetterSpacing";
import SignUpButton from "../components/utilities/SignUpButton";
import { styled } from "@linaria/react";
import ProductPageHeroIllustration from "../components/productPage/heroIllustration/_ProductPageHeroIllustration";
import { ProductPageBuildSection } from "../components/productPage/ProductPageBuildSection";
import { ProductPageRunSection } from "../components/productPage/ProductPageRunSection";
import { ProductPageMonitorSection } from "../components/productPage/ProductPageMonitorSection";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import { ProductPageWhatWillYouBuildSection } from "../components/productPage/ProductPageWhatWillYouBuildSection";
import { ProductPageContentCardsSection } from "../components/productPage/ProductPageContentCardsSection";
import {
  fromTablet,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import OnlyPhonesDiv from "../components/mediaQueries/OnlyPhones";

const HeroSectionTextContent = styled.div`
  position: relative;
  z-index: 1;
`;

const H1 = styled.h1`
  max-width: 10em;
  margin-left: auto;
  margin-right: auto;
  + div {
    margin-top: 0;
  }
`;

const SpacingBeforeBuildSection = styled.div`
  ${onlyPhones} {
    margin-top: 3em;
  }
  ${fromTablet} {
    margin-top: 4em;
  }
`;

const PageProduct: PageComponent = props => {
  return (
    <Page
      {...props}
      navThemeColor="purple"
      textColor={colors.purple800}
      backgroundColor={colors.purple100}
      browserThemeColor={colors.purple100}
      mobileNavBgColor={colors.purple100}
      footerBackgroundColor={withOpacity(colors.purple200, 0.5)}
      clipOverflow
    >
      <SEO
        title="Product"
        image={ogImage}
        description="An overview of the Tines platform to build, run, and monitor your most important workflows."
      />

      <HeroSection
        textColor={colors.purple800}
        backdrop="dots"
        backdropColor={colors.purple300}
      >
        <HeroSectionTextContent>
          <AllCaps as="p">The product</AllCaps>
          <Spacing size="1.5em" />
          <H1>
            Power your most important work
            <NoLetterSpacing>fl</NoLetterSpacing>
            ows
          </H1>
          <Spacing size="2em" />
          <ButtonGroupCenterFlex>
            <BookADemoButton width="8em" darker />
            <SignUpButton appearance="outlined" width="8em" darker />
          </ButtonGroupCenterFlex>
        </HeroSectionTextContent>

        <ProductPageHeroIllustration />
      </HeroSection>

      <SpacingBeforeBuildSection />

      <ProductPageBuildSection />

      <Spacing size="xl" />

      <ProductPageRunSection />

      <Spacing size="xl" />

      <ProductPageMonitorSection />

      <Spacing size="subsectionMargin" />
      <OnlyPhonesDiv>
        <Spacing size="1em" />
      </OnlyPhonesDiv>

      <ProductPageWhatWillYouBuildSection />

      <Spacing size="sectionMargin" />

      <ProductPageContentCardsSection />

      <ExplosionCTASection
        backgroundColor={withOpacity(colors.purple200, 0.5)}
      />
    </Page>
  );
};

export default PageProduct;
