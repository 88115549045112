import chroma from "chroma-js";
import {
  colors,
  getBrandColorThemeVariablesAsString,
} from "../../styles/colors.styles";
import { Helmet } from "react-helmet";
import { useSiteContext } from "../../context/site.context";
import { useState } from "react";
import {
  SystemColorScheme,
  getSystemColorScheme,
} from "../../utils/colorScheme.utils";
import { breakpoints } from "../../styles/breakpointsAndMediaQueries.styles";
import { UI, useOnWindowResize } from "../../utils/window.utils";
import { SiteNavThemingConfig, getSiteNavThemeVars } from "../site/SiteNav";

export type PageThemeProps = {
  theme?: string | null;
  browserThemeColor?: string;
  browserThemeColorDesktop?: string;
  backgroundColor?: string;
  textColor?: string;
  supportsColorSchemes?: boolean;
  alwaysUseColorScheme?: SystemColorScheme;
  cssString?: string;
  linkAccentColor?: string;
  footerBackgroundColor?: string;
  footerTextColor?: string;
} & SiteNavThemingConfig;

const PageTheme = (props: PageThemeProps) => {
  const siteContext = useSiteContext();

  const hasDarkBackground = props.backgroundColor
    ? chroma(props.backgroundColor).luminance() < 0.5
    : false;

  const isDarkTheme = props.backgroundColor
    ? hasDarkBackground
    : siteContext.supportsColorSchemes
    ? siteContext.colorScheme === "dark" ||
      (siteContext.colorScheme === "auto" && getSystemColorScheme() === "dark")
    : false;

  const getDefaultThemeColor = () =>
    isDarkTheme ? colors.dark900 : colors.lightest;

  const [themeColor, setThemeColor] = useState(
    props.browserThemeColor ??
      props.browserThemeColorDesktop ??
      getDefaultThemeColor()
  );
  useOnWindowResize(() => {
    if (UI.vw < breakpoints.tablet) {
      setThemeColor(
        props.browserThemeColor ??
          props.browserThemeColorDesktop ??
          getDefaultThemeColor()
      );
    } else {
      setThemeColor(
        props.browserThemeColorDesktop ??
          props.browserThemeColor ??
          getDefaultThemeColor()
      );
    }
  });

  const navThemeVars = getSiteNavThemeVars({
    navThemeColor: props.navThemeColor ?? "neutral",
    colorNavAsDarkTheme: props.colorNavAsDarkTheme ?? isDarkTheme,
    navLogoColor: props.navLogoColor,
    mobileNavBgColor: props.mobileNavBgColor,
  });

  const backgroundColor =
    props.backgroundColor ?? (isDarkTheme ? colors.dark900 : colors.lightest);
  const foregroundColor =
    props.textColor ?? (isDarkTheme ? colors.white : colors.dark900);

  const style = `
    html {
      --PageBackgroundColor: ${backgroundColor};
      --PageForegroundColor: ${foregroundColor};
      --FooterBackgroundColor: ${props.footerBackgroundColor ?? "transparent"};
      --FooterForegroundColor: ${props.footerTextColor ?? foregroundColor};
      ${getBrandColorThemeVariablesAsString(props.theme)}
      --LinkAccentColor: ${
        props.linkAccentColor ?? (isDarkTheme ? colors.white : colors.purple)
      };
      ${Object.entries(navThemeVars)
        .map(([varName, value]) => `${varName}:${value}`)
        .join(";")}
      }
    ${props.cssString ?? ""}
  `;

  return (
    <>
      <Helmet>
        <html
          data-is-darker-theme={isDarkTheme}
          data-color-scheme={
            props.alwaysUseColorScheme ??
            siteContext.alwaysUseColorScheme ??
            (isDarkTheme
              ? "dark"
              : siteContext.supportsColorSchemes
              ? siteContext.colorScheme
              : "light")
          }
        />
        <meta name="theme-color" content={themeColor} />
      </Helmet>

      <style id="__page-theme-css" children={style} />
    </>
  );
};

export default PageTheme;
