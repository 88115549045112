import ComboFontHeading from "../../../typography/ComboFontHeading";
import Pill from "../../../basic/Pill";
import SpotIllustration from "../../../illustrations/SpotIllustration";
import ReportSecondarySection from "../../ReportSecondarySection";
import ReportChartContainer from "../../ReportChartContainer";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import BarChart from "../../charts/BarChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import LeaderboardChart, {
  LeaderboardChartItem,
} from "../../charts/LeaderboardChart";
import Spacing from "../../../layout/Spacing";

const SOCChapter2 = () => {
  return (
    <ReportSection
      id="where-time-goes"
      themeName="green"
      header={
        <>
          <div>
            <SpotIllustration name="forkingRoutes" />
          </div>
          <Pill color="green">Part Two</Pill>
          <ComboFontHeading
            snippets={[[<>Where</>], [<>time</>, "serif"], [<>goes</>]]}
          />
          <p>
            Many security analysts spend time on tedious, mundane tasks that are
            necessary yet of low-impact for the SOC team. This also keeps
            analysts from engaging in more high-impact work for their
            organizations. But what exactly are these tedious tasks, and how
            much time are they taking from analysts’ workloads? This section
            gives us some insight into where the SOC team’s time goes.
          </p>
        </>
      }
    >
      <ReportSecondarySection centered spacing="1.5em">
        <ComboFontHeading
          snippets={[
            [
              <>
                Top five tasks
                <br />
              </>,
            ],
            [<>consuming the most time</>, "serif"],
          ]}
          as="h3"
        />
        <p>
          What are the tasks that an analyst spends most of their day working
          on? (We asked them to select all that applied.)
        </p>
        <LeaderboardChart columns={2}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.green}
            textInCircle="50.4%"
          >
            <h4>#1: Reporting</h4>
            <p>
              The number one most time-consuming task is reporting. Whether it’s
              capturing notes and metrics related to cases, analyzing team
              performance, or demonstrating value to leadership, reporting
              matters — but it’s not supposed to be the main part of the job.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.orange}
            textInCircle="46.6%"
          >
            <h4>#2: Monitoring</h4>
            <p>
              The next most time-consuming task is monitoring for threats and
              alerts. While a critical part of the role of a security analyst,
              the concern here is that 71.6% of respondents are Level 2 and 3
              analysts, who are spending most of their time doing front-line
              monitoring.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.pink}
            textInCircle="38.2%"
          >
            <h4>#3: Intrusion detection</h4>
            <p>
              The task that analysts spend the most time on next is intrusion
              detection.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.purple}
            textInCircle="32.3%"
          >
            <h4>#4: Detecting</h4>
            <p>Followed by general detecting.</p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.blue}
            textInCircle="31.4%"
          >
            <h4>#5: Operations or ShiftOps</h4>
            <p>
              Rounding out the top five tasks are operations or ShiftOps tasks.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <Spacing size=".5em" />
        <p>
          And to highlight how things are the exact opposite of how they should
          be, lower on the list are more proactive, higher-value efforts, like
          updating or adding IOCs, modifying alert rules, updating operational
          documentation, and intelligence research.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>What tasks do you spend the most of your time on?</h4>
          <BarChart
            header={["Skill", "Respondents"]}
            sortByValue
            data={[
              ["Reporting", 236],
              ["Intrusion detection", 179],
              ["Monitoring", 218],
              ["Operations/ShiftOps", 147],
              ["Communicating (email, phone, slack etc.)", 132],
              ["Detecting", 151],
              ["Threat hunting", 114],
              ["Malware analysis/Forensics", 126],
              ["Log analysis", 106],
              ["Responding to security incidents", 103],
              ["Updating/adding IOC's", 81],
              ["Modifying alert rules", 89],
              ["Troubleshooting system errors/System maintenance", 87],
              ["Update/adding KBs/operational documentation", 89],
              ["Meetings", 84],
              ["Data Loss Prevention (DLP)", 97],
              ["Containment", 81],
              ["Recovery", 87],
              [
                "Intelligence (i.e. researching threat actors/TTPs/Att&ck frameworks)",
                98,
              ],
              ["Compliance/audits", 86],
              [
                "Vulnerability/compliance scanning (e.g. Nessus) & patching",
                105,
              ],
              ["Phishing triage/response", 81],
              ["Penetration testing, Red teaming, Purple teaming, etc.", 108],
              ["Security orchestration, automation and response (SOAR)", 113],
              ["Evaluating new vendors/products/services", 72],
            ]}
            total={468}
            height={1200}
            colors={[colorsV4.green]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>

      <ReportSecondarySection centered spacing="1.5em">
        <ComboFontHeading
          snippets={[
            [
              <>
                Top three tasks
                <br />
              </>,
            ],
            [<>analysts enjoy the least</>, "serif"],
          ]}
          as="h3"
        />
        <p>
          Which tasks do analysts least enjoy, that are uninspiring, drain their
          energy, or are simply a slog to get through?
        </p>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.green}
            textInCircle="17.7%"
          >
            <h4>#1: Triage</h4>
            <p>
              Number one task on the list was triage. While triaging is a core
              skill of a security analyst, and while many enjoy it because of
              its detective work, the far less enjoyable parts include manually
              deduplicating alerts, repeated similar cases, and too much noise.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.orange}
            textInCircle="16.2%"
          >
            <h4>#2: Reporting</h4>
            <p>
              The next task that analysts enjoy the least is reporting, which,
              as we saw above, is the task they’re spending the most time on
              during the day. Again, reporting matters, but streamlining
              reporting through automation can help reduce time spent and
              increase job satisfaction.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.purple}
            textInCircle="13%"
          >
            <h4>#3: Monitoring</h4>
            <p>
              Finally, analysts also least enjoy monitoring, which is the second
              most time-consuming task in an analyst’s day. Like reporting, much
              of this kind of front line manual monitoring can be automated to
              free up analysts to focus on tasks that have more impact and that
              they enjoy.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>

        <Spacing size=".5em" />

        <ReportChartContainer centered>
          <h4>Which of these tasks do you enjoy the least?</h4>
          <BarChart
            header={["Task", "Respondents"]}
            sortByValue
            data={[
              ["Triaging", 83],
              ["Reporting", 76],
              ["Intrusion detection", 47],
              ["Monitoring", 61],
              ["Operations/ShiftOps", 33],
              ["Communicating (email, phone, slack, etc.)", 36],
              ["Detecting", 29],
              ["Tracking", 38],
              ["Threat hunting", 30],
              ["Responding to security incidents", 35],
            ]}
            total={468}
            height={400}
            colors={[colorsV4.green]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>

      <ReportSecondarySection centered spacing="1.5em">
        <ComboFontHeading
          snippets={[
            [
              <>
                Top five key metrics
                <br />
              </>,
            ],
            [<>used to measure job performance</>, "serif"],
          ]}
          as="h3"
        />
        <p>
          How are analysts’ job performance being measured? In other words, what
          metrics should SOC teams be looking to improve first to optimize team
          performance?
        </p>
        <Spacing size=".5em" />
        <LeaderboardChart columns={2}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.green}
            textInCircle="54.1%"
          >
            <h4>#1: Mean time to investigate</h4>
            <p>
              For the majority of analysts, the top measure of their job
              performance is mean time to investigate. So SOC teams should look
              for ways to reduce time between detection and investigation.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.orange}
            textInCircle="43.8%"
          >
            <h4>#2: Number of alerts</h4>
            <p>
              The second metric they’re most measured on is number of alerts.
              SOC teams should focus on ways to reduce false positives and
              reduce alert fatigue amongst their analysts.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.pink}
            textInCircle="40%"
          >
            <h4>#3: Mean time to respond</h4>
            <p>
              The third most-used metric is mean time to respond, and tools such
              as automation can help reduce time spent investigating,
              remediating, and eliminating threats or incidents.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.purple}
            textInCircle="37.6%"
          >
            <h4>#4: Time to detect</h4>
            <p>
              Next, they’re measured on time to detect. SOC teams should focus
              on ways to identify issues faster and ensure that they have tools
              in place to catch something they may have not even seen before
              (the dreaded zero-day exploit).
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.blue}
            textInCircle="34.2%"
          >
            <h4>#5: Number of incidents handled</h4>
            <p>
              Finally, the fifth key metric used to measure performance is
              number of incidents handled, which teams can decrease by having
              better, faster, and more thorough alert and resolution tools in
              place.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>

        <Spacing size=".5em" />

        <ReportChartContainer centered>
          <h4>What key metrics are used to measure your job performance?</h4>
          <BarChart
            header={["Metric", "Respondents"]}
            sortByValue
            data={[
              ["Mean time to investigate", 253],
              ["Mean time to respond", 187],
              ["Number of alerts", 205],
              ["Number of incidents handled", 160],
              ["Time to detect", 176],
              ["False positive rate", 130],
              ["Time to containment", 127],
              ["Time to eradication", 110],
              ["Percentage of escalated events", 103],
              ["Percentage of recurring incidents", 110],
              ["Adherence to Service Level Agreements (SLAs)", 109],
              [
                "Adherence to SOW/SOP/KBs (Statements of Work, Std Operating Procedure, Knowledge Base articles)",
                132,
              ],
              ["Knowledge Base/wiki articles created or enhanced", 121],
              ["Rules or detections created or enhanced", 107],
              ["False positives identified and reduced", 74],
            ]}
            total={468}
            height={800}
            colors={[colorsV4.green]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>

      <ReportSummaryCard>
        <h3>Summary</h3>
        <p>
          Where does an analyst’s time go? According to our respondents, their
          time is mostly spent on necessary but tedious manual tasks, like
          reporting, monitoring, detection, and other operational duties. It’s
          also least spent on tasks that could more proactively position the
          organization’s security, like updating documentation, modifying alert
          rules, and intelligence and threat research.
        </p>
        <p>
          Unfortunately, the tasks that analysts enjoy the least about their job
          are the same tasks they’re spending most of their day working on. When
          someone spends the majority of their day working on tasks they don’t
          enjoy — tasks that, if automated, could free them up for more engaging
          work — it’s no wonder 71% are feeling some level of burnout.
        </p>
        <p>
          Automation can also help improve the metrics used to measure analyst
          job performance, like decreasing mean time to detect, investigate, and
          respond, decreasing the number of alerts received, and more efficiency
          responding to incidents.
        </p>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default SOCChapter2;
