import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";

import Page from "./Page.template";
import { PageComponentProps } from "../types/gatsby.types";
import YDWWTHero from "../components/ydwwt/YDWWTHero";
import { colors } from "../styles/colors.styles";
import YDWWTCategoriesSection from "../components/ydwwt/YDWWTCategoriesSection";
import YDWWTFaqSection from "../components/ydwwt/YDWWTFaqSection";
import YDWWTPrizesSection from "../components/ydwwt/YDWWTPrizesSection";
import YDWWTEnterCompetitionSection from "../components/ydwwt/YDWWTEnterCompetitionSection";
import { graphql } from "gatsby";
import { DatoCmsYdwwt, YdwwtBySlugQuery } from "../../graphql-types";
import defaultOgImage from "../../static/images/og/you-did-what-with-tines.png";
import { YDWWT_SUBMISSION_MODAL_NAME } from "../components/modals/modalNames";
import YDWWTSubmissionModal from "../components/ydwwt/YDWWTSubmissionModal";
import { useSiteContext } from "../context/site.context";
import { YDWWTInspirationsSection } from "../components/ydwwt/YDWWTInspirationsSection";
const defaultDescription =
  "Use Tines to automate something. Fun or Serious. Simple or Complex. Surprise us. Surprise yourself! You can enter as often as you like and the best thing is: everybody gets a prize!";

const YDWWTLandingPage = (
  props: PageComponentProps<{
    data: YdwwtBySlugQuery;
  }>
) => {
  const siteContext = useSiteContext();
  const ydwwt = props.data.ydwwt as DatoCmsYdwwt;
  return (
    <Page
      {...props}
      browserThemeColor={colors.dark900}
      backgroundColor={colors.dark900}
      textColor={colors.white}
    >
      <SEO
        title={ydwwt.seoTitle || `You did WHAT with Tines?!: ${ydwwt.name}`}
        description={ydwwt.seoDescription || defaultDescription}
        image={ydwwt.pagePreviewImage?.url || defaultOgImage}
      />

      <YDWWTHero ydwwt={ydwwt} />

      <Spacing size="sectionMargin" />

      <YDWWTCategoriesSection ydwwt={ydwwt} />

      <Spacing size="sectionMargin" />

      <YDWWTPrizesSection ydwwt={ydwwt} />

      <Spacing size="sectionMargin" />

      <YDWWTInspirationsSection ydwwt={ydwwt} />

      <Spacing size="sectionMargin" />

      <YDWWTEnterCompetitionSection ydwwt={ydwwt} />

      <Spacing size="sectionMargin" />

      <YDWWTFaqSection ydwwt={ydwwt} />

      <Spacing size="sectionMargin" />

      {siteContext.modal === YDWWT_SUBMISSION_MODAL_NAME && (
        <YDWWTSubmissionModal ydwwt={ydwwt} />
      )}
    </Page>
  );
};

export default YDWWTLandingPage;

export const ydwwtBySlugQuery = graphql`
  query YdwwtBySlug($slug: String) {
    ydwwt: datoCmsYdwwt(slug: { eq: $slug }) {
      id: originalId
      slug
      name
      seoTitle
      seoDescription
      pagePreviewImage {
        url
      }
      announcementPageHeading
      announcementPageMainCopy {
        blocks
        value
      }
      categories {
        id: originalId
        name
        color
        description {
          blocks
          value
        }
        icon {
          url
        }
      }
      winners {
        color
        story {
          storyId
          descriptor
          internalAuthors {
            name
            surname
            linkedin
            organization {
              name
            }
          }
          communityAuthors {
            name
            surname
            linkedin
            organization {
              name
            }
          }
          videoIntroEmbedUrl
          gallery {
            url
            width
            height
          }
        }
      }
      categoryWinners {
        id: originalId
        color
        description {
          blocks
          value
        }
        icon {
          url
        }
        name
        winners {
          id: originalId
          story {
            storyId
            descriptor
            internalAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            communityAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            videoIntroEmbedUrl
            gallery {
              url
              width
              height
            }
          }
        }
      }
      dateEnd
      dateStart
      dateWinnerAnnouncement
      examplesSectionCopy {
        blocks
        value
      }
      inspirationSectionCopy {
        blocks
        value
      }
      inspirationSectionExampleStories {
        storyId
        descriptor
        internalAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        communityAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        videoIntroEmbedUrl
        gallery {
          url
          width
          height
        }
      }
      faq {
        id: originalId
        answer {
          blocks
          value
        }
        question {
          blocks
          value
        }
      }
      heroSectionBodyCopy {
        blocks
        value
      }
      heroSectionSubheading
      prizes {
        id: originalId
        color
        prizeType
        summary {
          blocks
          value
        }
        image {
          url
        }
      }
      prizesSectionCopy {
        blocks
        value
      }
    }
  }
`;
