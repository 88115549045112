import { useState } from "react";
import { useOnMount } from "./lifeCycle.utils";

export const useDocumentDimensions = () => {
  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(768);
  useOnMount(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);
    });
    resizeObserver.observe(document.documentElement);
    return () => {
      resizeObserver.disconnect();
    };
  });
  return { documentWidth: width, documentHeight: height };
};
