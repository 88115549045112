import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopMl,
  fromTablet,
  uptoDesktop,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import PageSection from "../reusableSections/PageSection";
import { colors } from "../../styles/colors.styles";
import { rSize, rSizeNoCalc } from "../../styles/responsiveSizes.styles";
import { w } from "../../utils/string.utils";
import Spacing from "../layout/Spacing";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPaths";
import AllCaps from "../typography/AllCaps";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { SparklesTwoToneIcon } from "../icons/twoTone/SparklesTwoToneIcon";
import { graphql, useStaticQuery } from "gatsby";
import {
  CasesPageFeaturedStoriesQuery,
  DatoCmsStoryDigest,
} from "../../../graphql-types";
import StoryEntry, { StoryEntryInner } from "../library/StoryEntry";
import { serif } from "../../styles/fonts.styles";
import { CasesPageAIPortalIcon } from "./icons/CasesPageAIPortalIcon";
import { GridCanvas } from "../decorations/GridCanvas";
import { Link } from "gatsby";
import { css } from "linaria";
import { convertDatoRecordToStoryDescriptor } from "../../utils/story.utils";

const HeaderGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  grid-template-areas: "icon" "header" "list";
  ${fromDesktop} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-areas: ${`". ${w("header", 4)} ${w("icon", 2)} ${w(
      "list",
      5
    )}"`};
    padding-bottom: ${rSize("xl")};
  }
  ${fromDesktopMl} {
    grid-template-areas: ${`". . ${w("header", 3)} ${w("icon", 2)} ${w(
      "list",
      4
    )} ."`};
  }
`;

const IconContainer = styled.div`
  grid-area: icon;
  justify-self: center;
  background-color: ${colors.purple};
  color: ${colors.white};
  border: 2px solid ${colors.purple300};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  position: relative;
  svg {
    width: 3.6rem;
    height: 3.6rem;
  }
`;

const Header = styled.header`
  grid-area: header;
  ${uptoDesktop} {
    text-align: center;
  }
  h2 {
    span {
      ${uptoTablet} {
        white-space: nowrap;
      }
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

const List = styled.div`
  grid-area: list;
  line-height: 1.2;
  font-size: 1.4rem;
  ${uptoDesktop} {
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 27em;
  }
  ${fromDesktop} {
    padding-left: 0;
    max-width: 20em;
  }
  h3 {
    ${uptoDesktop} {
      text-align: center;
    }
  }
  ul {
    padding-left: 1em;
    ${uptoDesktop} {
      columns: 2;
      column-gap: 1.5em;
    }
    ${fromDesktop} {
      padding-left: 0;
    }
  }
  li {
    margin-bottom: 1rem;
    break-inside: avoid;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
`;

const StoryEmbedWrap = styled.div`
  background-color: ${colors.lightest};
  border-radius: ${rSize("radius")};
  > * {
    border: 1px solid var(--ac600);
  }
  ${StoryEntryInner} {
    ${fromDesktop} {
      padding: 0.5em;
    }
  }
`;

const AIPageBannerFooter = styled.footer`
  position: relative;
  grid-area: footer;
  strong span {
    transition: transform 0.1s;
  }
`;

const AIPageBanner = css`
  position: relative;
  background-color: ${colors.yellow100};
  border: 1px solid ${colors.yellow400};
  color: ${colors.yellow700};
  border-radius: 1.2rem;
  padding: 1.5em;
  display: grid;
  grid-template-areas: "text" "footer" "icon";
  text-decoration: none;
  ${uptoTablet} {
    grid-gap: 1em;
  }
  ${fromTablet} {
    grid-column-end: span 2;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-areas: "text icon" "footer icon";
  }
  ${fromDesktop} {
    padding: 2em;
  }
  &:hover {
    border-color: ${colors.yellow700};
    ${AIPageBannerFooter} {
      strong span {
        transform: translateX(0.5em);
      }
    }
  }
`;

const AIPageBannerTextContent = styled.div`
  position: relative;
  grid-area: text;
  h3 {
    font-size: 2.8rem;
    font-family: ${serif};
    font-weight: 400;
    span {
      display: block;
    }
  }
  p {
    max-width: 20em;
    margin-top: 0.75em;
  }
`;

const AIPageBannerIcon = styled.div`
  position: relative;
  grid-area: icon;
  ${uptoTablet} {
    justify-self: end;
  }
  ${fromTablet} {
    margin: 1.5em;
    width: 19rem;
    height: 19rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.yellow200};
    border: 1px solid ${colors.yellow300};
    border-radius: 50%;
  }
`;

export const CasesPageAISection = () => {
  const data = useStaticQuery<CasesPageFeaturedStoriesQuery>(
    casesPageFeaturedStoriesQuery
  );
  const stories = data.stories.nodes.map(s =>
    convertDatoRecordToStoryDescriptor(s as DatoCmsStoryDigest)
  );
  return (
    <PageSection relative>
      <HeaderGrid>
        <IconContainer>
          <SparklesTwoToneIcon />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-left-start"
            index={0}
            color={colors.purple300}
            bottom={0}
            left="50%"
          />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-right-start"
            index={0}
            color={colors.purple300}
            bottom={0}
            left="50%"
          />
        </IconContainer>

        <Header>
          <AllCaps>AI + Cases</AllCaps>
          <Spacing size="1em" />
          <SectionHeading2 lighter>
            Do more with <span>cases and AI</span>
          </SectionHeading2>
          <Spacing />
        </Header>

        <List>
          <AllCaps as="h3">Examples</AllCaps>
          <Spacing size=".5em" />
          <ul>
            <li>Summarize event data and pass to a case</li>
            <li>Format a case template using Markdown</li>
            <li>Summarize what happened on a case and pass it to a page</li>
            <li>
              Format a timeline of events into a visualization then add it to a
              case
            </li>
            <li>Map record data</li>
          </ul>
        </List>

        <ConnectorPathWaypoint
          id="cases-page-ai-section-left-start"
          index={1}
          color={colors.purple300}
          bottom="0"
          left="50%"
          radius={18}
        />

        <ConnectorPathWaypoint
          id="cases-page-ai-section-left-start"
          index={2}
          color={colors.purple300}
          top="100%"
          left={rSize("xl")}
          radius={32}
        />

        <ConnectorPathWaypoint
          id="cases-page-ai-section-left-start"
          index={3}
          color={colors.purple300}
          top={`calc(100% + ${rSizeNoCalc("xxl")})`}
          left={rSize("xl")}
        />

        <ConnectorPathWaypoint
          id="cases-page-ai-section-right-start"
          index={1}
          color={colors.purple300}
          bottom="0"
          left="50%"
          radius={18}
        />

        <ConnectorPathWaypoint
          id="cases-page-ai-section-right-start"
          index={2}
          color={colors.purple300}
          top="100%"
          right={rSize("xl")}
          radius={32}
        />

        <ConnectorPathWaypoint
          id="cases-page-ai-section-right-start"
          index={3}
          color={colors.purple300}
          top={`calc(100% + ${rSizeNoCalc("xxl")})`}
          right={rSize("xl")}
        />
      </HeaderGrid>

      <Spacing size="xxl" />

      <ContentGrid>
        {stories.map(s => (
          <StoryEmbedWrap key={s.id}>
            <StoryEntry
              story={s}
              withDescription
              size="medium"
              preHeading="Example workflow"
              hideTools
            />
          </StoryEmbedWrap>
        ))}

        <Link to="/product/ai" className={AIPageBanner}>
          <GridCanvas parallax color={colors.yellow200} />
          <AIPageBannerIcon>
            <CasesPageAIPortalIcon />
          </AIPageBannerIcon>
          <AIPageBannerTextContent>
            <h3>
              <span>Learn more about</span> <span>AI in Tines</span>
            </h3>
            <p>
              Leverage LLMs to build, run, and monitor your most important
              workflows. Private and secure by design.
            </p>
          </AIPageBannerTextContent>
          <AIPageBannerFooter>
            <p>
              <strong>
                Learn more <span>→</span>
              </strong>
            </p>
          </AIPageBannerFooter>

          <ConnectorPathWaypoint
            id="cases-page-ai-section-left-end"
            index={0}
            color={colors.purple300}
            top="100%"
            left={rSize("xl")}
          />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-left-end"
            index={1}
            color={colors.purple300}
            top={`calc(100% + ${rSizeNoCalc("xxl")})`}
            left={rSize("xl")}
            radius={32}
          />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-left-end"
            index={2}
            color={colors.purple300}
            top={`calc(100% + ${rSizeNoCalc("xxl")})`}
            left="50%"
            radius={18}
          />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-left-end"
            index={3}
            color={colors.purple300}
            top={`calc(100% + ${rSizeNoCalc("xxl")} + ${rSizeNoCalc(
              "sectionMargin"
            )})`}
            left="50%"
          />

          <ConnectorPathWaypoint
            id="cases-page-ai-section-right-end"
            index={0}
            color={colors.purple300}
            top="100%"
            right={rSize("xl")}
          />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-right-end"
            index={1}
            color={colors.purple300}
            top={`calc(100% + ${rSizeNoCalc("xxl")})`}
            right={rSize("xl")}
            radius={32}
          />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-right-end"
            index={2}
            color={colors.purple300}
            top={`calc(100% + ${rSizeNoCalc("xxl")})`}
            right="50%"
            radius={18}
          />
          <ConnectorPathWaypoint
            id="cases-page-ai-section-right-end"
            index={3}
            color={colors.purple300}
            top={`calc(100% + ${rSizeNoCalc("xxl")} + ${rSizeNoCalc(
              "sectionMargin"
            )})`}
            right="50%"
          />
        </Link>
      </ContentGrid>

      <Spacing size="xxl" />
    </PageSection>
  );
};

export const casesPageFeaturedStoriesQuery = graphql`
  query casesPageFeaturedStories {
    stories: allDatoCmsStoryDigest(
      filter: { storyId: { in: ["1243116", "1238726"] } }
    ) {
      nodes {
        storyId
        descriptor
        internalAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        communityAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        videoIntroEmbedUrl
        gallery {
          url
          width
          height
        }
      }
    }
  }
`;
