import { DatoCmsEvent, DatoCmsEventCategory } from "../../../graphql-types";
import {
  EventDirectoryItem,
  EventDirectoryItemCategory,
} from "../../types/helper.types";
import CategoryPill from "../basic/CategoryPill";

type Props = {
  event: DatoCmsEvent | EventDirectoryItem;
};

const EventCategoryList = (props: Props) => {
  return (
    <>
      {(props.event.categories as EventDirectoryItemCategory[])
        ?.filter(i => i)
        .map(category => (
          <CategoryPill
            category={category as DatoCmsEventCategory}
            key={category?.name}
          />
        ))}
    </>
  );
};

export default EventCategoryList;
