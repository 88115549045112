import { styled } from "@linaria/react";
import Spacing from "../components/layout/Spacing";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import { PageTree } from "../utils/tree.utils";
import Page from "./Page.template";
import UniLearningPathCard from "../components/hub/university/UniLearningPathCard";
import { rSize } from "../styles/responsiveSizes.styles";
import {
  KnownLearningPathConfigs,
  useUniTreeWithProgress,
} from "../utils/university.utils";
import { IconTinesUniversityPurple } from "../components/hub/university/IconTinesUniversityPurple";
import { color, colors } from "../styles/colors.styles";
import UniMoreContentCard from "../components/hub/university/UniMoreContentCard";
import Button from "../components/forms/Button";
import ogImage from "../../static/images/og/tines-university.png";
import { TinesCertificationIcon } from "../components/hub/university/TinesCertificationIcon";
import BootcampIcon from "../components/illustrations/spots/BootcampIcon";
import HeroSection, {
  HeroSectionDescription,
} from "../components/sections/HeroSection";
import { getWidthPxInMaxGrid } from "../constants/globalGrid.constants";
import { Paths } from "../utils/pathBuilders.utils";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { graphql } from "gatsby";
import { UniIndexPageQuery } from "../../graphql-types";
import AllCaps from "../components/typography/AllCaps";
import StandardCTASection from "../components/sections/StandardCTASection";
import WiderPageSection from "../components/layout/WiderPageSection";
import {
  fromTabletLg,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import UniversityTestimonialMarquee from "../components/hub/university/UniversityTestimonialMarquee";
import UniIntroVideoBubble from "../components/hub/university/UniIntroVideoBubble";
import UniProgressHint from "../components/hub/university/UniProgressHint";

const IconWrap = styled.div`
  svg {
    ${onlyPhones} {
      width: 120px;
      height: auto;
    }
  }
`;
const LearningPathGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
`;

const MoreContentGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
`;

const UniversityIndexPageTemplate: PageComponent<{
  pageContext: { learningPaths: PageTree };
  data: UniIndexPageQuery;
}> = props => {
  const description =
    "Learn how to build in Tines; from the basics to advanced, and get Tines-certified.";
  const treeWithProgress = useUniTreeWithProgress(
    props.pageContext.learningPaths
  );
  const knownCourses = treeWithProgress.filter(p =>
    KnownLearningPathConfigs.find(k => k.slug === p.tree.path.slug)
  );
  const ctaButtonLabel = knownCourses.every(p => p.completed)
    ? "Start learning"
    : knownCourses.some(p => p.hasStarted)
    ? "Continue from where you left off"
    : "Start learning";
  const ctaButtonLink =
    knownCourses
      .map(c => c.tree.children)
      .flat()
      .find(c => !c.completionTime)?.path ??
    knownCourses[0].tree.children[0].path;

  return (
    <Page
      {...props}
      textColor={colors.purple800}
      navThemeColor="purple"
      backgroundColor={colors.purple50}
    >
      <SEO title="Tines University" image={ogImage} description={description} />
      <AdminToolBarWithEditInDatoCmsButton itemType="universityLesson" />

      <HeroSection
        backdrop="grid"
        backdropColor={color("purple", 150)}
        contentRelative
      >
        <IconWrap>
          <IconTinesUniversityPurple />
        </IconWrap>
        <h1>Tines University</h1>
        <HeroSectionDescription
          centered
          style={{
            maxWidth: getWidthPxInMaxGrid(7),
          }}
        >
          {description}
        </HeroSectionDescription>
        <Spacing size="1.5em" />
        <Button
          to={ctaButtonLink}
          darker
          width={ctaButtonLabel === "Start learning" ? "8em" : undefined}
        >
          {ctaButtonLabel}
        </Button>
        <Spacing size="1.5em" />
        <UniProgressHint />
      </HeroSection>

      <UniversityTestimonialMarquee />

      <UniIntroVideoBubble />

      <Spacing size="sectionPadding" />

      <WiderPageSection>
        <LearningPathGrid>
          {knownCourses.map(p => {
            const record = props.data.learningPaths.nodes.find(
              l => l.id === p.tree.path.id
            );
            return (
              <UniLearningPathCard
                key={p.tree.path.id}
                learningPath={p}
                description={record?.excerpt ?? ""}
                estimatedTime={record?.estimatedTime ?? 0}
              />
            );
          })}
        </LearningPathGrid>

        <Spacing size="sectionPadding" />

        <MoreContentGrid>
          <UniMoreContentCard
            color="green"
            icon={<BootcampIcon />}
            button={
              <Button to="/bootcamp" width="7em" color="green" darker>
                Register
              </Button>
            }
          >
            <AllCaps>For beginners</AllCaps>
            <Spacing />
            <h2>Bootcamps</h2>
            <Spacing />
            <p>
              Sign up for a guided training session to learn the core concepts
              of Tines.
            </p>
          </UniMoreContentCard>

          <UniMoreContentCard
            color="purple"
            icon={<TinesCertificationIcon />}
            button={
              <Button to={Paths.getCertified()} width="8em" darker>
                Get certified
              </Button>
            }
          >
            <AllCaps>For pros</AllCaps>
            <Spacing />
            <h2>Tines certification program</h2>
            <Spacing />
            <p>
              Completed all the courses? Sign up for the{" "}
              <strong>Tines certification program</strong> to accelerate your
              building potential.
            </p>
          </UniMoreContentCard>
        </MoreContentGrid>
      </WiderPageSection>

      <Spacing size="sectionPadding" />

      <StandardCTASection
        heading="Don’t have Tines yet?"
        body="Sign up now for Tines Community Edition and start learning!"
        wider
      />

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const uniIndexPageQuery = graphql`
  query UniIndexPage {
    learningPaths: allDatoCmsLesson(
      filter: { treeParent: { id: { eq: null } } }
    ) {
      nodes {
        id: originalId
        excerpt
        estimatedTime
      }
    }
  }
`;

export default UniversityIndexPageTemplate;
