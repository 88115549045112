import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { BrandColorNameV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { StoryDescriptor } from "../../types/helper.types";
import { StoryEmbedFrameWrapper } from "./StoryEmbedFrame";
import StoryIconArray from "./StoryIconArray";
import StoryImportButton from "./StoryImportButton";
import RigidDotGrid, { RigidDotGridLayer } from "../decorations/RigidDotGrid";
import StoryCommunityAuthorsDisplay from "./StoryCommunityAuthorsDisplay";
import GradientBorder from "../decorations/GradientBorder";
import { CSSProperties, Fragment, ReactNode } from "react";
import { Link } from "gatsby";
import { cx } from "linaria";
import { useSiteContext } from "../../context/site.context";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";
import VotingBanner from "../hub/VotingBanner";
import { Paths } from "../../utils/pathBuilders.utils";
import StoryEmbedAsGallery, {
  StoryEmbedAsGalleryWrap,
} from "./StoryEmbedAsGallery";
import StoryInternalAuthorsDisplay from "./StoryInternalAuthorsDisplay";
import { isCommunityStory } from "../storyboard/utils/story.utils";
import { isString } from "lodash-es";
import { DatoCmsLibraryTool } from "../../../graphql-types";
import { makeSlug } from "../../utils/string.utils";
import { preventWidows } from "../../utils/typography.utils";
import Pill from "../basic/Pill";
import chroma from "chroma-js";

type Props = {
  story: StoryDescriptor;
  overrideColor?: BrandColorNameV4 | null;
  pillText?: string | null;
  layout?: "split" | "centered";
  flexibleEmbedRatio?: boolean;
  withBorder?: boolean;
  withLinkToStoryPage?: boolean;
  asLightbox?: boolean;
  noCloseButton?: boolean;
  hideTools?: boolean;
  doNotApplyCommunityStyling?: boolean;
  disableFullScreen?: boolean;
  description?: ReactNode;
  utmCampaign?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
};

export const StoryCardWithEmbedDiv = styled.section`
  display: grid;
  position: relative;
  grid-template-rows: auto minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  padding: 2em ${rSize("md")} ${rSize("md")};
  ${fromTablet} {
    padding: ${rSize("lg")};
  }
  border-radius: ${rSize("radius")};
  background-color: var(--BackgroundLightMode, ${withOpacity(colors.purple)});
  color: var(--ac700, ${colors.purple700});
  ${RigidDotGridLayer} {
    opacity: 0.3;
  }
  .Button {
    font-size: 1.6rem;
  }
  .ButtonInner {
    background-color: var(--ac, ${colors.purple});
    color: ${colors.white};
    &:hover {
      background-color: var(--ac, ${colors.purple});
      filter: brightness(1.1);
    }
  }
  [data-color-scheme="dark"] & {
    background-color: var(
      --BackgroundDarkMode,
      ${withOpacity(colors.purple600, 0.2)}
    );
    color: var(--ac100, ${withOpacity(colors.purple100)});
    ${RigidDotGridLayer} {
      opacity: 0.15;
    }
    .ButtonInner {
      background-color: var(--ac100, ${withOpacity(colors.purple100)});
      color: var(--ac600, ${colors.purple600});
      &:hover {
        background-color: var(--ac100, ${withOpacity(colors.purple100)});
        filter: brightness(1.1);
      }
    }
  }
  @media (prefers-color-scheme: dark) {
    [data-color-scheme="auto"] & {
      background-color: var(--ac60020, ${withOpacity(colors.purple600, 0.2)});
      color: var(--ac100, ${withOpacity(colors.purple100)});
      ${RigidDotGridLayer} {
        opacity: 0.15;
      }
      .ButtonInner {
        background-color: var(--ac100, ${withOpacity(colors.purple100)});
        color: var(--ac600, ${colors.purple600});
      }
    }
  }
  &.split {
    ${fromTabletLg} {
      grid-template-rows: auto;
      grid-template-columns: 16.8em minmax(0, 1fr);
      align-items: stretch;
      padding: 1em;
      ${StoryEmbedAsGalleryWrap},
      ${StoryEmbedFrameWrapper} {
        aspect-ratio: auto;
        min-height: 27.5em;
      }
    }
    ${fromDesktopMd} {
      ${StoryEmbedAsGalleryWrap},
      ${StoryEmbedFrameWrapper} {
        min-height: 30em;
      }
    }
  }
  &.asLightbox {
    padding: 2.5em 2.4rem 2.4rem 2.4rem;
    ${fromTabletLg} {
      padding: 2.5em 2.4rem 2.4rem 2.4rem;
    }
    &.noCloseButton {
      padding: 2.4rem;
    }
  }
  &.community {
    border: 1px solid var(--BorderColor);
  }
  .ButtonInner {
    background-color: var(--ac600, ${colors.purple600});
  }
`;

const Header = styled.header`
  display: grid;
  grid-gap: ${rSize("gap")};
  position: relative;
  p {
    line-height: 1.35;
    font-size: 1.4rem;
    margin-left: 0 !important;
  }
  ${fromTabletLg} {
    .centered & {
      p {
        line-height: 1.35;
        font-size: 1.6rem;
      }
    }
    .split & {
      text-align: left;
      grid-template-rows: auto minmax(0, 1fr);
      grid-template-columns: minmax(0, 1fr);
      align-items: stretch;
      ${fromTabletLg} {
        padding: 1em;
      }
      p {
        line-height: 1.35;
        ${fromTabletLg} {
          margin-left: 0;
        }
      }
    }
    .asLightbox & {
      margin-top: -1.125em;
      padding-left: 1.125em;
      padding-bottom: 1.5em;
    }
  }
`;

const IconArrayWrapper = styled.div`
  display: flex;
  .split & {
    ${fromTabletLg} {
      display: flex;
      padding-bottom: 3em;
    }
  }
`;

const HeaderContent = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  align-self: end;
  h3 {
    text-wrap: balance; // supported in Chrome but not in safari
    font-size: 2.4rem;
    ${fromPhoneLg} {
      font-size: 2.8rem;
    }
    ${fromTablet} {
      font-size: 3rem;
    }
    a {
      text-decoration: none;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  h4 {
    text-transform: uppercase;
    opacity: 0.6;
    letter-spacing: 0.05em;
    font-size: 1.2rem !important;
    + * {
      margin-top: 0.5em;
    }
  }
  p {
    max-width: 38em;
    margin-left: auto;
    margin-right: auto;
    strong {
      font-weight: 600;
    }
  }
`;

const HeaderContentInner = styled.div`
  .asLightbox & {
    /* position: sticky;
    bottom: 0; */
  }
  > * {
    + * {
      margin-top: 1.6rem;
    }
  }
`;

const HeaderEnd = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .split & {
    ${fromTabletLg} {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .centered & {
    > * {
      + * {
        margin-top: ${rSize("gap")};
      }
    }
  }
`;

const LightboxFooter = styled.div`
  backdrop-filter: blur(2em);
  border-radius: 1em;
  overflow: hidden;
  ${fromTabletLg} {
    margin: 1em -1em -1.5em -1em;
  }
  > * {
    border-radius: 0;
  }
`;

const LinkToStoryPageWrapper = styled.p`
  a {
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }
`;

const ToolList = styled.p`
  a {
    text-decoration: none;
    color: inherit !important;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 2.4rem;
  width: 6.4rem;
  padding: 0.5em;
  color: inherit;
  background-color: var(--ac20, ${withOpacity(colors.purple, 0.2)});
  border: 0;
  border-radius: 0 0 0.5em 0.5em;
  &:hover {
    background-color: var(--ac30, ${withOpacity(colors.purple, 0.3)});
  }
  svg {
    display: block;
  }
`;

const HowItWorksSection = styled.div`
  background-color: ${withOpacity(colors.white, 0.8)};
  ${darkModeLinariaCSS(`background-color: var(--ac800, ${colors.dark900});`)}
  .community & {
    background-color: var(--ac50, ${colors.purple50});
    ${darkModeLinariaCSS(`background-color: var(--ac800, ${colors.dark900});`)}
  }
  padding: 1em;
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  .Button {
    font-size: 1.4rem;
  }
  .ButtonInner {
    border-radius: 0.5em;
  }
`;

const AuthorListParagraph = styled.p`
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StoryCardWithEmbed = (p: Props) => {
  const siteContext = useSiteContext();
  const layout = p.layout ?? "split";
  const theme = getBrandColorTheme(p.overrideColor ?? p.story.color);
  const importButton = (
    <StoryImportButton
      story={p.story}
      utmCampaign={p.utmCampaign}
      utmMedium={p.utmMedium}
      utmSource={p.utmSource}
      fullWidth={p.asLightbox}
    />
  );
  const isCommunity = isCommunityStory(p.story);
  const applyCommunityStyling = isCommunity && !p.doNotApplyCommunityStyling;
  return (
    <StoryCardWithEmbedDiv
      color={theme.name}
      className={cx(
        layout,
        p.asLightbox && "asLightbox",
        p.noCloseButton && "noCloseButton",
        applyCommunityStyling && "community"
      )}
      style={
        {
          "--ac": theme.c500,
          "--ac20": withOpacity(theme.c500, 0.2),
          "--ac30": withOpacity(theme.c500, 0.3),
          "--ac50": theme.c50,
          "--ac75": chroma.mix(theme.c100, theme.c50).hex(),
          "--ac70": withOpacity(theme.c500, 0.7),
          "--ac100": theme.c100,
          "--ac10030": withOpacity(theme.c100, 0.4),
          "--ac600": theme.c600,
          "--ac60020": withOpacity(theme.c600, 0.2),
          "--ac700": theme.c700,
          "--ac800": theme.c800,
          "--ac80060": withOpacity(theme.c800, 0.6),
          "--ac900": theme.c900,
          "--BorderColor": withOpacity(theme.c500, 0.15),
          "--BackgroundLightMode": withOpacity(
            applyCommunityStyling ? theme.c50 : theme.c500,
            0.2
          ),
          "--BackgroundDarkMode": withOpacity(
            applyCommunityStyling ? theme.c950 : theme.c700,
            0.2
          ),
        } as CSSProperties
      }
    >
      {p.withBorder && (
        <GradientBorder
          colors={[withOpacity(theme.c300, 0.4), withOpacity(theme.c300, 0.1)]}
          angle={33}
          offset={-2}
          borderWidth={2}
          borderRadius={24}
        />
      )}
      <RigidDotGrid color={theme.c500} cellSize={24} dotSize={1} />
      <Header>
        <IconArrayWrapper>
          <StoryIconArray story={p.story} />
        </IconArrayWrapper>
        <HeaderContent>
          <HeaderContentInner>
            {p.pillText && (
              <Pill color={theme.name} appearance="translucent">
                {p.pillText}
              </Pill>
            )}
            <h3>
              {p.withLinkToStoryPage ? (
                <Link to={`/library/stories/${p.story.id}`}>
                  {p.story.name}
                </Link>
              ) : (
                p.story.name
              )}
            </h3>
            {p.description !== undefined ? (
              p.description
            ) : (
              <p>{preventWidows(p.story.description)}</p>
            )}
            {!p.hideTools && p.story.tools.length > 0 && (
              <div>
                <h4>Tools</h4>
                <ToolList>
                  {p.story.tools.map((t, i, arr) => (
                    <Fragment key={t}>
                      <ToolLink tool={t} />
                      {i < arr.length - 1 ? ", " : ""}
                    </Fragment>
                  ))}
                </ToolList>
              </div>
            )}
            {/* {p.story.collections && p.story.collections.length > 0 && (
              <>
                <h4>
                  In collection{p.story.collections.length > 1 ? "s" : ""}
                </h4>
                <div>
                  {p.story.collections.map(c => (
                    <p key={c.slug}>
                      <CollectionLink collection={c} />
                    </p>
                  ))}
                </div>
              </>
            )} */}
            {p.story.internalAuthors.length > 0 && (
              <>
                <h4>Created by</h4>
                <AuthorListParagraph>
                  <StoryInternalAuthorsDisplay
                    story={p.story}
                    linkToLinkedInIfAvailable
                  />
                </AuthorListParagraph>
              </>
            )}
            {p.story.communityAuthors.length > 0 && (
              <>
                <h4>
                  Community author{p.story.communityAuthors.length > 1 && "s"}
                </h4>
                <AuthorListParagraph>
                  <StoryCommunityAuthorsDisplay
                    story={p.story}
                    compact
                    linkToLinkedInIfAvailable
                  />
                </AuthorListParagraph>
              </>
            )}
            {p.withLinkToStoryPage && (
              <LinkToStoryPageWrapper>
                <Link
                  to={`/library/stories/${p.story.id}?name=${p.story.slug}`}
                >
                  → View in the Library
                </Link>
              </LinkToStoryPageWrapper>
            )}
            <HeaderEnd>
              {p.asLightbox ? (
                <LightboxFooter>
                  <HowItWorksSection>
                    <p>
                      <b>How it works</b>
                    </p>
                    <p>
                      Import this story to your tenant, from where you can adapt
                      it to meet your unique needs.
                    </p>
                    {importButton}
                  </HowItWorksSection>
                  <VotingBanner
                    text="Was this story helpful?"
                    url={Paths.library.story(p.story.id, p.story.slug)}
                    compact
                  />
                </LightboxFooter>
              ) : (
                importButton
              )}
            </HeaderEnd>
          </HeaderContentInner>
        </HeaderContent>
      </Header>
      <StoryEmbedAsGallery
        story={p.story}
        flexibleRatio={p.flexibleEmbedRatio}
        disableFullScreen={p.disableFullScreen || p.asLightbox}
      />
      {p.asLightbox && !p.noCloseButton && (
        <CloseButton onClick={siteContext.library.closeStoryLightbox}>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
            <path
              opacity="0.8"
              d="M9 1L1 9M1 1L9 9"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </CloseButton>
      )}
    </StoryCardWithEmbedDiv>
  );
};

// const CollectionLink = ({
//   collection,
// }: {
//   collection: Pick<
//     DatoCmsLibraryCollection,
//     "name" | "collectionType" | "slug"
//   >;
// }) => {
//   const siteContext = useSiteContext();
//   const handleClick = () => {
//     siteContext.library.closeStoryLightbox();
//   };
//   return (
//     <Link
//       to={PathBuilder.library.collection(
//         collection.collectionType,
//         collection.slug
//       )}
//       onClick={handleClick}
//     >
//       → {collection.name}
//     </Link>
//   );
// };

const ToolLink = ({ tool }: { tool: DatoCmsLibraryTool | string }) => {
  const siteContext = useSiteContext();
  const handleClick = () => {
    siteContext.library.closeStoryLightbox();
  };
  if (isString(tool))
    return (
      <Link to={Paths.library.tool(makeSlug(tool))} onClick={handleClick}>
        {tool}
      </Link>
    );
  return (
    <Link to={Paths.library.tool(tool.slug!)} onClick={handleClick}>
      {tool.name}
    </Link>
  );
};

export default StoryCardWithEmbed;
