import { styled } from "@linaria/react";
import { EventDirectoryItem } from "../../types/helper.types";

type Props = {
  item: EventDirectoryItem;
};

const EventDirectoryItemLocationWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  svg {
    display: block;
  }
  > * + * {
    margin-left: 0.25em;
  }
`;

const EventDirectoryItemLocation = (props: Props) => {
  const { address } = props.item.place ?? {};
  const text = address
    ? [
        address.city.trim(),
        address.state.trim(),
        "usa united states of america".includes(
          address.country.trim().toLowerCase()
        )
          ? ""
          : address.country.trim(),
        props.item.distanceFromUser
          ? `${
              Math.round((props.item.distanceFromUser / 1000) * 10) / 10
            } km away`
          : "",
      ]
        .filter(i => i)
        .join(", ")
    : "";
  return text ? (
    <EventDirectoryItemLocationWrapper>
      <svg width="14" height="20" viewBox="0 0 14 20" fill="#FF4646">
        <path d="M7 19.0017C5.73694 17.9244 4.56619 16.7433 3.5 15.4707C1.9 13.5597 8.78894e-07 10.7137 8.78894e-07 8.00174C-0.00069302 6.6167 0.409509 5.26259 1.17869 4.11077C1.94788 2.95895 3.04147 2.0612 4.32107 1.53114C5.60067 1.00108 7.00875 0.86253 8.36712 1.13303C9.72548 1.40353 10.9731 2.07092 11.952 3.05074C12.6038 3.6996 13.1203 4.47128 13.4719 5.32111C13.8234 6.17095 14.0029 7.08207 14 8.00174C14 10.7137 12.1 13.5597 10.5 15.4707C9.4338 16.7432 8.26306 17.9244 7 19.0017ZM7 5.00174C6.20435 5.00174 5.44129 5.31781 4.87868 5.88042C4.31607 6.44303 4 7.20609 4 8.00174C4 8.79739 4.31607 9.56045 4.87868 10.1231C5.44129 10.6857 6.20435 11.0017 7 11.0017C7.79565 11.0017 8.55871 10.6857 9.12132 10.1231C9.68393 9.56045 10 8.79739 10 8.00174C10 7.20609 9.68393 6.44303 9.12132 5.88042C8.55871 5.31781 7.79565 5.00174 7 5.00174Z" />
      </svg>
      <span>{text}</span>
    </EventDirectoryItemLocationWrapper>
  ) : null;
};

export default EventDirectoryItemLocation;
