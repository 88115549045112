import { useEffect, useState } from "react";
import {
  DatoCmsLibraryTool,
  DatoCmsPartner,
  DatoCmsPartnerCategory,
} from "../../../graphql-types";
import { useOnMount } from "../../utils/lifeCycle.utils";
import axios from "axios";
import { formatAsSearchString } from "../../utils/search.utils";
import { SearchableEntry } from "../../types/helper.types";
import PartnerEntry from "./PartnerEntry";
import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import TextInput, { TextInputStyled } from "../forms/TextInput";
import { colors, withOpacity } from "../../styles/colors.styles";
import Spacing from "../layout/Spacing";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import { useAllLibraryTools } from "../../utils/library.utils";
import { partnerMatchesTool } from "../../utils/partners.utils";
import { Link } from "gatsby";
import { css, cx } from "linaria";
import { uniqBy } from "lodash-es";
import LoadingIndicator from "../utilities/LoadingIndicator";
import {
  fromDesktopMl,
  fromTablet,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import ListWithPagination from "../basic/ListWithPagination";

export type PartnerDirectoryEntry = SearchableEntry<
  DatoCmsPartner & {
    tool?: DatoCmsLibraryTool;
  }
>;

type Props = {
  onDataFetched?: (partners: PartnerDirectoryEntry[]) => void;
  onQueryUpdate?: (query: string) => void;
  onSearchResultUpdate?: (results: PartnerDirectoryEntry[]) => void;
  inline?: boolean;
};

const PartnerDirectoryContainer = styled.div``;

const ResultListWrap = styled.div`
  min-height: 16em;
`;

const ResultList = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(2, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${fromDesktopMl} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const SearchBarWrap = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 1fr) auto;
  position: relative;
  svg {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.25em;
    ${fromTablet} {
      left: 1.5em;
    }
    z-index: 1;
    pointer-events: none;
  }
  ${TextInputStyled} {
    position: relative;
    background-color: ${colors.lightest};
    border: 1px solid ${colors.purple100};
    min-height: 3.5em;
    font-size: 1.6rem;
    color: ${colors.purple800};
    padding-left: 2.75em;
    ${fromTablet} {
      padding-left: 3.75em;
    }
    .block & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .inline & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:hover,
    &:focus {
      outline: none;
      background-color: ${colors.purple50};
      border-color: ${colors.purple300};
    }
  }
`;

const OpenInNewTabLink = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid ${colors.purple100};
  border-radius: 0 0.5em 0.5em 0;
  margin-left: -1px;
  padding: 0.5em 1em;
  font-weight: 600;
  font-size: 1.2rem;
  ${fromTablet} {
    font-size: 1.4rem;
  }
  &:hover {
    background-color: ${colors.purple50};
    color: ${colors.purple600};
    border-color: ${colors.purple300};
    z-index: 1;
  }
`;

const FiltersWrap = styled.div`
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple100};
  border-top: 0;
  border-radius: 0 0 0.5em 0.5em;
  padding: 0.75em;
  font-size: 1.4rem;
  strong {
    display: inline-block;
    margin-right: 0.5em;
  }
`;

const CategoryPill = styled.button`
  appearance: none;
  border: none;
  padding: 0.4em 0.6em;
  min-height: 2.25em;
  font: inherit;
  color: ${colors.purple800};
  opacity: 0.7;
  font-weight: 600;
  background-color: transparent;
  border-radius: 0.5em;
  margin-left: 0.2em;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  &:hover {
    color: ${colors.purple600};
    opacity: 1;
    background-color: ${withOpacity(colors.purple50, 0.7)};
  }
  &.selected {
    color: ${colors.purple600};
    opacity: 1;
    background-color: ${colors.purple50};
    border-color: ${colors.purple100};
  }
`;

const CategoryCheckboxCountBadge = styled.span`
  margin-left: 0.5em;
  padding: 0.2em 0.5em;
  font-size: 75%;
  font-weight: 700;
  border-radius: 1em;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: 0.15;
  }
`;

const EmptyState = styled.div`
  min-height: 16em;
  padding: 2em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple100};
  border-radius: 0.5em;
  p {
    opacity: 0.5;
  }
`;

const PartnerDirectory = (props: Props) => {
  const [ready, setReady] = useState(false);
  const [partners, setPartners] = useState<PartnerDirectoryEntry[]>([]);
  const [categories, setCategories] = useState<
    (DatoCmsPartnerCategory & { count: number })[]
  >([]);
  const { tools } = useAllLibraryTools();
  const fetchData = async () => {
    const { data } = await axios.get<{
      partners: DatoCmsPartner[];
    }>(`/api/partners/list`);
    const partnersWithSearchString = data.partners
      .map(p => {
        return {
          ...p,
          __searchString: formatAsSearchString(
            [p.name, p.slug, p.summary, p.searchKeywords]
              .filter(i => i)
              .join("__")
          ),
        };
      })
      .sort((a, b) => {
        const priority =
          parseFloat(b.priority || "10") - parseFloat(a.priority || "10");
        if (priority !== 0) return priority;
        return Math.random() - 0.5;
      });
    setPartners(partnersWithSearchString);
  };
  const formState = useState({
    query: "",
  });
  const query = formState[0].query;
  const formattedQuery =
    query.length > 1 ? formatAsSearchString(formState[0].query) : "";
  const [selectedCategories, _setSelectedCategories] = useState<string[]>([]);
  const showAllCategories =
    !selectedCategories.length ||
    categories.every(c => selectedCategories.includes(c.slug!));
  const selectCategories = (filters: string[]) => {
    _setSelectedCategories(filters);
    if (filters.length === 0) removeUrlQueryParam("category");
    else setUrlQueryParam("category", filters);
  };
  const toggleCategory = (c: DatoCmsPartnerCategory) => {
    selectCategories([c.slug!]);
    // // use below to enable multi-select
    // if (selectedCategories.includes(c.slug!)) {
    //   selectCategories(removeOneFromArray(selectedCategories, c.slug!));
    // } else {
    //   selectCategories([...selectedCategories, c.slug!]);
    // }
  };
  const clearFilters = () => {
    selectCategories([]);
  };
  useEffect(() => {
    props.onQueryUpdate?.(formattedQuery);
    if (!ready) return;
    if (!query) removeUrlQueryParam("s");
    else setUrlQueryParam("s", query);
  }, [query, props, formattedQuery, ready]);
  useOnMount(() => {
    const { category, s } = getUrlQueryParams();
    if (category) selectCategories(category.split(","));
    if (s) formState[1]({ query: s });
    fetchData();
  });
  const partnersMatchingQuery = formattedQuery
    ? partners.filter(p => p.__searchString.includes(formattedQuery))
    : props.inline
    ? []
    : partners;
  const results = showAllCategories
    ? partnersMatchingQuery
    : partnersMatchingQuery.filter(p => {
        return p.category?.find(c => c && selectedCategories.includes(c.slug!));
      });
  useEffect(() => {
    props.onSearchResultUpdate?.(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.map(r => r.id)]);
  useEffect(() => {
    if (!partners.length || !tools.length) return;
    partners.forEach(partner => {
      partner.tool = tools.find(t => partnerMatchesTool(partner, t));
    });
    setPartners(partners);
    const uniqueCategories = uniqBy(
      partners
        .map(p => p.category)
        .filter(i => i)
        .flat() as DatoCmsPartnerCategory[],
      c => c.slug
    )
      .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .map(c => ({
        ...c,
        count: partners.filter(p =>
          p.category?.find(cat => cat && cat.slug === c.slug)
        ).length,
      }));
    setCategories(uniqueCategories);
    props.onDataFetched?.(partners);
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners.length, tools.length]);

  return (
    <PartnerDirectoryContainer
      className={cx(props.inline ? "inline" : "block")}
    >
      <SearchBarWrap id="search-bar">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6ZM9.16437 9.87147C8.30243 10.5768 7.20063 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 7.20063 10.5768 8.30243 9.87147 9.16437L14.3536 13.6464C14.5488 13.8417 14.5488 14.1583 14.3536 14.3536C14.1583 14.5488 13.8417 14.5488 13.6464 14.3536L9.16437 9.87147Z"
            fill="currentColor"
          />
        </svg>
        <TextInput
          formState={formState}
          name="query"
          placeholder="Search for a partner…"
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          autoFocus={!props.inline}
        />
        {!props.inline ? null : (
          <Link
            className={OpenInNewTabLink}
            to={`/partners/directory${
              formattedQuery ? `?=${formState[0].query}` : ""
            }`}
          >
            Go to directory →
          </Link>
        )}
      </SearchBarWrap>
      {!props.inline && (
        <FiltersWrap>
          {/* <strong>Show categories: </strong> */}
          <CategoryPill
            className={cx(showAllCategories && "selected")}
            onClick={clearFilters}
          >
            <span>All partners</span>{" "}
            {ready && (
              <CategoryCheckboxCountBadge>
                {partners.length}
              </CategoryCheckboxCountBadge>
            )}
          </CategoryPill>
          {categories.map(c => (
            <CategoryPill
              key={c.slug}
              className={cx(selectedCategories.includes(c.slug!) && "selected")}
              onClick={() => toggleCategory(c)}
            >
              <span>
                {c.name}
                {c.count > 1 ? "s" : ""}
              </span>{" "}
              <CategoryCheckboxCountBadge>{c.count}</CategoryCheckboxCountBadge>
            </CategoryPill>
          ))}
        </FiltersWrap>
      )}
      <Spacing size="gap" />
      {ready ? (
        <>
          {results.length > 0 ? (
            <ListWithPagination
              defaultPerPage={20}
              allowCustomPerPageCount
              entries={results}
              scrollToHashOnNavigation="#search-bar"
              appearance="outlined"
              children={entries => {
                return (
                  <ResultListWrap>
                    <ResultList>
                      {entries.map(partner => (
                        <PartnerEntry
                          partner={partner}
                          key={partner.id}
                          tool={partner.tool}
                        />
                      ))}
                    </ResultList>
                  </ResultListWrap>
                );
              }}
            />
          ) : !props.inline ? (
            formattedQuery ? (
              <EmptyState>
                <p>No partners matching your query.</p>
              </EmptyState>
            ) : (
              <EmptyState>
                <p>No partners found.</p>
              </EmptyState>
            )
          ) : null}
        </>
      ) : props.inline ? null : (
        <EmptyState>
          <LoadingIndicator size={24} />
        </EmptyState>
      )}
    </PartnerDirectoryContainer>
  );
};

export default PartnerDirectory;
