import ContentCardBase from "./_ContentCardBase";
import img from "../../../static/images/tines-university-illustration.png";
import img2x from "../../../static/images/tines-university-illustration@2x.png";
import { css } from "linaria";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { brandColorThemeVar } from "../../styles/colors.styles";

const style = css`
  ${fromTabletMd} {
    background-image: url(${img});
    background-size: cover;
    background-position: 66% 50%;
    @media (min-device-pixel-ratio: 1.5) {
      background-image: url(${img2x});
    }
    @media (-webkit-min-device-pixel-ratio: 1.5) {
      background-image: url(${img2x});
    }
    @media (-moz-min-device-pixel-ratio: 1.5) {
      background-image: url(${img2x});
    }
    figure {
      display: none;
    }
  }
  [data-path="/"] & {
    border-color: ${brandColorThemeVar(700)};
    &:hover {
      border-color: ${brandColorThemeVar(800)};
    }
  }
`;

const ContentCardUniversity = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  return (
    <ContentCardBase
      className={style}
      link="/university"
      color="purple"
      variant="50"
      large={large}
      size="1/2"
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Learn"}</strong>
          </p>
          <h4>{overrides?.heading || "Tines University"}</h4>
          <ReactMarkdown>
            {overrides?.description ||
              "Build your knowledge with dedicated learning paths at all levels."}
          </ReactMarkdown>
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Start learning"}</strong>
        </p>
      </header>
      <figure>
        <UniversityIconMobile />
      </figure>
    </ContentCardBase>
  );
};

export default ContentCardUniversity;

const UniversityIconMobile = () => (
  <svg
    width="69"
    height="59"
    viewBox="0 0 69 59"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    stroke="#4D3E78"
    strokeWidth={0.8}
  >
    <path
      d="M67.954 53.3711H11.3286V57.6413H63.3339L67.954 53.3711Z"
      fill="#4D3E78"
    />
    <path
      d="M18.8139 50.1204C18.8139 48.6512 17.6224 47.46 16.1533 47.46H8.02286C7.24024 47.46 6.53644 47.7981 6.04962 48.336C5.62255 48.8081 2.2361 52.1449 1.75255 52.6422C1.28674 53.1214 1.00049 53.7754 1.00049 54.4962V57.6414H14.4514L18.8141 53.2655L18.8139 50.1204Z"
      fill="#8D75E6"
    />
    <path
      d="M14.4509 57.6416V54.4964C14.4509 53.0271 13.2603 51.8359 11.7911 51.8359H3.66048C2.19092 51.8359 1 53.0271 1 54.4964V57.6416H14.4509Z"
      fill="#C2AAFA"
    />
    <path d="M13.769 52.7175L18.0821 48.3882" />
    <path
      d="M39.3789 50.1209C39.3789 48.6517 38.1877 47.4604 36.7182 47.4604H28.588C27.8052 47.4604 27.1013 47.7986 26.6147 48.3365C26.1877 48.8086 22.8012 52.1454 22.3175 52.6427C21.8518 53.1219 21.5654 53.7759 21.5654 54.4967V57.6419H35.0165L39.3789 53.266V50.1209Z"
      fill="#8D75E6"
    />
    <path
      d="M35.0165 57.6416V54.4964C35.0165 53.0271 33.8259 51.8359 32.3566 51.8359H24.2259C22.7565 51.8359 21.5654 53.0271 21.5654 54.4964V57.6416H35.0165Z"
      fill="#C2AAFA"
    />
    <path d="M34.3345 52.7175L38.6478 48.3882" />
    <path
      d="M59.9451 50.1209C59.9451 48.6517 58.7539 47.4604 57.2845 47.4604H49.1541C48.3716 47.4604 47.6676 47.7986 47.1808 48.3365C46.7539 48.8086 43.3676 52.1454 42.8839 52.6427C42.4182 53.1219 42.1318 53.7759 42.1318 54.4967V57.6419H55.5826L59.9453 53.266L59.9451 50.1209Z"
      fill="#8D75E6"
    />
    <path
      d="M55.5826 57.6416V54.4964C55.5826 53.0271 54.3923 51.8359 52.923 51.8359H44.7923C43.3229 51.8359 42.1318 53.0271 42.1318 54.4964V57.6416H55.5826Z"
      fill="#C2AAFA"
    />
    <path d="M54.9009 52.7175L59.214 48.3882" />
    <path
      d="M16.2671 49.5433C16.2672 49.5433 16.2669 49.5439 16.2661 49.5452C16.2667 49.5439 16.2671 49.5433 16.2671 49.5433ZM16.13 49.524C16.1718 49.5514 16.2014 49.5745 16.2221 49.5926C16.2014 49.6108 16.1718 49.6338 16.13 49.6613C15.9632 49.7707 15.6889 49.8885 15.305 49.995C14.5444 50.2061 13.4652 50.343 12.253 50.343C11.0407 50.343 9.96146 50.2061 9.20087 49.995C8.81705 49.8885 8.54269 49.7707 8.37591 49.6613C8.33407 49.6338 8.30452 49.6108 8.28386 49.5926C8.30452 49.5745 8.33407 49.5514 8.37591 49.524C8.54269 49.4146 8.81705 49.2968 9.20087 49.1903C9.96146 48.9792 11.0407 48.8423 12.253 48.8423C13.4652 48.8423 14.5444 48.9792 15.305 49.1903C15.6889 49.2968 15.9632 49.4146 16.13 49.524ZM8.23879 49.5433C8.23883 49.5433 8.23921 49.5439 8.23978 49.5452C8.23903 49.5439 8.23874 49.5433 8.23879 49.5433ZM8.23879 49.642C8.23874 49.642 8.23903 49.6414 8.23978 49.6401C8.23921 49.6414 8.23883 49.642 8.23879 49.642ZM16.2661 49.6401C16.2669 49.6414 16.2672 49.642 16.2671 49.642C16.2671 49.642 16.2667 49.6414 16.2661 49.6401Z"
      fill="#4D3E78"
    />
    <path
      d="M36.7513 49.5433C36.7514 49.5433 36.7511 49.5439 36.7503 49.5452C36.7509 49.5439 36.7513 49.5433 36.7513 49.5433ZM36.6142 49.524C36.656 49.5515 36.6856 49.5745 36.7062 49.5926C36.6856 49.6108 36.656 49.6338 36.6142 49.6613C36.4474 49.7707 36.173 49.8885 35.7892 49.995C35.0286 50.2061 33.9493 50.343 32.737 50.343C31.5247 50.343 30.4454 50.2061 29.6848 49.995C29.301 49.8885 29.0266 49.7707 28.8598 49.6613C28.818 49.6338 28.7884 49.6108 28.7677 49.5926C28.7884 49.5745 28.818 49.5515 28.8598 49.524C29.0266 49.4146 29.301 49.2968 29.6848 49.1903C30.4454 48.9792 31.5247 48.8423 32.737 48.8423C33.9493 48.8423 35.0286 48.9792 35.7892 49.1903C36.173 49.2968 36.4474 49.4146 36.6142 49.524ZM28.7227 49.5433C28.7227 49.5433 28.7231 49.5439 28.7237 49.5452C28.7229 49.5439 28.7226 49.5433 28.7227 49.5433ZM28.7227 49.642C28.7226 49.642 28.7229 49.6414 28.7237 49.6401C28.7231 49.6414 28.7227 49.642 28.7227 49.642ZM36.7503 49.6401C36.7511 49.6414 36.7514 49.642 36.7513 49.642C36.7513 49.642 36.7509 49.6414 36.7503 49.6401Z"
      fill="#4D3E78"
    />
    <path
      d="M57.2362 49.5433C57.2362 49.5433 57.2359 49.5439 57.2352 49.5452C57.2357 49.5439 57.2361 49.5433 57.2362 49.5433ZM57.099 49.524C57.1409 49.5515 57.1704 49.5745 57.1911 49.5926C57.1704 49.6108 57.1409 49.6338 57.099 49.6613C56.9323 49.7707 56.6579 49.8885 56.274 49.995C55.5134 50.2061 54.4341 50.343 53.2218 50.343C52.0096 50.343 50.9303 50.2061 50.1697 49.995C49.7858 49.8885 49.5114 49.7707 49.3447 49.6613C49.3028 49.6338 49.2733 49.6108 49.2526 49.5926C49.2733 49.5745 49.3028 49.5515 49.3447 49.524C49.5114 49.4146 49.7858 49.2968 50.1697 49.1903C50.9303 48.9792 52.0096 48.8423 53.2218 48.8423C54.4341 48.8423 55.5134 48.9792 56.274 49.1903C56.6579 49.2968 56.9323 49.4146 57.099 49.524ZM49.2075 49.5433C49.2076 49.5433 49.208 49.5439 49.2085 49.5452C49.2078 49.5439 49.2075 49.5433 49.2075 49.5433ZM49.2075 49.642C49.2075 49.642 49.2078 49.6414 49.2085 49.6401C49.208 49.6414 49.2076 49.642 49.2075 49.642ZM57.2352 49.6401C57.2359 49.6414 57.2362 49.642 57.2362 49.642C57.2361 49.642 57.2357 49.6414 57.2352 49.6401Z"
      fill="#4D3E78"
    />
    <path
      d="M5.55908 49.294C5.55908 49.8731 7.6603 50.3423 10.2519 50.3423C12.8434 50.3423 14.9443 49.8731 14.9443 49.294V22.5362C14.9443 21.9573 12.8433 21.4878 10.2519 21.4878C7.66015 21.4878 5.55908 21.9572 5.55908 22.5362V49.294Z"
      fill="#C2AAFA"
    />
    <path
      d="M26.125 49.294C26.125 49.8731 28.2261 50.3423 30.8174 50.3423C33.4092 50.3423 35.5101 49.8731 35.5101 49.294V22.5362C35.5101 21.9573 33.409 21.4878 30.8174 21.4878C28.2259 21.4878 26.125 21.9572 26.125 22.5362V49.294Z"
      fill="#C2AAFA"
    />
    <path
      d="M46.6914 49.294C46.6914 49.8731 48.7922 50.3423 51.3838 50.3423C53.9754 50.3423 56.0765 49.8731 56.0765 49.294V22.5362C56.0765 21.9573 53.9754 21.4878 51.3838 21.4878C48.7922 21.4878 46.6914 21.9572 46.6914 22.5362V49.294Z"
      fill="#C2AAFA"
    />
    <path
      d="M10.252 27.047C8.63309 27.047 7.1636 26.9326 6.04395 26.7476V25.0103H14.4812V26.7441C13.3589 26.9311 11.8811 27.047 10.252 27.047Z"
      fill="#4D3E78"
    />
    <path
      d="M30.9019 27.047C29.283 27.047 27.8135 26.9326 26.6938 26.7476V25.0103H35.1311V26.7441C34.0089 26.9311 32.531 27.047 30.9019 27.047Z"
      fill="#4D3E78"
    />
    <path
      d="M51.5534 27.047C49.9345 27.047 48.4649 26.9326 47.3452 26.7476V25.0103H55.7825V26.7441C54.6603 26.9311 53.1824 27.047 51.5534 27.047Z"
      fill="#4D3E78"
    />
    <path
      d="M16.8573 22.0927C16.8573 21.3875 13.8998 20.8159 10.2514 20.8159C6.6031 20.8159 3.64551 21.3875 3.64551 22.0927C3.64551 22.5138 3.64551 23.7255 3.64551 24.1468C3.64551 24.8519 6.6031 25.4237 10.2514 25.4237C13.8998 25.4237 16.8573 24.8521 16.8573 24.1468C16.8573 23.7257 16.8573 22.5138 16.8573 22.0927Z"
      fill="#5ABE89"
    />
    <path
      d="M16.8573 19.7945C16.8573 19.0893 13.8998 18.5176 10.2514 18.5176C6.6031 18.5176 3.64551 19.0892 3.64551 19.7945C3.64551 20.2156 3.64551 21.4273 3.64551 21.8486C3.64551 22.5537 6.6031 23.1254 10.2514 23.1254C13.8998 23.1254 16.8573 22.5537 16.8573 21.8486C16.8573 21.4273 16.8573 20.2154 16.8573 19.7945Z"
      fill="#C2AAFA"
    />
    <path
      d="M10.2623 21.0711C13.9107 21.0711 16.8683 20.4994 16.8683 19.7943C16.8683 19.0892 13.9107 18.5176 10.2623 18.5176C6.61387 18.5176 3.65625 19.0892 3.65625 19.7943C3.65625 20.4994 6.61387 21.0711 10.2623 21.0711Z"
      fill="#C2AAFA"
    />
    <path
      d="M37.4233 22.0927C37.4233 21.3875 34.4657 20.8159 30.8173 20.8159C27.1689 20.8159 24.2114 21.3875 24.2114 22.0927C24.2114 22.5138 24.2114 23.7255 24.2114 24.1468C24.2114 24.8519 27.1689 25.4237 30.8173 25.4237C34.4657 25.4237 37.4233 24.8521 37.4233 24.1468C37.4233 23.7257 37.4233 22.5138 37.4233 22.0927Z"
      fill="#FD975D"
    />
    <path
      d="M37.4233 19.7945C37.4233 19.0893 34.4657 18.5176 30.8173 18.5176C27.1689 18.5176 24.2114 19.0892 24.2114 19.7945C24.2114 20.2156 24.2114 21.4273 24.2114 21.8486C24.2114 22.5537 27.1689 23.1254 30.8173 23.1254C34.4657 23.1254 37.4233 22.5537 37.4233 21.8486C37.4233 21.4273 37.4233 20.2154 37.4233 19.7945Z"
      fill="#C2AAFA"
    />
    <path
      d="M30.8175 21.0711C34.4659 21.0711 37.4235 20.4994 37.4235 19.7943C37.4235 19.0892 34.4659 18.5176 30.8175 18.5176C27.1691 18.5176 24.2114 19.0892 24.2114 19.7943C24.2114 20.4994 27.1691 21.0711 30.8175 21.0711Z"
      fill="#C2AAFA"
    />
    <path
      d="M57.9894 22.0927C57.9894 21.3875 55.0318 20.8159 51.3834 20.8159C47.735 20.8159 44.7773 21.3875 44.7773 22.0927C44.7773 22.5138 44.7773 23.7255 44.7773 24.1468C44.7773 24.8519 47.735 25.4237 51.3834 25.4237C55.0318 25.4237 57.9894 24.8521 57.9894 24.1468C57.9894 23.7257 57.9894 22.5138 57.9894 22.0927Z"
      fill="#F486B8"
    />
    <path
      d="M57.9894 19.7945C57.9894 19.0893 55.0318 18.5176 51.3834 18.5176C47.735 18.5176 44.7773 19.0892 44.7773 19.7945C44.7773 20.2156 44.7773 21.4273 44.7773 21.8486C44.7773 22.5537 47.735 23.1254 51.3834 23.1254C55.0318 23.1254 57.9894 22.5537 57.9894 21.8486C57.9894 21.4273 57.9894 20.2154 57.9894 19.7945Z"
      fill="#C2AAFA"
    />
    <path
      d="M51.3834 21.0711C55.0318 21.0711 57.9894 20.4994 57.9894 19.7943C57.9894 19.0892 55.0318 18.5176 51.3834 18.5176C47.735 18.5176 44.7773 19.0892 44.7773 19.7943C44.7773 20.4994 47.735 21.0711 51.3834 21.0711Z"
      fill="#C2AAFA"
    />
    <path
      d="M55.866 20.0155L56.6812 19.4902L57.1171 19.7946C57.1129 19.7959 57.1086 19.7972 57.1044 19.7985C56.7686 19.8991 56.3259 19.969 55.866 20.0155Z"
      fill="#4D3E78"
    />
    <path
      d="M4.43919 15.1729L2.21875 16.4496V17.9287C2.21875 19.398 3.40952 20.5892 4.87879 20.5892H54.1415C54.6548 20.5892 55.1336 20.4431 55.5394 20.1909C55.8948 19.9699 57.7662 19.0047 58.2362 18.5388C58.7217 18.0573 59.0222 17.39 59.0222 16.6518V15.1729H4.43919Z"
      fill="#4D3E78"
    />
    <path
      d="M2.21875 16.4502V17.9293C2.21875 19.3986 3.40952 20.5898 4.87879 20.5898H54.1415C55.6109 20.5898 56.8019 19.3984 56.8019 17.9293V16.4502H2.21875Z"
      fill="#8D75E6"
    />
    <path
      d="M58.9659 15.1736L31.5805 1L4.28139 15.1736L2.2876 16.4504H56.7982L58.9659 15.1736Z"
      fill="#8D75E6"
    />
    <path
      d="M56.7982 16.4499H2.2876L29.4128 2.27637L56.7982 16.4499Z"
      fill="#C2AAFA"
    />
  </svg>
);
