import Pill from "../../basic/Pill";
import ReportSection from "../ReportSection";
import { Serif } from "../../typography/Serif";
import SpotIllustration from "../../illustrations/SpotIllustration";
import ReportSummaryCard from "../ReportSummaryCard";
import ReportCaseStudyCard from "../ReportCaseStudyCard";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import caseStudyImage from "../../../../static/images/reports/tines-broad-institute-of-mit-and-harvard.png";
import Spacing from "../../layout/Spacing";
import ReportCardWithSpotIllustration from "../ReportCardWithSpotIllustration";
import { Separator } from "../../utilities/Hr";
import { Columned } from "../../layout/Columned";

const NoCodePlaybookChapter4 = () => {
  return (
    <ReportSection
      id="five-best-practices"
      themeName="green"
      smallerSectionHeading
      header={
        <>
          <div>
            <SpotIllustration name="chainedRings" />
          </div>
          <Pill color="green">Part Four</Pill>
          <h2>
            Five best practices to
            <br />
            <Serif>increase your chances of success</Serif>
          </h2>
          <p>
            As you get up and running with your no-code platform, here are some
            best practices to help everyone acclimate and become more
            successful.
          </p>
        </>
      }
    >
      <ReportCardWithSpotIllustration
        illustration="plantWithStickies"
        largerHeading
      >
        <Pill color="green">Best practice 1</Pill>
        <h3>Annotate</h3>
        <p>
          Your no-code automation workflows should be understandable to the
          other humans on your team. A great way to achieve this is by leaving
          sticky notes beside each step of your automated workflow to provide
          your colleagues with more context.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration
        illustration="templateBlocks"
        largerHeading
      >
        <Pill color="green">Best practice 2</Pill>
        <h3>Modularize</h3>
        <p>
          As you automate more workflows, you’ll inevitably repeat certain
          steps. For instance “post a message to the team Slack in this format”.
          Extract shared sequences to modules that can be reused across other
          workflows.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration illustration="lamp" largerHeading>
        <Pill color="green">Best practice 3</Pill>
        <h3>Monitor</h3>
        <p>
          Certain automation workflows can be mission critical and it is
          important to know when something unexpected has happened. Set up
          monitoring to make sure somebody gets notified when any of your tools
          within a workflow has an issue — for example, the API for an upstream
          system is down, a credential expires, or no alerts have been received
          recently.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration
        illustration="forkingRoutes"
        largerHeading
      >
        <Pill color="green">Best practice 4</Pill>
        <h3>Evolve</h3>
        <p>
          The best automation workflows are continuously improved. From time to
          time, analyze a random workflow run and creatively think about what
          could make the task faster or the outputs more useful.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration
        illustration="retroMobilePhone"
        largerHeading
      >
        <Pill color="green">Best practice 5</Pill>
        <h3>Communicate value</h3>
        <p>
          After investing in automation, it’s important to demonstrate value to
          leadership. For previously manual processes, a good system is to
          estimate the number of minutes each step used to cost, and track the
          accumulated data of actual human hours saved (ideally automatically in
          the platform itself).
        </p>
      </ReportCardWithSpotIllustration>

      <ReportSummaryCard
        backgroundColor={colorsV4.green700}
        textColor={colorsV4.white}
      >
        <blockquote>
          <p>
            Being mindful of these common misconceptions can streamline your
            ability to get a high-impact no-code security automation program up
            and running. Next, we’ll explore the best practices that can improve
            your chances of success.
          </p>
        </blockquote>
      </ReportSummaryCard>

      <ReportCaseStudyCard color="green" imageSrc={caseStudyImage}>
        <h2>
          How the Broad Institute of MIT and Harvard’s Security Team Used
          No-Code Automation to Slash Its Alert Remediation Time to Seconds
        </h2>
        <Separator />
        <Spacing size="0.25em" />
        <h3>The challenge</h3>
        <Columned>
          <p>
            The Broad does big things at scale. Protecting vast amounts of
            sensitive data while honoring the institute’s founding principles
            produces complex security challenges. Additionally, information
            about their system, network, and users is found across their
            infrastructure, so determining a system flow or the risk factor of
            any particular system that throws an alert takes a lot of time and
            effort.
          </p>
        </Columned>
        <h3>The impact of Tines no-code security automation platform</h3>
        <Columned>
          <p>
            According to Will Hedglon, Associate Director of Information
            Security, Broad Institute of MIT and Harvard: “We realized we needed
            some type of SOAR platform to ingest security intel, make good
            decisions, and then take actions based on that intel and those
            decisions. The thing that drew us to Tines is that you just plug
            APIs into it, and off you go; we aren’t locked into a particular
            vendor, we don’t have to worry too much about integrating with
            various platforms. If it has an API, we can do cool stuff with it,
            so that was the big thrust of why we were interested in Tines.
            We played around with it for a few weeks and realized this could
            be really great.
          </p>
          <p>
            “I’m a very visual learner, so to be able to see everything, the
            different actions, and modules, is very powerful for me. We could
            write code to do some of what Tines does, but the amount of time and
            energy to do error checking and have everything broken out in a way
            that’s understandable to new team members would be impossible. Tines
            perfectly fit that gap of making it easy for us to use it, making
            our workflows understandable, providing really granular error
            checking, and enabling us to see exactly where something goes wrong.
            The approach to secrets management also gave us a lot of confidence.
          </p>
          <p>
            “Pre-Tines, we had a manual, resource-intensive workflow to get an
            initial alerting from Google Cloud Security Command Center (SCC).
            For example, if somebody just made a bucket public, my team then
            spent a lot of time enriching that information to figure out who
            flipped the bit to make the bucket public, and which team that
            person is on, and who is that person’s manager, and all of this
            important enriching data. Then we would create a Jira ticket and
            find the proper outreach communications to talk to the user to
            understand if they intentionally made the bucket public, and then
            wrangle remediation. Each step took some manual intervention and the
            turnaround time was weeks or sometimes even months. Post Tines, we
            automate that all away, and users get a nice little Slack message
            within seconds of flipping a bucket public to remediate it.”
          </p>
        </Columned>
      </ReportCaseStudyCard>
    </ReportSection>
  );
};

export default NoCodePlaybookChapter4;
