import { Link, graphql } from "gatsby";
import SEO from "../components/utilities/SEO";
import { PageComponentProps } from "../types/gatsby.types";
import Page from "./Page.template";
import {
  getKnownLearningPathConfig,
  useUniTreeWithProgress,
} from "../utils/university.utils";
import {
  brandColorThemeVar,
  getBrandColorTheme,
  withOpacity,
} from "../styles/colors.styles";
import { UniLearningPathCoverPageByIdQuery } from "../../graphql-types";
import { styled } from "@linaria/react";
import { serif } from "../styles/fonts.styles";
import AllCaps from "../components/typography/AllCaps";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import Spacing from "../components/layout/Spacing";
import PageSection from "../components/reusableSections/PageSection";
import Button from "../components/forms/Button";
import UniProgressHint from "../components/hub/university/UniProgressHint";
import { rSize } from "../styles/responsiveSizes.styles";
import { widthInGrid } from "../constants/globalGrid.constants";
import { GridCanvas } from "../components/decorations/GridCanvas";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  onlyPhones,
  uptoDesktop,
  uptoTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import { Paths } from "../utils/pathBuilders.utils";
import { css } from "linaria";
import { preventWidows } from "../utils/typography.utils";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { PageTree, TreePage } from "../utils/tree.utils";

type Props = PageComponentProps<{
  pageContext: {
    id: string;
    outline: TreePage[];
    uniTree: PageTree;
  };
  data: UniLearningPathCoverPageByIdQuery;
}>;

const TopSpacer = styled.div`
  ${fromTablet} {
    height: 2em;
  }
  ${fromDesktop} {
    height: 3em;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  height: 580px;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${brandColorThemeVar(200)};
  ${onlyPhones} {
    display: none;
  }
`;

const CardStack = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -8px;
    left: 8px;
    right: 8px;
    height: 24px;
    background-color: ${brandColorThemeVar(100)};
    border: 1px solid ${brandColorThemeVar(400)};
    border-radius: ${rSize("radius")};
    z-index: 2;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -16px;
    left: 16px;
    right: 16px;
    height: 24px;
    background-color: ${brandColorThemeVar(200)};
    border: 1px solid ${brandColorThemeVar(400)};
    border-radius: ${rSize("radius")};
    z-index: 1;
  }
`;

const Card = styled.div`
  position: relative;
  background-color: ${brandColorThemeVar(50)};
  border: 1px solid ${brandColorThemeVar(400)};
  border-radius: ${rSize("radius")};
  display: grid;
  z-index: 3;
  ${fromTabletMd} {
    grid-template-columns: auto minmax(0, 1fr);
  }
`;

const Figure = styled.div`
  padding: 4em;
  text-align: center;
  ${fromTabletMd} {
    width: 225px;
  }
  ${fromDesktopMd} {
    width: ${widthInGrid(3, 1)};
  }
  ${uptoTabletMd} {
    border-bottom: 1px solid ${brandColorThemeVar(400)};
  }
  ${fromTabletMd} {
    padding-top: 8em;
    border-right: 1px solid ${brandColorThemeVar(400)};
  }
  ${fromDesktopMd} {
    padding-top: 10em;
    padding-bottom: 10em;
    svg {
      width: 135px;
      height: auto;
      path[stroke] {
        stroke-width: 0.75;
      }
    }
  }
`;

const CardBody = styled.div`
  > * {
    padding: 2em;
    ${fromTablet} {
      padding: 3em 4em;
    }
    ${fromDesktop} {
      padding: 4em ${widthInGrid(1, 1)};
    }
  }
`;

const Header = styled.header`
  border-bottom: 1px dashed ${brandColorThemeVar(400)};
  h1 {
    font-family: ${serif};
    font-weight: 300;
    font-size: 4.2rem;
    ${fromTablet} {
      font-size: 5.2rem;
    }
    ${fromDesktopMd} {
      font-size: 6.4rem;
    }
    line-height: 1;
  }
  > * + * {
    margin-top: ${rSize("gap")};
  }
`;

const uniLinkCss = css`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Description = styled.p`
  text-wrap: pretty; // supported in Chrome but not in safari
  max-width: 33em;
`;

const ButtonRow = styled.div`
  ${uptoDesktop} {
    > * {
      + * {
        margin-top: ${rSize("gap")};
      }
    }
  }
  ${fromDesktop} {
    display: flex;
    align-items: center;
    > * {
      + * {
        margin-left: ${rSize("gap")};
      }
    }
  }
  margin-top: 2em;
  p {
    max-width: 24em;
    text-wrap: balance; // supported in Chrome but not in safari
  }
`;

const Content = styled.div`
  line-height: 1.2;
  font-size: 1.4rem;
  > * {
    max-width: 30em;
    + * {
      margin-top: 0.5em;
    }
    + h4 {
      margin-top: 1em;
    }
  }
  h4 {
    font-size: inherit;
    font-weight: 700;
    span {
      font-weight: 500;
    }
  }
  ul {
    ${fromDesktop} {
      columns: 2;
      column-gap: 2em;
    }
    padding-left: 1em;
    margin-top: 1em;
  }
  li {
    margin-bottom: 0.5em;
    font-weight: 500;
    break-inside: avoid;
  }
`;

const UniLearningPathCoverPage = (props: Props) => {
  const treeWithProgress = useUniTreeWithProgress(props.pageContext.uniTree);
  const knownConfig = getKnownLearningPathConfig(props.pageContext.id);
  const theme = getBrandColorTheme(knownConfig?.color);
  const page = props.data.page!;
  const title = page.title ?? knownConfig?.title;
  const description = page.excerpt;
  const Icon = knownConfig?.icon ?? (() => <></>);
  const outline = props.pageContext.outline;
  const lessons = treeWithProgress.find(c => c.tree.path.id === page.id)!.tree
    .children;
  const firstUncompletedLesson = lessons.find(c => !c.completionTime);
  const linkUrl = firstUncompletedLesson?.path ?? lessons[0]?.path;
  const hasStarted = lessons.some(c => !!c.completionTime);
  const hasCompleted = lessons.every(c => !!c.completionTime);
  const toShowInOutline = outline.filter(
    c => c.title !== "Next steps" && c.title !== "Core objectives"
  );
  return (
    <Page
      {...props}
      textColor={theme.c800}
      linkAccentColor={theme.c500}
      navThemeColor={theme.navTheme}
      theme={theme.name}
    >
      <SEO
        title={`${title} | Tines University`}
        image={knownConfig?.image}
        description={description}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="universityLesson"
        recordId={page.id}
      />
      <SiteNavHeightPlaceholder />
      <TopSpacer />
      <Backdrop>
        <GridCanvas
          backgroundColor={withOpacity(theme.c50, 0.5)}
          color={theme.c100}
        />
      </Backdrop>
      <PageSection>
        <CardStack>
          <Card>
            <Figure
              style={{
                backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
                  [
                    `<svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>`,
                    `<line x1='35.5' y1='0' x2='35.5' y2='72' stroke='${theme.c200}' />`,
                    `<line x1='0' y1='35.5' x2='72' y2='35.5' stroke='${theme.c200}' />`,
                    `</svg>`,
                  ].join("")
                )}")`,
              }}
            >
              <Icon />
            </Figure>
            <CardBody>
              <Header>
                <AllCaps>
                  <Link className={uniLinkCss} to={Paths.university()}>
                    Tines University
                  </Link>
                </AllCaps>
                <h1>{title}</h1>
                <Description>{description}</Description>
                <ButtonRow>
                  {linkUrl && (
                    <Button darker to={linkUrl}>
                      {hasStarted
                        ? hasCompleted
                          ? "Start learning"
                          : "Continue course →"
                        : "Start course"}
                    </Button>
                  )}
                  <UniProgressHint />
                </ButtonRow>
              </Header>
              <Content>
                <h4>
                  Estimated time: <span>30 minutes</span>
                </h4>
                <h4>In this course:</h4>
                <ul>
                  {toShowInOutline.map(p => (
                    <li key={p.id}>{preventWidows(p.title)}</li>
                  ))}
                </ul>
              </Content>
            </CardBody>
          </Card>
        </CardStack>
      </PageSection>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const uniLearningPathCoverPageByIdQuery = graphql`
  query UniLearningPathCoverPageById($id: String) {
    page: datoCmsLesson(originalId: { eq: $id }) {
      id: originalId
      title
      slug
      excerpt
      estimatedTime
    }
  }
`;

export default UniLearningPathCoverPage;
