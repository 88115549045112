import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import { rSize, rSizeNoCalc } from "../../../styles/responsiveSizes.styles";
import { ConnectorPathWaypoint } from "../../decorations/ConnectorPaths";
import { ActionCard } from "../../decorations/storyboard/ActionCard";
import Spacing from "../../layout/Spacing";
import { AIActionDetails } from "./AIActionDetails";
import { getWidthPxInMaxGrid } from "../../../constants/globalGrid.constants";
import { fromDesktopMd } from "../../../styles/breakpointsAndMediaQueries.styles";

const HeroSectionAIActionBlock = styled.div`
  position: absolute;
  bottom: 0;
  top: 4em;
  left: calc(50% + ${rSizeNoCalc("gap", 0.5)});
  right: 0;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  padding-left: ${getWidthPxInMaxGrid(3)};
  display: none;
  ${fromDesktopMd} {
    display: block;
  }
`;
const AIActionDetailsWrap = styled.div`
  position: relative;
  z-index: 2;
  > * {
    margin-left: -1.3rem;
  }
`;
const ReceiveEmailActionWrap = styled.div`
  position: relative;
  text-align: right;
`;
const AIActionWrap = styled.div`
  position: relative;
  text-align: left;
  padding-left: ${rSize("gap")};
`;

export const AIActionIllustratedBlock = () => {
  return (
    <HeroSectionAIActionBlock>
      <ReceiveEmailActionWrap>
        <ActionCard
          type="receive-email"
          title="User security reports"
          hasOutgoingLink
          eventCount="539"
          colorVariant={0}
          injectBefore={
            <>
              <ConnectorPathWaypoint
                id="ai-page-hero-ai-action-email-to-ai"
                index={0}
                color={colors.red400}
                top="100%"
                left="2.3rem"
              />
              <ConnectorPathWaypoint
                id="ai-page-hero-ai-action-email-to-ai"
                index={1}
                top="calc(100% + 18px)"
                left="2.3rem"
                radius={12}
              />
            </>
          }
        />
      </ReceiveEmailActionWrap>
      <Spacing size="48px" />
      <AIActionWrap>
        <ActionCard
          type="ai"
          title="Summarize report"
          hasIncomingLink
          hasOutgoingLink
          colorVariant={100}
          injectBefore={
            <>
              <ConnectorPathWaypoint
                id="ai-page-hero-ai-action-email-to-ai"
                index={2}
                bottom="calc(100% + 31px)"
                left="2.3rem"
                radius={18}
              />
              <ConnectorPathWaypoint
                id="ai-page-hero-ai-action-email-to-ai"
                index={3}
                color={colors.yellow400}
                bottom="calc(100% - 1px)"
                left="2.3rem"
              />

              <ConnectorPathWaypoint
                id="ai-page-hero-ai-action-to-hero-bottom-center"
                index={0}
                color={colors.yellow400}
                bottom="calc(100% - 1px)"
                left="2.3rem"
                radius={0}
              />
              <ConnectorPathWaypoint
                id="ai-page-hero-ai-action-to-hero-bottom-center"
                index={1}
                top="calc(100% + 133px)"
                left="2.3rem"
                radius={24}
              />
            </>
          }
        />
      </AIActionWrap>
      <Spacing size="3.2rem" />
      <AIActionDetailsWrap>
        <AIActionDetails />
      </AIActionDetailsWrap>
    </HeroSectionAIActionBlock>
  );
};
