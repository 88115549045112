import { styled } from "@linaria/react";
import { fluidFontSize, fluidLineHeight } from "../../styles/helpers.styles";

export const ArticleLikePageTitle = styled.h1`
  ${fluidFontSize(32, 46)};
  ${fluidLineHeight(38, 52)};
  font-weight: 500;
  &.long {
    ${fluidFontSize(28, 36)};
    ${fluidLineHeight(36, 44)};
  }
`;

export default ArticleLikePageTitle;
